import React, { useState } from "react";
import AppMetaHeader from "../component/AppMetaHeader";
import PageContainer from "../component/Layout/PageContainer";
import { useApi, useMount } from "../hooks";
import { Button, Col, Row } from "antd";
import CustomCard from "../component/GlobalCard";
import Shimmer from "../component/Shimmer";
import EmptyResult from "../component/Empty";
import styled from "styled-components";
import { recommendStore } from "../store/recommendedCourseStore";
import PaginationComp from "../utils/PaginationComp";

interface ITestSeries {
  id: number;
  slug: string;
  title: string;
  thumb: string;
  total_quizzes: string;
  total_questions: string;
  category_id: number;
}

const RecommendedTestSeriesListing = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState();
  const [total, setTotal] = useState();
  const [filterSlug, setFilterSlug] = useState<string>();
  const [all, online, offline, onlineOffline]: ITestSeries[][] = [
    [],
    [],
    [],
    [],
  ];

  const { data, isLoading, load } = useApi<{ data: ITestSeries[] }>(
    `test-series`
  );

  useMount(() => {
    load({ page });
  }, [page]);

  useMount(() => {
    document.title = "Test Series | Khan Global Studies";
  }, []);

  useMount(() => {
    if (!data) return;
    setPerPage(data?.per_page);
    setTotal(data?.total);
  }, [data]);

  data?.data?.forEach((item: ITestSeries) => {
    all.push(item);
    item?.category_id === 1 && online.push(item);
    item?.category_id === 2 && onlineOffline.push(item);
    item?.category_id === 3 && offline.push(item);
  });

  const selectButton = [
    { title: "All Test Series", slug: "all" },
    { title: "Online Test Series", slug: "online" },
    { title: "Online + Offline Test Series", slug: "online+offline" },
    { title: "Offline Test Series", slug: "offline" },
  ];

  const filteredArray =
    filterSlug === "all"
      ? all
      : filterSlug === "online"
      ? online
      : filterSlug === "offline"
      ? offline
      : filterSlug === "online+offline"
      ? onlineOffline
      : all;

  return (
    <>
      <AppMetaHeader currentPageTitle="Test Series" />
      <PageContainer currentPageTitle="Test Series" transparent={true}>
        <Header>
          {selectButton?.map((item) => (
            <Button
              size="large"
              onClick={() => {
                setFilterSlug(item?.slug);
              }}
              style={{
                marginTop: "0.5em",
                // marginBottom: "2%",
                marginRight: "1em",
                backgroundColor:
                  item?.slug === (filterSlug === undefined ? "all" : filterSlug)
                    ? "#2E3192"
                    : "",
                color:
                  item?.slug === (filterSlug === undefined ? "all" : filterSlug)
                    ? "white"
                    : "",
              }}
            >
              {item?.title}
            </Button>
          ))}

          <h2 style={{ marginBottom: "0%", marginTop: "0.5%" }}>
            We have found {filteredArray.length} Test Series available for you!
          </h2>
        </Header>

        <Container className="video-content-container">
          <Shimmer count={4} loading={isLoading} type="horizantal">
            <Row
              gutter={[15, 15]}
              style={{
                width: "99.8%",
                marginBottom: "1%",
              }}
            >
              {filteredArray?.length > 0 ? (
                filteredArray?.map((elem) => (
                  <Col key={elem?.id} xs={24} sm={12} md={8} lg={6} xl={6}>
                    <a
                      href={`https://khanglobalstudies.com/test-series/${elem.slug!}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <CustomCard
                        title={
                          <div
                            style={{
                              marginBottom: "2%",
                              whiteSpace: "break-spaces",
                            }}
                            className="card-content"
                          >
                            {elem.title}
                          </div>
                        }
                        type="cover"
                        cover={{
                          url: elem?.thumb || "./kgs.jpg",
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    </a>
                  </Col>
                ))
              ) : (
                <EmptyResult
                  title={"No Data Found!"}
                  showDes={false}
                  status={"404"}
                />
              )}
            </Row>

            {data?.last_page > 1 && (
              <div style={{ float: "right" }}>
                <PaginationComp
                  page={page}
                  total={total}
                  perPage={perPage}
                  onChange={(page) => setPage(page)}
                />
              </div>
            )}
          </Shimmer>
        </Container>
      </PageContainer>
    </>
  );
};

const Header = styled.div`
  width: 100%;
  height: 12.5vh;
`;
const Container = styled.div`
  width: 100%;
  background: transparent;
  height: 71vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export default RecommendedTestSeriesListing;
