import React, { useEffect, useState } from "react";
import { Tabs, Row, Col, Button } from "antd";
import EmptyResult from "../component/Empty";
import PageContainer from "../component/Layout/PageContainer";
import { Link } from "@tanstack/react-router";
import AppMetaHeader from "../component/AppMetaHeader";
import { useApi, useMount } from "../hooks";
import moment from "moment";
import { ILives, ITodayClass, IUpcomingClass } from "../interfaces/live.types";
import CustomCard from "../component/GlobalCard";
import { SubTitleContainer } from "../component/GlobalCard";
import Shimmer from "../component/Shimmer";
import EmptyWrapper from "../component/EmptyWrapper";
import { useMediaQuery } from "react-responsive";

function LiveCards({
  isLoading,
  data = [],
  load,
}: {
  isLoading: boolean;
  data?: IUpcomingClass[] | ITodayClass[];
  label?: string | React.JSX.Element;
}) {
  const isSmallScreen = useMediaQuery({
    query: "(max-width: 426px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(max-width: 1025px)",
  });

  const isExtraLageScreen = useMediaQuery({
    query: "(max-width: 1441px)",
  });
  return (
    <div
      style={{
        marginTop: isSmallScreen
          ? "4em"
          : isMediumScreen
          ? "4em"
          : isLargeScreen
          ? "4em"
          : isExtraLageScreen
          ? "4em"
          : "4em",
      }}
    >
      <Shimmer type="horizantal" loading={isLoading} count={4}>
        {data.length > 0 ? (
          <Row gutter={[24, 18]}>
            {data?.map((elem: any) => {
              const currentTime = moment();
              const publishedTime = moment(elem.published_at);
              const isTimePassed = currentTime.isAfter(publishedTime);
              return (
                <Col
                  key={elem?.id}
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                  key={elem?.id}
                >
                  <Link to={isTimePassed ? `/live/class?vvid=${elem.id}` : ""}>
                    <CustomCard
                      title={elem.name}
                      desc={
                        <SubTitleContainer>
                          {elem.course.title}
                        </SubTitleContainer>
                      }
                      type="cover"
                      cover={{
                        url: elem.thumb ? elem.thumb : "kgs.jpg",
                        height: "100%",
                        width: "100%",
                        className: "imageContainer",
                      }}
                      date={moment(elem.published_at).format(
                        "Do MMM YYYY hh:mm A"
                      )}
                      icons={
                        <svg
                          width="33"
                          height="33"
                          viewBox="0 0 33 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.5 32.625C25.4056 32.625 32.625 25.4056 32.625 16.5C32.625 7.59441 25.4056 0.375 16.5 0.375C7.59441 0.375 0.375 7.59441 0.375 16.5C0.375 25.4056 7.59441 32.625 16.5 32.625ZM12.9078 8.5321L25.6765 15.6258C26.3623 16.0068 26.3623 16.9932 25.6765 17.3742L12.9078 24.4679C12.1079 24.9123 11.125 24.3339 11.125 23.4189V9.58108C11.125 8.6661 12.1079 8.08774 12.9078 8.5321Z"
                            fill="white"
                          />
                        </svg>
                      }
                    />
                  </Link>
                </Col>
              );
            })}
          </Row>
        ) : (
          <EmptyWrapper load={load} />
        )}
      </Shimmer>
    </div>
  );
}

const LiveClasses = () => {
  const { data, isLoading, load } = useApi<ILives>("lives");
  const [activeKey, setActiveKey] = useState("1");
  const [isClicked, setIsClicked] = useState(false);
  useMount(() => {
    load();
  });

  useEffect(() => {
    document.title = "Live Classes | Khan Global Studies";
  }, []);

  const items = [
    {
      key: "1",

      label: (
        <span className={!isClicked ? "tabs_1" : "tabs_2"}>
          Ongoing Live Classes
        </span>
      ),
      children: (
        <LiveCards isLoading={isLoading} data={data?.today} load={load} />
      ),
    },

    {
      key: "2",
      label: (
        <span className={isClicked ? "tabs_1" : "tabs_2"}>
          Upcoming Live Classes
        </span>
      ),
      children: (
        <LiveCards isLoading={isLoading} data={data?.upcomings} load={load} />
      ),
    },
  ];

  const handleTabChange = (key) => {
    setActiveKey(key);
    setIsClicked(!isClicked);
  };

  const getCurrentPageTitle = () => {
    const activeItem = items.find((item) => item.key === activeKey);
    return `${activeItem ? activeItem.label.props.children : ""}`;
  };

  return (
    <>
      <AppMetaHeader title="" currentPageTitle="Live Classes" />
      <PageContainer
        currentPageTitle={getCurrentPageTitle()}
        transparent={true}
      >
        <Tabs
          tabBarStyle={{
            width: "100%",
            paddingBottom: "0.5%",
            position: "fixed",
            zIndex: "1",
            backgroundColor: "#EDF4FF",
          }}
          items={items}
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={handleTabChange}
          size="small"
        />
      </PageContainer>
    </>
  );
};

export default LiveClasses;
