import { RouterProvider } from "@tanstack/react-router";
import { ConfigProvider, theme } from "antd";
import React from "react";
import ReactDOM from "react-dom/client";
import "./routes";
import router from "./routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#171A68",
          colorLink: "#5458C9",
          fontFamily: "Urbanist",
          paddingLG: 19,
          lineWidth: 1,
          padding: 4,
        },

        components: {
          Tabs: {
            fontSize: 15,
          },
          Segmented: {},
          Collapse: {
            headerPadding: "12px 16px",
          },
        },

        algorithm: theme.compactAlgorithm,
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  </React.StrictMode>
);
