import {
  Button,
  Card,
  Col,
  Descriptions,
  DescriptionsProps,
  List,
  Row,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import ItemCard from "../pages/ItemCard";
import PaymentList from "./PaymentList";
import { useSearch } from "@tanstack/react-router";
import { useApi, useMount } from "../hooks";
import api from "../config/api";
import CCAvenuePay from "./CCAvenuePay";
import RzPay from "./RzPay";
import Loader from "../component/loader";
import styled from "styled-components";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import {
  ICourses,
  IGeneratedOrder,
  IOrders,
  ITestSeriesOrder,
} from "./payment";
import { RZ_CHANNEL } from "./PyamentConfig";

const Checkout = () => {
  const [loading, setLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [generatedOrder, setGeneratedOrder] = useState<IGeneratedOrder>();
  const [paymentRes, setPaymentRes] = useState(null);
  const [paymentChannel, setPaymentChannel] = useState(RZ_CHANNEL);

  const { entity_type, entity_id } = useSearch({
    from: "/checkout",
  });

  const orderId = generatedOrder?.order?.id;

  const { data, isLoading, load } = useApi<IOrders>(
    orderId ? `/orders/${orderId}?medium=0` : ""
  );

  useMount(() => {
    load();
  }, [orderId]);

  const {
    data: courseData,
    isLoading: courseDataLoading,
    load: courseDataLoad,
  } = useApi<ICourses>(`courses`);

  const {
    data: tsData,
    isLoading: tsDataLoading,
    load: tsDataLoad,
  } = useApi<ITestSeriesOrder>(`test-series`);

  useMount(() => {
    tsDataLoad();
    courseDataLoad();
  }, []);

  const courseId = courseData?.data?.find((item) => item?.slug === entity_id);
  const test_series_id = tsData?.data?.find(
    (item) => item?.slug === entity_id
  )?.id;

  useEffect(() => {
    const fetchData = async () => {
      if (!courseId?.id) return;
      const orderData = { course_id: courseId.id, channel: "_blank" };
      try {
        const orderDataPost = await api.post(`orders`, orderData);
        setGeneratedOrder(orderDataPost?.data);
      } catch (error) {}
    };

    const fetchTsData = async () => {
      if (!test_series_id) return;
      try {
        const orderDataPost = await api.post(`ts-orders`, { test_series_id });
        setGeneratedOrder(orderDataPost?.data);
      } catch (error) {}
    };

    if (entity_type === "test-series") {
      fetchTsData();
    } else if (entity_type === "course") {
      fetchData();
    }
  }, [courseId?.id, entity_type, test_series_id]);

  const items = [
    {
      label: <DescriptionLabel>Item Price</DescriptionLabel>,
      value: (
        <DescriptionLabel>
          {"₹"}
          {data?.amount}
          {"/-"}
        </DescriptionLabel>
      ),
    },
    // {
    //   label: <DescriptionLabel>Discount Code</DescriptionLabel>,
    //   value: (
    //     <DescriptionLabel>
    //       <Tag style={{ cursor: "pointer" }}>Apply</Tag>
    //     </DescriptionLabel>
    //   ),
    // },
    {
      label: <DescriptionLabel>Tax (18%)</DescriptionLabel>,
      value: <DescriptionLabel>{"₹"}00/-</DescriptionLabel>,
    },
    {
      label: <DescriptionLabel>Shipping Charges</DescriptionLabel>,
      value: <DescriptionLabel>FREE</DescriptionLabel>,
    },
    {
      label: <DescriptionLabel>Total</DescriptionLabel>,
      value: (
        <DescriptionLabel>
          {"₹"}
          {data?.amount}
          {"/-"}
        </DescriptionLabel>
      ),
    },
  ];

  return !orderId || isLoading ? (
    <div style={{ position: "absolute", top: "50%", left: "50%" }}>
      <Loader />
    </div>
  ) : (
    <Row
      gutter={[24, 24]}
      style={{ width: "85%", margin: "auto", marginTop: "5%" }}
    >
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <Card
          type="inner"
          title={<Title>Checkout Details</Title>}
          size="default"
        >
          <ItemCard order={data} />

          <PaymentList
            setPaymentChannel={setPaymentChannel}
            paymentChannel={paymentChannel}
          />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8}>
        <Card type="inner" title={<Title>Price Details</Title>}>
          <SubTitle>Product</SubTitle>
          <Row>
            <Col span={20}>
              <ProductTitle>
                {generatedOrder?.course?.title ||
                  generatedOrder?.test_series?.title}
              </ProductTitle>
            </Col>
            <Col span={4}>
              <PayementTag>
                {"₹"} {data?.amount}/-
              </PayementTag>
            </Col>
          </Row>

          <List
            loading={isLoading}
            size="small"
            bordered
            itemLayout="horizontal"
            dataSource={items}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta title={item?.label} />
                <div>{item?.value}</div>
              </List.Item>
            )}
            style={{ marginTop: "2.5%" }}
          />

          <p
            style={{
              textAlign: "center",
              fontSize: "0.9em",
              marginTop: "5%",
              marginBottom: "3%",
            }}
          >
            <SafetyCertificateOutlined style={{ fontSize: "0.9em" }} /> Safe and
            secure payments
          </p>

          <div
            style={{
              textAlign: "center",
              marginBottom: "3%",
            }}
          >
            {generatedOrder?.order?.status !== 1 ? (
              <>
                {paymentChannel === "" && (
                  <Button
                    type="primary"
                    size="large"
                    disabled={true}
                    style={{ width: "70%" }}
                  >
                    Choose Payment Option
                  </Button>
                )}
                {/* {paymentChannel === "ccavenue_hdfc" && (
                  <CCAvenuePay order={generatedOrder?.order} />
                )} */}
                {paymentChannel === "razorpay" && (
                  <RzPay
                    order={generatedOrder?.order}
                    setPaymentRes={setPaymentRes}
                    isUpdating={isUpdating}
                    setIsUpdating={setIsUpdating}
                  />
                )}
                {paymentChannel === "razorpay2" && (
                  <RzPay
                    order={generatedOrder?.order}
                    setPaymentRes={setPaymentRes}
                    isUpdating={isUpdating}
                    setIsUpdating={setIsUpdating}
                  />
                )}
              </>
            ) : (
              <Button size="large">Already Paid</Button>
            )}
          </div>
        </Card>
      </Col>
    </Row>
  );
};

const Title = styled.p`
  font-size: 1.05em;
  font-weight: 600;
  margin-bottom: 0%;
  padding: 3% 3% 3% 0%;
`;

const SubTitle = styled.p`
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: 0%;
  margin-top: 3%;
`;

const ProductTitle = styled.p`
  font-size: 1.4em;
  font-weight: 700;
  margin-bottom: 0%;
  color: #3e4192;
`;

const PayementTag = styled(Tag)`
  color: #0958d9;
  background: #e6f4ff;
  border-color: #91caff;
  font-weight: 600;
`;

const DescriptionLabel = styled.p`
  font-size: 1.1em;
  margin-bottom: 0%;
  font-weight: 500;
`;

export default Checkout;
