export default function Setting() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <g clip-path="url(#clip0_2344_5167)">
        <path
          d="M7.5 7.5C9.36396 7.5 10.875 5.98896 10.875 4.125C10.875 2.26104 9.36396 0.75 7.5 0.75C5.63604 0.75 4.125 2.26104 4.125 4.125C4.125 5.98896 5.63604 7.5 7.5 7.5Z"
          stroke="#000001"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.25 18.749H0.75V16.499C0.761941 15.3557 1.06347 14.2341 1.62641 13.2389C2.18935 12.2437 2.99535 11.4075 3.96911 10.8082C4.94287 10.209 6.05263 9.86633 7.19472 9.81226C8.33682 9.75819 9.47398 9.99447 10.5 10.499"
          stroke="#000001"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.2502 12.7502L13.1852 19.8152L9.99023 20.2502L10.4402 17.0552L17.4902 9.99023L20.2502 12.7502Z"
          stroke="#000001"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2344_5167">
          <rect width="21" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
