import { Avatar, Drawer, List, Tag } from "antd";
import React, { useState } from "react";
import { useApi, useMount } from "../../hooks";
import PDFIcon from "../../component/icons/PDFIcon";
import PdfViewer from "../../utils/pdfViewer";
import styled from "styled-components";
import { formatDateTime } from "../../helpers/help";

const PdfSolution = ({ id }) => {
  const [open, setOpen] = useState(false);
  const [resource, setResource] = useState();

  const { data, load, isLoading } = useApi<any>(`test-series/${id}/media`);

  useMount(() => {
    load();
  });

  const showDrawer = (item) => {
    setOpen(true);
    setResource(item);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <List
        loading={isLoading}
        itemLayout="horizontal"
        dataSource={data?.pdfs}
        bordered={true}
        renderItem={(item) => (
          <List.Item
            actions={[
              <TagButton
                onClick={() => {
                  showDrawer(item);
                }}
              >
                View
              </TagButton>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar shape="square" size="large" src={<PDFIcon />} />}
              title={
                <h3
                  style={{
                    fontWeight: "600",
                    marginBottom: "0%",
                    marginLeft: "2%",
                  }}
                >
                  {item?.name}
                </h3>
              }
              description={
                <p style={{ marginLeft: "2%", marginBottom: "0%" }}>
                  {formatDateTime(item?.published_at)}
                </p>
              }
            />
          </List.Item>
        )}
      />

      <Drawer title={resource?.name} onClose={onClose} open={open} width="70%">
        <PdfViewer pdfUrl={resource?.url} height={"700px"} />
      </Drawer>
    </div>
  );
};

const TagButton = styled(Tag)`
  background-color: #e2f1ff;
  color: #111827;
  border: none;
  font-size: 1em;
  padding: 0.5em 2em 0.5em 2em;
  border-radius: 0.7em;
  cursor: pointer;
`;

export default PdfSolution;
