import "antd/dist/reset.css";
import React, { useEffect, useState } from "react";
import api from "../../config/api";
import Loader from "../../component/loader";
import EmptyResult from "../../component/Empty";
import { IQuizSubmission, ICacheQuizSubmission } from "../../../type";
import "antd/dist/reset.css";
import { Card, Table, Col, Row } from "antd";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";
import { TableContainer } from "../../component/GlobalCard";
import {
  TableHeader,
  HeaderContainer,
  QuizHeader,
} from "../../component/GlobalCard";
import { ColumnsType } from "antd/lib/table";
import { useMediaQuery } from "react-responsive";

type Breakpoint = "xxl" | "xl" | "lg" | "md" | "sm" | "xs";

const QuizSubmissionInfo = () => {
  const [submittedQuiz, setSubmittedQuiz] = useState<IQuizSubmission[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    current: page,
    pageSize: 0,
    total: 0,
  });

  const queryClient = useQueryClient();
  const fetchSubmittedData = async (pageNo: number) => {
    try {
      setLoading(true);
      const { data } = await api.get(`/user/quizzes?page=${pageNo}`);
      setSubmittedQuiz(data?.data);
      setPagination({
        ...pagination,
        current: data?.current_page,
        pageSize: data?.per_page,
        total: data?.total,
      });
      queryClient.setQueryData(["quizSubmitted", page], data);
      setLoading(false);
    } catch (err) {}
  };

  const handleSubmittedData = () => {
    const quizSubmittedData: ICacheQuizSubmission | undefined =
      queryClient.getQueryData(["quizSubmitted", page]);
    if (!quizSubmittedData) {
      fetchSubmittedData(page);
    } else {
      setSubmittedQuiz(quizSubmittedData?.data);
      setPagination({
        ...pagination,
        current: quizSubmittedData?.current_page,
        pageSize: quizSubmittedData?.per_page,
        total: quizSubmittedData?.total,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSubmittedData();
  }, [page]);

  const columns: ColumnsType<IQuizSubmission> = [
    {
      title: <TableHeader> Id</TableHeader>,
      dataIndex: "0",
      key: "0",
      render: (_, { quiz_id }) => <HeaderContainer>{quiz_id}</HeaderContainer>,
    },
    {
      title: <TableHeader>Quiz Title</TableHeader>,
      dataIndex: "1",
      key: "1",
      render: (_, { quiz }) => (
        <HeaderContainer>{quiz?.title || "NA"}</HeaderContainer>
      ),
    },
    {
      title: <TableHeader>Date</TableHeader>,
      dataIndex: "2",
      key: "2",
      render: (_, { updated_at }) => (
        <HeaderContainer style={{ width: "max-content" }}>
          {moment(updated_at).format("DD MMMM YYYY")}
        </HeaderContainer>
      ),
    },

    {
      title: <TableHeader>Correct</TableHeader>,
      dataIndex: "3",
      key: "3",
      render: (_, { correct }) => <HeaderContainer>{correct}</HeaderContainer>,
    },

    {
      title: <TableHeader>Score</TableHeader>,
      dataIndex: "4",
      key: "4",
      render: (_, { score }) => <HeaderContainer>{score}</HeaderContainer>,
      sorter: (a, b) => a.score - b.score,
    },
    {
      title: <TableHeader>Total</TableHeader>,
      dataIndex: "5",
      key: "5",
      render: (_, { total }) => <HeaderContainer>{total}</HeaderContainer>,
    },
  ];

  const handleTableChange = (e) => {
    setPage(e?.current);
  };

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 426px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(max-width: 1025px)",
  });

  const isExtraLageScreen = useMediaQuery({
    query: "(max-width: 1441px)",
  });

  return (
    <Row
      className="hideScroll"
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: isSmallScreen
          ? "0em"
          : isMediumScreen
          ? "0em"
          : isLargeScreen
          ? "0em"
          : isExtraLageScreen
          ? "0em"
          : "0em",
      }}
    >
      {loading && page == 1 ? (
        <Loader />
      ) : submittedQuiz.length > 0 ? (
        <>
          {/* <TableContainer>
            <QuizHeader>Quiz</QuizHeader>
          </TableContainer> */}
          <Card style={{ width: "100%" }}>
            <Table
              pagination={{ ...pagination, showSizeChanger: false }}
              dataSource={submittedQuiz}
              columns={columns}
              onChange={handleTableChange}
              rowKey={(_, _index) => _index! + 1}
              loading={loading}
              style={{ overflow: "auto" }}
            />
          </Card>
        </>
      ) : (
        <EmptyResult
          title={"You have not attempt any test Yet!"}
          showDes={false}
          status={"404"}
        />
      )}
    </Row>
  );
};

export default QuizSubmissionInfo;
