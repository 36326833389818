export default function EnrollCountIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_18_87)">
        <path
          d="M11.8078 1.60845H3.62598C3.3997 1.60845 3.21689 1.76805 3.21689 1.96559V11.2513C3.21689 11.4489 3.3997 11.6085 3.62598 11.6085H11.8078C12.0341 11.6085 12.2169 11.4489 12.2169 11.2513V1.96559C12.2169 1.76805 12.0341 1.60845 11.8078 1.60845ZM11.2964 10.8049H4.13734V8.56158H5.38891C5.5372 8.92765 5.798 9.25689 6.14445 9.50689C6.5855 9.82497 7.14416 10.0013 7.71689 10.0013C8.28962 10.0013 8.84828 9.82609 9.28933 9.50689C9.63578 9.25689 9.89658 8.92765 10.0449 8.56158H11.2964V7.85845H9.39927L9.3328 8.13412C9.17939 8.80153 8.49927 9.28702 7.71689 9.28702C6.9345 9.28702 6.25439 8.80153 6.0997 8.13412L6.03322 7.85845H4.13734V2.41202H11.2964V10.8049ZM5.26234 4.69997H10.1714C10.2277 4.69997 10.2737 4.65979 10.2737 4.61068V4.07497C10.2737 4.02586 10.2277 3.98568 10.1714 3.98568H5.26234C5.20609 3.98568 5.16007 4.02586 5.16007 4.07497V4.61068C5.16007 4.65979 5.20609 4.69997 5.26234 4.69997ZM5.26234 6.48568H10.1714C10.2277 6.48568 10.2737 6.44551 10.2737 6.3964V5.86068C10.2737 5.81158 10.2277 5.7714 10.1714 5.7714H5.26234C5.20609 5.7714 5.16007 5.81158 5.16007 5.86068V6.3964C5.16007 6.44551 5.20609 6.48568 5.26234 6.48568Z"
          fill="#272835"
        />
      </g>
      <defs>
        <clipPath id="clip0_18_87">
          <rect width="12.5221" height="12.5221" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
