import React from "react";
import { Button } from "antd";
import useRazorpay from "react-razorpay";
import { RZ_CHANNEL, RZ_KEY } from "./PyamentConfig";
import api from "../config/api";
import { useAuthStore } from "../store/auth";

export default function RzPay({
  order,
  setPaymentRes,
  isUpdating,
  setIsUpdating,
}) {
  const { user } = useAuthStore();
  const Razorpay = useRazorpay();

  const ouuid = order.id;

  if (!ouuid) return;

  const updateChannel = async () => {
    try {
      setIsUpdating(true);
      const { data } = await api.put(`orders/${ouuid}`, {
        channel: RZ_CHANNEL,
      });
      if (data && data.order && data.order.gatway_id) {
        handlePayment(data.order.gatway_id);
      } else {
        setIsUpdating(false);
      }
      setIsUpdating(false);
    } catch (error) {
      setIsUpdating(false);
    }
  };

  const updateRazorPayment = async (res) => {
    setIsUpdating(true);
    const { data } = await api.post(`orders/${ouuid}`, res);
    setPaymentRes(data);
    setIsUpdating(false);
    window.location.href = `/payment-status?order_id=${ouuid}`;
    return;
  };

  const handlePayment = async (rzorder_id) => {
    if (!rzorder_id) {
      return;
    }

    const userName = user?.name;
    const userPhone = user?.phone;
    const userEmail = user?.email || "void@khanglobalstudies.com";
    const amount = +order.amount * 100;

    if (amount < 1) {
      return;
    }

    const options = {
      key: RZ_KEY,
      amount: amount,
      currency: "INR",
      name: "KGS - Khan Global Studies",
      description: "Khan Global Studies",
      image: "https://khanglobalstudies.com/icons/kgs.png",
      order_id: rzorder_id,
      prefill: {
        name: userName,
        email: userEmail,
        contact: userPhone,
      },
      notes: {
        address:
          "Khan Global Studies Pvt. Ltd. 5th Floor, A13A, Sector 62, Noida, Uttar Pradesh 201309",
      },
      theme: {
        color: "#3399cc",
      },
      handler: function (response) {
        updateRazorPayment(response);
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.open();

    rzp1.on("payment.failed", function (response) {
      updateRazorPayment(response);
      const ouuid = rzorder_id;
      window.location.href = `/courses`;
    });
  };

  return (
    <Button
      loading={isUpdating}
      onClick={updateChannel}
      size="large"
      type="primary"
      style={{ width: "80%" }}
    >
      Pay Now
    </Button>
  );
}
