// import { removeLocalStorage } from "../helpers/LocalStorageHelper";
import { useAuthStore } from "../store/auth";
import showNotification from "../utils/notify";

export const autoErrorHandle = (error) => {
  if (!error) return;
  if (!error.response) return;

  if (error.response && error.response.status === 401) {
    useAuthStore.getState().logout();
    // removeLocalStorage("token");
    window.location.pathname = "/";
  }
  //   if (error.response.status !== 401) {
  //     const errData = error?.response?.data;
  //     for (let key in errData) {
  //       if (key !== "status") showNotification("error", errData[key]);
  //     }
  //   }

  if (error.response.status === 404) {
    const errData = error?.response?.data?.data;
    Object.keys(errData).forEach((key) => {
      errData[key].forEach((message) => {
        showNotification("error", message, 3);
      });
    });
  }

  if (error.response.status === 422) {
    const errData = error?.response?.data;
    for (let key in errData) {
      if (key !== "status") showNotification("error", errData[key]);
    }
  }
  //   showNotification("error", "something went wrong");
  return error;
};
