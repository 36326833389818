import React, { useState } from "react";
import { Col, Card, Row, Form, Button, Select, Input } from "antd";
import api from "../../config/api";
import showNotification from "../../utils/notify";
import { useForm } from "antd/es/form/Form";
import { useApi, useMount, useStorage } from "../../hooks";
import { H3, P } from "./BasicDetails";
import styled from "styled-components";

const EducationDetails = () => {
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const [events, setEvents] = useStorage<any>("EVENTS", []);

  const eve = [
    ...events,
    {
      title: "Education Details",
      time: Date.now(),
    },
  ];

  const [formData, setFormData] = useState({
    pursuing: "",
    preparing: "",
    occupation: "",
    last_exam: "",
    last_year: "",
    how_long_prepare: "",
  });

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      await api.post(`/user/education-detail`, form.getFieldsValue());
      showNotification("success", "Education Details Updated Successfully", 3);
      setTimeout(() => {
        window.location.href = "/profile";
      }, 1000);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const { data, load, isLoading } = useApi(`/profile`);

  useMount(() => {
    load();
  }, []);

  useMount(() => {
    if (data?.education) {
      form.setFieldsValue({
        last_exam: data?.education?.last_exam,
        last_year: data?.education?.last_year,
        how_long_prepare: data?.education?.how_long_prepare,
        pursuing: data?.education?.pursuing,
        preparing: data?.education?.preparing,
        occupation: data?.education?.occupation,
      });
    }
  }, [data]);

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <Card>
          <H3>Education Details</H3>
          <P>Submit your educational Details in below fields</P>
          <Form layout="vertical" form={form} style={{ width: "90%" }}>
            <Form.Item label="Currently Pursuing" name="pursuing">
              <Input size="large" id="pursuing" onChange={onChange} />
            </Form.Item>

            <Form.Item label="Preparing For" name="preparing">
              <Input size="large" id="preparing" onChange={onChange} />
            </Form.Item>

            <Form.Item label="Occupation" name="occupation">
              <Input size="large" id="occupation" onChange={onChange} />
            </Form.Item>

            <Form.Item label="Last Exam Name" name="last_exam">
              <Input size="large" id="last_exam" onChange={onChange} />
            </Form.Item>

            <Form.Item label="Last Exam Year" name="last_year">
              <Input
                size="large"
                id="last_year"
                onChange={onChange}
                type="number"
              />
            </Form.Item>

            <Form.Item
              label="How long you are preparing for"
              name="how_long_prepare"
            >
              <Input
                size="large"
                id="how_long_prepare"
                onChange={onChange}
                type="number"
              />
            </Form.Item>
            <Form.Item>
              <SubmitButton
                type="primary"
                onClick={handleSubmit}
                loading={loading}
                disabled={loading}
              >
                Submit
              </SubmitButton>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

const SubmitButton = styled(Button)`
  background-color: #2e3192;
  color: white;
  border: none;
`;

export default EducationDetails;
