import { Link } from "@tanstack/react-router";
import { Col, Row } from "antd";
import React from "react";
import DownloadCard from "./DownloadCard";
import PyqIcon from "../../component/icons/PyqIcon";
import { usePublicStore } from "../../store/usePublicStore";
import AppMetaHeader from "../../component/AppMetaHeader";
import PageContainer from "../../component/Layout/PageContainer";

const PYQ = () => {
  const { active } = usePublicStore();
  const pyqList = active?.config?.pyq;

  const batches = [
    {
      id: 3,
      name: "uppsc",
    },
    {
      id: 5,
      name: "bpsc",
    },
    {
      id: 6,
      name: "upsc",
    },
    {
      id: 9,
      name: "cds",
    },
    {
      id: 10,
      name: "nda",
    },
    {
      id: 32,
      name: "mppsc",
    },
    {
      id: 34,
      name: "capf",
    },
    {
      id: 55,
      name: "neet",
    },
  ];

  return (
    <>
      <AppMetaHeader currentPageTitle="Previous Year Questions" />
      <PageContainer
        currentPageTitle="Previous Year Questions"
        title="Study Materials"
        url="/study-materials"
      >
        <Row gutter={[15, 15]}>
          {(pyqList === undefined ? batches : pyqList)?.map((item) => (
            <Col xxl={6} xl={6} lg={8} md={12} sm={12} xs={24} key={item?.id}>
              <Link to={`/study-materials/pyqs?id=${item?.id}`}>
                <DownloadCard
                  title={item?.name?.toUpperCase()}
                  avatar={<PyqIcon />}
                />
              </Link>
            </Col>
          ))}
        </Row>
      </PageContainer>
    </>
  );
};

export default PYQ;
