import React from 'react';

const quality1 = [
  { name: '720p', value: "720p" },
  { name: '360p', value: "360p" },
];

const quality2 = [
  { name: '360p', value: "sd" },
]

export default function QualityControl({ onChange,variable,resolution }) {

  const quality = variable?quality1:quality2
  
  const [selected] = React.useState(quality.find(q=>q.value===resolution));


  return <ul>
  <li style={{paddingTop:"0.3em",paddingBottom:"0.3em"}} onClick={()=>onChange(selected)}>
    <div style={{marginRight:"0.8em"}}>{`<`}</div>
    <div style={{fontSize:"0.9em"}}>
      Video Quality
    </div>
  </li>
  <li>
  <div style={{background:"white",height:"0.01em", marginBottom:"0.2em", width:"100%"}}></div>
  </li>
  {quality.map((q) => 
    <li 
      onClick={() => {
        onChange(q.value);
      }}
    >
     {q.name}
    </li>
  )}
</ul>
}
