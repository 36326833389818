import { Spin } from "antd";
import React from "react";

const Loader = () => {
  return (
    <h3 style={{ textAlign: "center", color: "blue" }}>
      <strong>
        <Spin /> &nbsp;
      </strong>
    </h3>
  );
};
export default Loader;
