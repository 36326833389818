import { Pagination } from "antd";
import React from "react";

const PaginationComp = ({ page, total, onChange, perPage }) => {
  return (
    <Pagination
      showSizeChanger={false}
      responsive={true}
      pageSize={Number(perPage)}
      current={page}
      total={total}
      onChange={onChange}
    />
  );
};

export default PaginationComp;
