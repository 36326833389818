import { Link } from "@tanstack/react-router";
import { Breadcrumb, Card } from "antd";
import React, { memo } from "react";

type IProps = {
  title?: string;
  url?: string;
  currentPageTitle?: string;
  children: any;
  loading?: boolean;
  transparent?: boolean;
};

const PageContainer = ({
  title,
  url,
  currentPageTitle,
  children,
  loading = false,
  transparent = false,
}: IProps) => {
  return (
    <>
      <div
        style={{
          padding: "0.5rem 1.5rem",
          height: "90vh",
        }}
      >
        <Breadcrumb
          // separator=">"
          style={{
            height: "3.5vh",
          }}
          items={[
            currentPageTitle
              ? {
                  title: <Link to="/">Home</Link>,
                }
              : "",
            url
              ? {
                  title: <Link to={url}>{title}</Link>,
                }
              : "",

            {
              title: <p>{currentPageTitle}</p>,
            },
          ]}
        />
        {transparent == false ? (
          children
        ) : (
          <>
            <Card
              style={{
                overflowY: "scroll",
                background: "transparent",
                height: "83vh",
                border: "none",
              }}
              loading={loading}
              size="small"
              className="hideScrollbar"
              bodyStyle={{ padding: "0px" }}
            >
              {children}
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default memo(PageContainer);
