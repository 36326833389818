import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import PageContainer from "../Layout/PageContainer";
import DailyWatchRate from "./DailyWatchRate";
import StatCard from "./StatCard";
import ActivityCard from "./ActivityCard";
import EnrollIcon from "../icons/EnrollIcon";
import LectureIcon from "../icons/LectureIcon";
import EnrollCountIcon from "../icons/EnrollCountIcon";
import LectureCountIcon from "../icons/LectureCountIcon";
import SubmissionIcon from "../icons/SubmissionIcon";
import SubmissionCountIcon from "../icons/SubmissionCountIcon";
import RecommendedCourse from "./RecommendedCourse";
import api from "../../config/api";
import { Link } from "@tanstack/react-router";
import LiveClasses from "./LiveClasses";
import RecommendedTestSeries from "./RecommendedTestSeries";
import Banner from "../Banner";
import { useApi, useMount } from "../../hooks";
import { useAuthStore } from "../../store/auth";
import { useMediaQuery } from "react-responsive";
import { usePublicStore } from "../../store/usePublicStore";
import { useNotificationStore } from "../../store/notificatonStore";

const Dashboard = () => {
  const { setIsLoading, storePublicData } = usePublicStore();
  const { setNotification, setLoading, setMarkAsRead } = useNotificationStore();
  const [submissions, setSubmissions] = useState();

  const { user } = useAuthStore();

  useEffect(() => {
    const allsubmission = async () => {
      const submission = await api.get("user/submissions");
      setSubmissions(submission?.data);
    };

    allsubmission();
  }, []);

  const {
    data: analyticData,
    load,
    isLoading: analyticLoading,
  } = useApi(`user/analytics?phone=${user?.phone}`);

  useMount(() => {
    load();
  }, []);

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 991px)",
  });

  return (
    <PageContainer transparent={true}>
      <div
        style={{
          width: "100%",
          backgroundColor: "transparent",
          height: "83vh",
          overflowX: "hidden",
        }}
        className={isSmallScreen ? "hideScrollbar" : "video-content-container"}
      >
        <div style={{ width: "99.5%" }}>
          <Banner />

          <Row
            gutter={[24, 24]}
            style={{
              marginTop: "1.5%",
              display: "flex",
            }}
            justify={"space-between"}
          >
            <Col lg={16} xl={16} md={24} sm={24} xs={24}>
              <Row
                gutter={[8, 8]}
                justify="space-between"
                style={{ marginBottom: "4%" }}
              >
                <Col
                  lg={8}
                  xl={8}
                  md={8}
                  sm={8}
                  xs={8}
                  // style={{ marginBottom: "4%" }}
                >
                  <Link to="/courses">
                    <StatCard
                      icon={<EnrollIcon />}
                      title="Enrolled Courses"
                      number={analyticData?.courses}
                      back="#E5E7FF"
                      icon2={<EnrollCountIcon />}
                    />
                  </Link>
                </Col>
                <Col
                  lg={8}
                  xl={8}
                  md={8}
                  sm={8}
                  xs={8}
                  // style={{ marginBottom: "4%" }}
                >
                  <StatCard
                    icon={<LectureIcon />}
                    title="Lecture Watch"
                    number={8}
                    back="#E8FFF0"
                    icon2={<LectureCountIcon />}
                  />
                </Col>
                <Col
                  lg={8}
                  xl={8}
                  md={8}
                  sm={8}
                  xs={8}
                  // style={{ marginBottom: "4%" }}
                >
                  <Link to="/submission">
                    <StatCard
                      icon={<SubmissionIcon />}
                      title="My Submission"
                      number={submissions?.total}
                      back="#FFF7EC"
                      icon2={<SubmissionCountIcon />}
                    />
                  </Link>
                </Col>
              </Row>

              <Row justify="space-between">
                <Col
                  lg={14}
                  xl={14}
                  md={24}
                  sm={24}
                  xs={24}
                  className="dailyWatchRate"
                >
                  <DailyWatchRate week={analyticData?.week_quizzes} />
                </Col>
                <Col
                  lg={9}
                  xl={9}
                  md={24}
                  sm={24}
                  xs={24}
                  className="dailyWatchRate"
                >
                  <ActivityCard />
                </Col>
              </Row>
            </Col>
            <Col lg={8} xl={8} md={24} sm={24} xs={24}>
              <LiveClasses />
            </Col>
          </Row>

          <Row
            gutter={26}
            style={{
              height: "50%",
              marginTop: "2%",
            }}
            justify="space-between"
          >
            <Col xl={16} lg={16} md={24} sm={24} xs={24}>
              <RecommendedCourse />
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <RecommendedTestSeries />
            </Col>
          </Row>
        </div>
      </div>
    </PageContainer>
  );
};

export default Dashboard;
