import React, { useState } from "react";
import { useApi, useMount } from "../../../hooks";
import { Link, useSearch } from "@tanstack/react-router";
import AppMetaHeader from "../../../component/AppMetaHeader";
import PageContainer from "../../../component/Layout/PageContainer";
import AppSwitch from "../../../component/AppSwitch";
import { Col, Drawer, Row, Select } from "antd";
import PdfViewer from "../../../utils/pdfViewer";
import DownloadCard from "../DownloadCard";
import PyqIcon from "../../../component/icons/PyqIcon";
import Shimmer from "../../../component/Shimmer";
import EmptyResult from "../../../component/Empty";
import { usePublicStore } from "../../../store/usePublicStore";

const PYQListing = () => {
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [resource, setResource] = useState();
  const [english, hindi] = [[], []];
  const [filterData, setFilterData] = useState();

  const { id } = useSearch({
    from: "/study-materials/pyqs",
  });

  const { data, load, isLoading } = useApi(`/previous-year-questions/${id}`);

  useMount(() => {
    load();
  }, []);

  filterData?.forEach((item) => {
    item.lang === "en" && english.push(item);
    item.lang === "hi" && hindi.push(item);
  });

  const showDrawer = (item) => {
    setOpen(true);
    setResource(item);
  };

  const onClose = () => {
    setOpen(false);
  };

  const year = new Set(
    data?.ques
      ?.map((item) => item?.year)
      ?.sort((a, b) => a - b)
      .reverse()
  );

  const yearOptions = [...year].map((year) => ({ label: year, value: year }));

  useMount(() => {
    setFilterData(
      data?.ques?.filter((item) => item?.year === yearOptions[0]?.value)
    );
  }, [yearOptions[0]?.value]);

  const handleChange = (a) => {
    setFilterData(data?.ques?.filter((item) => item?.year === a));
  };

  const batches = [
    {
      batch_id: 3,
      title: "uppsc",
    },
    {
      batch_id: 5,
      title: "bpsc",
    },
    {
      batch_id: 6,
      title: "upsc",
    },
    {
      batch_id: 9,
      title: "cds",
    },
    {
      batch_id: 10,
      title: "nda",
    },
    {
      batch_id: 32,
      title: "mppsc",
    },
    {
      batch_id: 34,
      title: "capf",
    },
    {
      batch_id: 55,
      title: "neet",
    },
  ];

  const currentPage = batches
    ?.filter((item) => item?.batch_id === id)
    ?.map((item) => item?.title);

  return (
    <>
      <AppMetaHeader currentPageTitle="Previous Year Question" />

      <PageContainer
        currentPageTitle={
          currentPage?.toString()?.charAt(0)?.toUpperCase() +
          currentPage?.toString()?.slice(1)
        }
        title={"Previous Year Questions"}
        url={`/study-materials`}
      >
        {/* <Card> */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1%",
          }}
        >
          <AppSwitch
            onValueChange={() => setIsChecked(!isChecked)}
            onLabel="English"
            offLabel="Hindi"
          />

          {yearOptions?.length > 0 && (
            <Select
              size="large"
              defaultValue={yearOptions[0]?.value}
              onChange={handleChange}
              options={yearOptions}
              style={{ width: "10em" }}
            />
          )}
        </div>
        <Shimmer type="downloads" loading={isLoading} count={4}>
          <Row gutter={24}>
            {(isChecked ? hindi : english)?.length === 0 ? (
              <EmptyResult
                title={"No Data Found!"}
                showDes={false}
                status={"404"}
              />
            ) : (
              (isChecked ? hindi : english)?.map((item) => (
                <Col
                  xxl={6}
                  xl={6}
                  lg={8}
                  md={12}
                  sm={12}
                  xs={24}
                  style={{ marginBottom: "2%" }}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      showDrawer(item);
                    }}
                  >
                    <DownloadCard title={item?.name} avatar={<PyqIcon />} />
                  </div>
                </Col>
              ))
            )}
          </Row>
        </Shimmer>
      </PageContainer>

      <Drawer title={resource?.name} onClose={onClose} open={open} width="70%">
        <PdfViewer pdfUrl={resource?.content} height={"700px"} />
      </Drawer>
    </>
  );
};

export default PYQListing;
