export default function OtherIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.70015 11.6109C2.57194 11.6109 0.0273438 9.00645 0.0273438 5.8056C0.0273438 2.60416 2.57223 0 5.70015 0C8.82808 0 11.373 2.60446 11.373 5.8056C11.373 9.00674 8.82836 11.6109 5.70015 11.6109ZM5.70015 1.1766C3.20615 1.1766 1.17732 3.25322 1.17732 5.80531C1.17732 8.35739 3.20644 10.4337 5.70015 10.4337C8.19387 10.4337 10.223 8.35739 10.223 5.80531C10.223 3.25322 8.19415 1.1766 5.70015 1.1766Z"
        fill="#03045E"
      />
      <path
        d="M18.3291 11.6109C15.2009 11.6109 12.6562 9.00645 12.6562 5.8056C12.6562 2.60416 15.2011 0 18.3291 0C21.457 0 24.0016 2.60446 24.0016 5.8056C24.0019 9.00674 21.457 11.6109 18.3291 11.6109ZM18.3291 1.1766C15.8351 1.1766 13.8062 3.25322 13.8062 5.80531C13.8062 8.35739 15.8353 10.4337 18.3291 10.4337C20.8228 10.4337 22.8516 8.35739 22.8516 5.80531C22.8516 3.25322 20.8231 1.1766 18.3291 1.1766Z"
        fill="#03045E"
      />
      <path
        d="M5.67281 23.9998C2.5446 23.9998 0 21.3954 0 18.1942C0 14.9931 2.54489 12.3889 5.67281 12.3889C8.80073 12.3889 11.3456 14.9934 11.3456 18.1942C11.3456 21.3954 8.80102 23.9998 5.67281 23.9998ZM5.67281 13.5655C3.17881 13.5655 1.14997 15.6418 1.14997 18.1939C1.14997 20.746 3.1791 22.8226 5.67281 22.8226C8.16652 22.8226 10.1956 20.746 10.1956 18.1939C10.1956 15.6418 8.16681 13.5655 5.67281 13.5655Z"
        fill="#03045E"
      />
      <path
        d="M18.3017 23.9998C15.1735 23.9998 12.6289 21.3954 12.6289 18.1942C12.6289 14.9931 15.1738 12.3889 18.3017 12.3889C21.4296 12.3889 23.9742 14.9934 23.9742 18.1942C23.9742 21.3954 21.4296 23.9998 18.3017 23.9998ZM18.3017 13.5655C15.8077 13.5655 13.7789 15.6418 13.7789 18.1939C13.7789 20.746 15.808 22.8226 18.3017 22.8226C20.7954 22.8226 22.8243 20.7463 22.8243 18.1939C22.8243 15.6415 20.7954 13.5655 18.3017 13.5655Z"
        fill="#03045E"
      />
    </svg>
  );
}
