export default function NcertIcon() {
  return (
    <svg
      width="27"
      height="29"
      viewBox="0 0 27 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8615 18.4673C23.1849 18.4673 22.6152 19.0014 22.6152 19.7135C22.6152 20.3901 23.1493 20.9598 23.8615 20.9598C24.538 20.9598 25.1077 20.4257 25.1077 19.7135C25.1077 19.0014 24.538 18.4673 23.8615 18.4673ZM23.8615 20.212C23.5766 20.212 23.3274 19.9628 23.3274 19.6779C23.3274 19.3931 23.5766 19.1438 23.8615 19.1438C24.1463 19.1438 24.3956 19.3931 24.3956 19.6779C24.3956 19.9628 24.1463 20.212 23.8615 20.212Z"
        fill="#03045E"
      />
      <path
        d="M17.6662 18.4673C16.9896 18.4673 16.4199 19.0014 16.4199 19.7135C16.4199 20.3901 16.954 20.9598 17.6662 20.9598C18.3427 20.9598 18.9124 20.4257 18.9124 19.7135C18.8768 19.0014 18.3427 18.4673 17.6662 18.4673ZM17.6662 20.212C17.3813 20.212 17.1321 19.9628 17.1321 19.6779C17.1321 19.3931 17.3813 19.1438 17.6662 19.1438C17.951 19.1438 18.2003 19.3931 18.2003 19.6779C18.2003 19.9628 17.951 20.212 17.6662 20.212Z"
        fill="#03045E"
      />
      <path
        d="M2.85367 18.4673C2.17714 18.4673 1.60742 19.0014 1.60742 19.7135C1.60742 20.3901 2.14153 20.9598 2.85367 20.9598C3.56581 20.9598 4.09992 20.4257 4.09992 19.7135C4.09992 19.0014 3.53021 18.4673 2.85367 18.4673ZM2.85367 20.212C2.56881 20.212 2.31956 19.9628 2.31956 19.6779C2.31956 19.3931 2.56881 19.1438 2.85367 19.1438C3.13853 19.1438 3.38778 19.3931 3.38778 19.6779C3.38778 19.9628 3.13853 20.212 2.85367 20.212Z"
        fill="#03045E"
      />
      <path
        d="M10.3322 18.4673C9.65565 18.4673 9.08594 19.0014 9.08594 19.7135C9.08594 20.3901 9.62004 20.9598 10.3322 20.9598C11.0443 20.9598 11.5784 20.4257 11.5784 19.7135C11.5428 19.0014 11.0087 18.4673 10.3322 18.4673ZM10.3322 20.212C10.0473 20.212 9.79808 19.9628 9.79808 19.6779C9.79808 19.3931 10.0473 19.1438 10.3322 19.1438C10.617 19.1438 10.8663 19.3931 10.8663 19.6779C10.8663 19.9628 10.617 20.212 10.3322 20.212Z"
        fill="#03045E"
      />
      <path
        d="M25.7847 0.806152H21.9748C21.5475 0.806152 21.1914 1.26905 21.1914 1.90997V27.5827C21.1914 28.2236 21.5119 28.6865 21.9748 28.6865H25.7847C26.212 28.6865 26.5681 28.2236 26.5681 27.5827V1.90997C26.5681 1.30465 26.212 0.806152 25.7847 0.806152ZM21.9035 7.07301H25.8559V22.4553H21.9035V7.07301ZM25.8559 6.36086H21.9035V4.79415H25.8559V6.36086ZM21.9035 23.1674H25.8559V24.7341H21.9035V23.1674ZM22.0104 1.51829H25.7491C25.7847 1.5539 25.8559 1.69633 25.8559 1.90997V4.08201H21.9035V1.90997C21.9035 1.69633 21.9748 1.5539 22.0104 1.51829ZM25.7491 27.9744H22.0104C21.9748 27.9388 21.9035 27.7964 21.9035 27.5827V25.4463H25.8559V27.5827C25.8559 27.7964 25.7847 27.9388 25.7491 27.9744Z"
        fill="#03045E"
      />
      <path
        d="M19.5543 28.6865C19.9815 28.6865 20.3376 28.2236 20.3376 27.5827V1.90997C20.3376 1.26905 20.0171 0.806152 19.5543 0.806152H15.7443C15.317 0.806152 14.9609 1.26905 14.9609 1.90997V27.5827C14.9609 28.2236 15.2814 28.6865 15.7443 28.6865H19.5543ZM15.6731 7.07301H19.6255V22.4553H15.6731V7.07301ZM19.6255 6.36086H15.6731V4.79415H19.6255V6.36086ZM15.6731 23.1674H19.6255V24.7341H15.6731V23.1674ZM15.7799 1.51829H19.5187C19.5543 1.5539 19.6255 1.69633 19.6255 1.90997V4.08201H15.6731V1.90997C15.6731 1.69633 15.7443 1.5539 15.7799 1.51829ZM15.6731 25.4463H19.6255V27.5827C19.6255 27.7964 19.5543 27.9388 19.5187 27.9744H15.7799C15.7443 27.9388 15.6731 27.7964 15.6731 27.5827V25.4463Z"
        fill="#03045E"
      />
      <path
        d="M5.56027 27.5826V5.00773L8.80052 27.8319C8.83613 28.188 9.01416 28.5084 9.26341 28.6865C9.37023 28.7933 9.51266 28.8289 9.65509 28.8289C9.6907 28.8289 9.72631 28.8289 9.72631 28.8289L13.5007 28.2948C13.7499 28.2592 13.9279 28.0811 14.0348 27.7963C14.106 27.5826 14.1416 27.3334 14.106 27.0841L10.5097 1.66066C10.4741 1.41141 10.3672 1.16216 10.2248 0.984123C10.0468 0.734873 9.79752 0.628052 9.54827 0.663659L5.77391 1.19777C5.63148 1.23337 5.52466 1.26898 5.41784 1.3758C5.27541 1.01973 5.02616 0.806087 4.74131 0.806087H0.966951C0.539665 0.806087 0.183594 1.26898 0.183594 1.90991V27.5826C0.183594 28.2236 0.504058 28.6865 0.966951 28.6865H4.77691C5.2042 28.6865 5.56027 28.2236 5.56027 27.5826ZM0.895736 7.07294H4.84813V22.4552H0.895736V7.07294ZM4.84813 6.3608H0.895736V4.79409H4.84813V6.3608ZM0.895736 23.1674H4.84813V24.7341H0.895736V23.1674ZM13.0022 24.3424L9.08538 24.9121L8.87173 23.381L12.7885 22.8113L13.0022 24.3424ZM8.76491 22.6689L6.59288 7.42901L10.5097 6.8593L12.6817 22.0992L8.76491 22.6689ZM10.4384 6.14716L6.52166 6.71687L6.30802 5.18576L10.2248 4.61605L10.4384 6.14716ZM13.3582 27.5826L9.65509 28.1168C9.61948 28.0811 9.51266 27.9387 9.51266 27.7251L9.22781 25.5886L13.1446 25.0189L13.4294 27.1554C13.4294 27.4046 13.3938 27.547 13.3582 27.5826ZM5.91634 1.90991L9.61948 1.3758C9.65509 1.41141 9.76191 1.55384 9.76191 1.76748L10.0824 3.90391L6.16559 4.47362L5.84513 2.33719C5.84513 2.12355 5.91634 1.98112 5.91634 1.90991ZM1.00256 1.51823H4.74131C4.77691 1.55384 4.84813 1.69627 4.84813 1.90991V4.08194H0.895736V1.90991C0.895736 1.69627 0.966951 1.55384 1.00256 1.51823ZM1.00256 27.9743C0.966951 27.9387 0.895736 27.7963 0.895736 27.5826V25.4462H4.84813V27.5826C4.84813 27.7963 4.77691 27.9387 4.74131 27.9743H1.00256Z"
        fill="#03045E"
      />
    </svg>
  );
}
