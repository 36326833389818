export default function Submission() {
  return (
    <>
      <path
        d="M11.3231 3.9231H2.47472C1.66026 3.9231 1 4.57745 1 5.38464V18.5385C1 19.3457 1.66026 20 2.47472 20H11.3231C12.1375 20 12.7978 19.3457 12.7978 18.5385V5.38464C12.7978 4.57745 12.1375 3.9231 11.3231 3.9231Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.94922 7.5769H9.84812"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.94922 11.2307H9.84812"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.94922 14.8845H6.89867"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.68652 1H14.2722C14.6634 1 15.0385 1.15398 15.315 1.42808C15.5916 1.70217 15.747 2.07392 15.747 2.46154V16.3462"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  );
}
