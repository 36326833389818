import { Button, Col } from "antd";
import React from "react";
import useQuizStore from "../../store/quizStore";
import { IStatus } from "../../interfaces/quiz";

const getIndexClass = (status:IStatus)=>{
  if(status.bookmark) return 'question-bookmarked'
  if(status.answer-1===status.attempt) return 'question-attempted'
  if(status.attempt!==-1 && status.answer-1!==status.attempt) return 'question-incorrect'
  if(status.visited) return 'currentQuestion'
}

export function QuestionGraph({ data,navigate }) {
  const {status} = useQuizStore()
  return (
    <Col span={8} style={{ backgroundColor: "#F5F5F5", borderRadius: "5px" }}>
      <div
        style={{
          width: "80%",
          margin: "auto",
          marginTop: "3%",
        }}
      >
        <p>Select which Question number you want to see.</p>
        <div className="quiz-result-list">
          {data?.map((item, i) => {
            const currentStatus = status[item.id]
            return (
            <Button
              key={i}
              onClick={() => {
                navigate(currentStatus.position)
              }}
              className={`quiz-result-btn ${getIndexClass(currentStatus)}`}
            >
              {i + 1}
            </Button>
          )})}
        </div>
      </div>
    </Col>
  );
}
