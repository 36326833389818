import { Card, Row } from "antd";
import React from "react";
import { Pie } from "react-chartjs-2";

const ActivityCard = () => {
  const data = {
    labels: ["Quiz", "Submission", "Courses"],
    datasets: [
      {
        data: [10, 10, 10],
        backgroundColor: ["#B8BAFE", "#6F72CD", "#2E3192"],
        borderWidth: 0,
      },
    ],
  };
  return (
    <>
   
      <Card style={{ width: "100%", height: "100%" }} size="small">
        <Row>
          <h3>Activity</h3>
          <Pie
            data={data}
            options={{
              plugins: {
                legend: {
                  position: "bottom",
                  labels: {
                    padding: 15,
                    usePointStyle: true,
                    font: {
                      size: 10,
                    },
                  },
                },
              },
            }}
          />
        </Row>
      </Card>
    </>
  );
};

export default ActivityCard;
