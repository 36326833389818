import { Row, Col } from "antd";
import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import styled from "styled-components";
import QuizFooter from "../../component/quiz/QuizFooter";
import QuizModal from "../../component/quiz/QuizModal";
import QuizBody from "../../component/quiz/attempt/quizBody";
import { QuizHeader } from "../../component/quiz/attempt/quizHeader";
import useMobile from "../../hooks/useMobile";
import QuizDrawer from "../../component/quiz/QuizDrawer";
import useQuizStore from "../../store/quizStore";
import HeaderLayout from "../../component/quiz/attempt/headerLayout";
import { LANG } from "../../component/quiz/attempt/LanguageConvert";

const { Content } = Layout;

export default function AttemptQuestion() {
  const isMobile = useMobile();
  const [drawerOpen, setDrawerOpen] = useState(
    typeof window != "undefined"
      ? window?.screen?.width < 600
        ? false
        : true
      : true
  );
  const [pageId, setPageId] = useState(1);
  const [language, setLanguage] = useState(LANG.En);
  const [collapsed, setCollapsed] = useState(isMobile);
  const [isReportVisible, setReportVisible] = useState(false);
  const [navigateTo, setNavigateTo] = useState<"submit" | "quit">("submit");
  const { quiz, paginate } = useQuizStore();
  const { questions, qpp } = paginate(pageId);
  const [isTimeEnd, setIsTimeEnd] = useState(false);

  const onScroll = (index) => {
    const smallScreen = window?.screen?.width < 600;
    if (smallScreen) {
      setDrawerOpen(!drawerOpen);
    }
    setPageId(index % qpp === 0 ? index / qpp : Math.floor(index / qpp) + 1);
    setTimeout(
      () => {
        const element = document.getElementById(index);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      },
      smallScreen ? 400 : 100
    );
  };

  return (
    <>
      <Row className="bg-image">
        <Layout className="layout">
          <Layout>
            <Content style={{ position: "relative" }}>
              <div style={{ height: "92vh" }}>
                <div style={{ height: "10%" }}>
                  <HeaderLayout
                    onQuit={() => {
                      setNavigateTo("quit");
                      setReportVisible(true);
                    }}
                    quiz={quiz}
                    language={language}
                    handleScroll={onScroll}
                    handleModal={() => {}}
                  />
                </div>
                <div
                  style={{
                    height: "80%",
                  }}
                >
                  {questions.map((item) => (
                    <>
                      <Row
                        align={"middle"}
                        style={{
                          height: "10%",
                          padding: "0% 2% 1% 1%",
                          background: "white",
                        }}
                      >
                        <QuizHeader
                          onCompleteTimer={() => {
                            // setReportVisible(true);
                            setIsTimeEnd(true);
                            setNavigateTo("submit");
                          }}
                          item={item}
                          language={language}
                          setLanguage={setLanguage}
                        />
                      </Row>
                      <Row
                        style={{
                          height: "90%",
                          paddingTop: "3%",
                          paddingBottom: "4%",
                          overflowY: "scroll",
                          background: "white",
                        }}
                      >
                        <QuizBody language={language} item={item} />
                      </Row>
                    </>
                  ))}
                </div>
                <div style={{ height: "10%" }}>
                  <QuizFooter
                    pageId={pageId}
                    paginate={(id: number) => setPageId(id)}
                    onClickSubmit={() => {
                      setNavigateTo("submit");
                      setReportVisible(true);
                    }}
                    length={
                      quiz?.questions.length! % qpp === 0
                        ? Math.floor(quiz?.questions?.length! / qpp)
                        : Math.floor(quiz?.questions?.length! / qpp) + 1
                    }
                  />
                </div>
              </div>
              <ToggleDrawer onClick={() => setCollapsed(!collapsed)}>
                <div
                  style={{
                    paddingTop: "110%",
                    color: "black",
                    cursor: "pointer",
                  }}
                >
                  {collapsed ? <LeftOutlined /> : <RightOutlined />}
                </div>
              </ToggleDrawer>
            </Content>
            <QuizDrawer
              onScroll={onScroll}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
          </Layout>
        </Layout>
      </Row>

      <QuizModal
        isVisible={isReportVisible}
        setVisible={setReportVisible}
        navigateTo={navigateTo}
        isTimeEnd={isTimeEnd}
      />
    </>
  );
}

const ToggleDrawer = styled.div`
  width: 15px;
  height: 50px;
  background: #f0f0f0;
  position: absolute;
  top: 45%;
  right: 0;
  border-radius: 2px;
  text-align: "center";
  font-size: "1.1em";
  cursor: "pointer";
`;
