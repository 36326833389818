import React, { useState } from "react";
import { useApi, useMount } from "../../hooks";
import AppMetaHeader from "../../component/AppMetaHeader";
import PageContainer from "../../component/Layout/PageContainer";
import { useNcertStore } from "../../store/useNcertStore";
import { useSearch } from "@tanstack/react-router";
import { Card, Col, Drawer, Row } from "antd";
import CustomCard, { TitleContainer } from "../../component/GlobalCard";
import PdfViewer from "../../utils/pdfViewer";
import AppSwitch from "../../component/AppSwitch";
import DownloadCard from "./DownloadCard";
import NcertIcon from "../../component/icons/NcertIcon";
import Shimmer from "../../component/Shimmer";

interface IResource {
  content: string;
  name: string;
}

const ContentListing = () => {
  const [open, setOpen] = useState(false);
  const [resource, setResource] = useState<IResource>();
  const [isChecked, setIsChecked] = useState(false);

  const { c, subject } = useSearch({
    from: "/study-materials/ncert-book-pdfs",
  });

  const { className } = useNcertStore();

  const { data, load, isLoading } = useApi<any[]>(`/standards/${c}/${subject}`);

  const [english, hindi] = [[], []];

  data?.forEach((item) => {
    item.lang === "en" && english.push(item);
    item.lang === "hi" && hindi.push(item);
  });

  useMount(() => {
    load();
  }, []);

  const showDrawer = (item) => {
    setOpen(true);
    setResource(item);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppMetaHeader currentPageTitle="NCERT Books" />

      <PageContainer
        currentPageTitle={subject.charAt(0).toUpperCase() + subject.slice(1)}
        title={className}
        url={`/study-materials/ncert-book-pdf?c=${c}`}
      >
        {/* <Card> */}

        <AppSwitch
          onValueChange={() => setIsChecked(!isChecked)}
          //   title="Select by Course / TestSeries"
          onLabel="English"
          offLabel="Hindi"
        />
        <Shimmer type="downloads" loading={isLoading} count={4}>
          <Row gutter={24}>
            {(isChecked ? hindi : english)?.map((item) => (
              <Col
                xxl={4}
                xl={6}
                lg={8}
                md={12}
                sm={12}
                xs={24}
                style={{ marginBottom: "2%" }}
              >
                {/* <Link
                  to={`/downloads/ncert-book-pdfs?c=${c}&subject=${item?.slug}`}
                > */}

                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    showDrawer(item);
                  }}
                >
                  {/* <CustomCard
                  title={<TitleContainer>{item?.name}</TitleContainer>}
                  type="avatar"
                  // desc={
                  //   <SubTitleContainer>

                  //   </SubTitleContainer>
                  // }
                  // avatar={{
                  //   url: elem.thumb || (elem.other_img as string),
                  //   height: "100%",
                  //   width: "90%",
                  //   className: "img-tag",
                  // }}
                  className={"card-active"}
                /> */}

                  <DownloadCard title={item?.name} avatar={<NcertIcon />} />
                </div>
                {/* </Link> */}
              </Col>
            ))}
          </Row>
        </Shimmer>
        {/* </Card> */}
      </PageContainer>

      <Drawer title={resource?.name} onClose={onClose} open={open} width="70%">
        <PdfViewer pdfUrl={resource?.content} height={"700px"} />
      </Drawer>
    </>
  );
};

export default ContentListing;
