import React from "react";

const speeds = [
  { name: "2 x", value: 2 },
  { name: "1.75 x", value: 1.75 },
  { name: "1.5 x", value: 1.5 },
  { name: "1.25 x", value: 1.25 },
  { name: "1 x", value: 1 },
  { name: "0.5 x", value: 0.5 },
];
export default function RateControl({ setRate, rate }) {
  return (
    <ul>
      <li
        style={{ paddingTop: "0.3em", paddingBottom: "0.3em" }}
        onClick={() => setRate(rate)}
      >
        <div style={{ marginRight: "0.8em" }}>{`<`}</div>
        <div style={{ fontSize: "0.9em" }}>Playback Speed</div>
      </li>
      <li>
        <div
          style={{
            background: "white",
            height: "0.01em",
            marginBottom: "0.2em",
            width: "100%",
          }}
        ></div>
      </li>
      {speeds.map((q) => (
        <li
          style={{ fontSize: "0.9em" }}
          onClick={() => {
            setRate(q);
          }}
        >
          {q.name}
        </li>
      ))}
    </ul>
  );
}
