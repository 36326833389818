import axios from "axios";
import base_url from "./baseUrl";
import { autoErrorHandle } from "./autoErrorHandle";

const api = axios.create({
  baseURL: base_url,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const authorizeApi = (token: string | null) => {
  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    autoErrorHandle(error);
    throw error;
  }
);

export default api;
