import { Button, Row, Col } from "antd";
import React from "react";

const QuizFooter = ({ pageId, paginate, length, onClickSubmit }) => {
  return (
    <Row
      align="middle"
      style={{
        backgroundColor: "white",
        height: "100%",
        borderTop: "1px solid #00000040",
      }}
    >
      <Col span={24}>
        <Row style={{ marginLeft: "1.5rem" }}>
          <Col
            lg={12}
            xl={12}
            md={12}
            sm={12}
            xs={12}
            style={{ display: "flex" }}
          >
            {pageId > 1 && (
              <Button
                style={{ fontWeight: 800 }}
                className={pageId.length === 0 ? "text" : "quiz-start-btn-self"}
                onClick={() => paginate(pageId - 1)}
              >
                Previous
              </Button>
            )}
            {pageId < length && (
              <Button
                style={{ fontWeight: 800 }}
                className="quiz-start-btn-self"
                onClick={() => paginate(pageId + 1)}
              >
                Next
              </Button>
            )}
          </Col>
          <Col
            lg={12}
            xl={12}
            md={12}
            sm={12}
            xs={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              style={{ fontWeight: 800 }}
              className="quiz-start-btn-self"
              onClick={() => onClickSubmit()}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default QuizFooter;
