import React, { useState } from "react";
import { Row, Col, Card, Tag, Button, Input, Tooltip } from "antd";
import { QuestionGraph } from "../QuestionGraph";
import useQuizStore from "../../../store/quizStore";
import parse from "html-react-parser";
import { onCopy } from "../../../helpers/help";
import { IQuestion } from "../../../interfaces/quiz";
import styled from "styled-components";
import { LANG } from "../attempt/LanguageConvert";
import { Link, useRouter } from "@tanstack/react-router";

const { TextArea } = Input;

type TOptionProps = {
  option: string;
  index: number;
  question: IQuestion;
};

const ReviewAndExplanation = () => {
  const router = useRouter();
  const [language, setLanguage] = useState(LANG.En);
  const [pageId, setPageId] = useState(1);
  const { quiz, paginate, status } = useQuizStore();
  const { questions } = paginate(pageId);

  const getOptionColor = (question, index) => {
    const { attempt, answer } = status[question.id];
    if (index === answer - 1) return "#22c55e";
    if (index === attempt) return "#ef4444";
  };
  const Option = ({ option, index, question }: TOptionProps) => {
    const backgroundColor = getOptionColor(question, index);
    if (!option) return;
    return (
      <OptionContainer key={index} $backgroundColor={backgroundColor}>
        <div
          style={{
            marginRight: "15px",
            boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px",
            fontWeight: "bold",
            padding: "5px 12px",
            borderRadius: "6px",
            cursor: "pointer",
            backgroundColor: backgroundColor,
            color: backgroundColor ? "white" : "",
            border: "#d9d9d9",
          }}
        >
          {String.fromCharCode(65 + index)}
        </div>
        <div>{parse(String(option))}</div>
      </OptionContainer>
    );
  };
  const handleNavigation = (position: number) => {
    setPageId(position);
    const element = document.getElementById("explanation");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container>
      <Title>
        <span
          style={{ marginRight: "1%", cursor: "pointer" }}
          onClick={() => router.history.back()}
        >
          <Tooltip title="Exit" arrow={false} placement="top">
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.41 10.4064L2.83 5.99999L7.41 1.59359L6 0.23999L0 5.99999L6 11.76L7.41 10.4064Z"
                fill="#202224"
              />
            </svg>
          </Tooltip>
        </span>
        Review and Explanation
      </Title>
      <SubContainer>
        <Row>
          <Col span={16}>
            <Row>
              <Col span={24}>
                <LangButton
                  onClick={() => setLanguage(LANG.Hi)}
                  $active={language === LANG.Hi}
                >
                  Hindi
                </LangButton>
                <LangButton
                  onClick={() => setLanguage(LANG.En)}
                  $active={language === LANG.En}
                >
                  English
                </LangButton>
              </Col>
            </Row>

            <p
              style={{
                marginBottom: "0%",
                fontSize: "1.1em",
                fontWeight: 600,
                marginTop: "1%",
              }}
            >
              {"Question:" + pageId}
            </p>

            {questions.map((question) => {
              return (
                <>
                  <Row align="middle" style={{ marginTop: "10px" }}>
                    <Col span={24}>
                      <b onCopy={onCopy} className="review-questions">
                        {parse(
                          language === LANG.Hi ? question?.hi.q : question?.q
                        )}
                      </b>
                      <Row align="middle">
                        <Col xl={15} lg={18} md={21} sm={22} xs={22}>
                          {language === LANG.En ? (
                            <>
                              {(question?.options || []).map(
                                (option, index) => (
                                  <Option
                                    question={question}
                                    option={option}
                                    index={index}
                                  />
                                )
                              )}
                            </>
                          ) : (
                            <>
                              {(question?.hi?.options || []).map(
                                (option, index) => (
                                  <Option
                                    question={question}
                                    option={option}
                                    index={index}
                                  />
                                )
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col xl={20} lg={20} md={21} sm={22} xs={22}>
                      <div>
                        <h4>Explanation:</h4>
                        {question.note === null
                          ? "Explanation not available!"
                          : parse(
                              language === LANG.Hi
                                ? String(question?.hiNote)
                                : String(question?.note)
                            )}
                      </div>
                    </Col>
                  </Row>
                </>
              );
            })}
          </Col>
          <QuestionGraph navigate={handleNavigation} data={quiz?.questions} />
        </Row>
        <Col span={24}>
          {pageId > 1 && (
            <Button
              onClick={() => setPageId((pageId) => pageId - 1)}
              style={{ fontWeight: 800 }}
              className="quiz-start-btn-self"
            >
              Prev
            </Button>
          )}
          {pageId < quiz?.questions.length! && (
            <Button
              onClick={() => setPageId((pageId) => pageId + 1)}
              style={{ fontWeight: 800 }}
              className="quiz-start-btn-self"
            >
              Next
            </Button>
          )}
        </Col>
      </SubContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 0.5em;
`;

const SubContainer = styled.div`
  overflow-y: scroll;
  height: 62vh;
  padding: 2%;
`;

const Title = styled.p`
  font-weight: 600;
  background: #eaf0ff;
  padding: 1% 1% 1% 2%;
  border-radius: 0.3em 0.3em 0em 0em;
  margin-bottom: 0%;
  font-size: 1.2em;
`;
export default ReviewAndExplanation;

const LangButton = styled(Tag)<{ $active?: boolean }>`
  padding: 5px 15px;
  border-radius: 30px;
  cursor: pointer;
  background-color: ${(props) => (props.$active ? "#e6f7ff" : "")};
  border-color: ${(props) => (props.$active ? "#4A3AFF" : "")};
  color: ${(props) => (props.$active ? "#4A3AFF" : "")};
`;

const OptionContainer = styled.div<{ $backgroundColor?: string }>`
  display: flex;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 8px;
  margin-right: 8px;
  cursor: pointer;
  align-items: center;
  background-color: ${(props) => props.$backgroundColor};
  color: ${(props) => (props.$backgroundColor ? "white" : "")};
  border: 1px solid #d9d9d9;
`;
