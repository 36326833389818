//  ...................global card code here .............
import { Avatar, Button, Card, Col, Row } from "antd";
import React, { ReactElement } from "react";
import styled from "styled-components";
import parse from "html-react-parser";
type cardType = "avatar" | "cover" | "content";
type buttonType = "primary" | "secondary" | "danger" | "success";
type posType = "top-left" | "top-right";
type buttonSize = "small" | "large" | "medium";
const { Meta } = Card;

interface IActionsButton {
  title: string | React.JSX.Element;
  subtitle: string;
  onClick: (params: any) => void;
  type?: buttonType;
  // size?: string;
  size?: buttonSize;
  pos?: posType;
  className?: string;
  icons?: string;
  tabs?: string;
  logo?: string;
}
interface IBorderStyle {
  borderWidth: number | string;
  borderColor: string;
  borderRadius: number | string;
}

interface ITitle {
  text: string;
  style: { fontSize: 15; color: "pink" };
}

interface IAvatar {
  url: string;
  height?: number | string;
  width?: number | string;
  className?: string;
}

interface ICover {
  url?: string;
  height?: number | string;
  width?: number | string;
  className?: string;
}

type optionPos = "top" | "bottom" | "left" | "right";
interface IOptions {
  title: string;
  clickHandler: (params?: any) => void;
  position: optionPos;
}

// className`cardoptions-${options.position}`

interface IProps {
  type: cardType;
  title?: string | React.JSX.Element;
  desc?: string | React.JSX.Element;
  hoverable?: boolean;
  loading?: boolean;
  // cover?: string | React.JSX.Element;
  cover?: ICover;
  avatar?: IAvatar;
  date?: string;
  actions?: IActionsButton[] | React.JSX.Element;
  border?: IBorderStyle;
  className?: string;
  question?: string;
  icons?: string | number | React.JSX.Element;
  style?: string;
}

export default function CustomCard(props: IProps) {
  const coverCard = () => {
    return (
      <Card
        bodyStyle={{ padding: "0.5em" }}
        cover={
          props.icons ? (
            <Row className="imageContainer">
              <img
                alt="example"
                src={props.cover?.url}
                width={props.cover?.width}
                height={props.cover?.height}
                className={props.cover?.className}
              />
              <Row className="videoPlay">
                <Row className="videoPlayButton">{props.icons}</Row>
              </Row>
            </Row>
          ) : (
            <Row style={{ padding: "0.9em" }}>
              <img
                alt="example"
                src={props.cover?.url}
                width={props.cover?.width}
                height={props.cover?.height}
                className={props.cover?.className}
              />
            </Row>
          )
        }
        bordered={props?.border ? true : false}
        style={{ ...props.border }}
      >
        <div style={{ paddingLeft: "0.5em", paddingRight: "0.7em" }}>
          <Meta title={props.title} description={props.desc} />

          {props.actions?.map((ab) => (
            <Button className={ab.className} onClick={ab.onClick}>
              {
                <span>
                  {ab.title}
                  {ab.subtitle}
                </span>
              }
            </Button>
          ))}
        </div>
        {props.date && <DateContainer>{props.date}</DateContainer>}
      </Card>
    );
  };

  const avatarCard = () => {
    return (
      <Card
        loading={props?.loading}
        className={props.className}
        bordered={props?.border ? true : false}
        style={props.style}
      >
        <Container>
          <Meta
            avatar={
              <img
                src={props?.avatar?.url}
                width={props?.avatar?.width}
                height={props?.avatar?.height}
                className={props?.avatar?.className}
              />
            }
            title={
              <TitleContainer className="card-content">
                {props.title}
              </TitleContainer>
            }
            description={<SubTitleContainer>{props.desc}</SubTitleContainer>}
            date={<ParaContainer>{props.date}</ParaContainer>}
          />
          {props?.actions?.map((action) => (
            <Button
              className={action.className}
              size={action.size}
              onClick={action.onClick}
            >
              {action.title}
            </Button>
          ))}
        </Container>
      </Card>
    );
  };

  const contentCard = () => {
    return (
      <Card hoverable>
        <Container>
          <Row>
            {props.actions?.map((tabBtn) => (
              <BottomContaner>{tabBtn.subtitle}</BottomContaner>
            ))}
          </Row>
          <Row>
            <DateContainer>{props.date}</DateContainer>
          </Row>
        </Container>
        <ParaContainer>{props.question}</ParaContainer>
        <Meta
          title={<SpanContainer>{parse(props.title)}</SpanContainer>}
          description={props.desc}
        />
        <Row
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {props.actions?.map((ab) => (
            <BottomContaner onClick={() => ab.onClick(props.type)}>
              {ab.title}
            </BottomContaner>
          ))}
        </Row>
      </Card>
    );
  };

  switch (props.type) {
    case "avatar":
      return avatarCard();
    case "cover":
      return coverCard();
    case "content":
      return contentCard();
  }
}

//  ...................global card code here .............end;

export const TitleContainer = styled.div`
  color: #2f2e41;
  font-family: Urbanist;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 0.2rem;
  white-space: nowrap;
  // white-space:normal;
`;
export const SubTitleContainer = styled.div`
  color: #757575;
  font-family: Urbanist;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: -0.2rem;
  white-space: nowrap;
`;

export const BottomContaner = styled.button`
  background-color: #e2f1ff;
  color: #113d66;
  font-weight: 500;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.8125rem;
  margin-right: 0.625rem;
  padding: 0.25rem 0.75rem;
  box-sizing: border-box;
`;

const ParaContainer = styled.p`
  color: #9e9e9e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;

  margin-top: 0.7rem;
  margin-bottom: 0;
  line-height: normal;
  color: black;
`;

const SpanContainer = styled.div`
  white-space: pre-wrap;
  text-align: justify;
  color: #000;
  margin-top: 0.5rem;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const H3 = styled.h3`
  color: #9b9b9b;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const Question = styled.h3`
  color: #2f2e41;
  font-family: Urbanist;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const P = styled.p`
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Urbanist;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const HeaderContainer = styled.span`
  color: #2f2e41;
  font-family: Urbanist;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  padding-left: 0.9375rem;
  display: flex;
  justify-content: flex-start;
  line-height: 3.125rem;
`;
export const QuizHeader = styled.h3`
  color: #2e3192;
  font-family: Urbanist;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TableHeader = styled.span`
  padding-left: 0.9rem;
  line-height: 3.125rem;
`;
export const TableContainer = styled.div`
  width: 100%;
  height: 10vh;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  z-index: 2;
  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const DateContainer = styled.p`
  color: #757575;
  font-size: 0.8em;
  padding-left: 0.7em;
  margin-top: 0.8em;
  margin-bottom: 0%;
`;
