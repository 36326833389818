import React from "react";
import Loader from "../loader";

interface IProps {
  visible: boolean;
  loading: boolean;
  playing: boolean;
  onForward: () => void;
  onBackward: () => void;
  onPlay: () => void;
}

export default function ActionButton({
  loading,
  playing,
  visible,
  onForward,
  onBackward,
  onPlay,
}: IProps) {
  return (
    <div className="custom-player-wrapper" id="custom-player">
      {visible && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "90%",
          }}
        >
          <div className="play-btn-container" onClick={onBackward}>
            <i className="bx bx-rewind"></i>
          </div>

          <div className="play-btn-container" onClick={onPlay}>
            {loading ? (
              <Loader />
            ) : (
              <i
                className={`bx bx-${playing ? "pause" : "play"}-circle center`}
              ></i>
            )}
          </div>
          <div className="play-btn-container" onClick={onForward}>
            <i className="bx bx-fast-forward"></i>
          </div>
        </div>
      )}
    </div>
  );
}
