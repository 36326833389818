import React, { useState } from "react";
import { useApi, useMount } from "../../hooks";
import { Table } from "antd";
import { dateParse } from "../../helpers/help";
import styled from "styled-components";

const Activities = ({ id }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState();
  const [total, setTotal] = useState();
  const { data, load, isLoading } = useApi<any>(`test-series/${id}/activities`);

  useMount(() => {
    load();
    setPerPage(data?.data?.per_page);
    setTotal(data?.data?.total);
  });

  const columns = [
    {
      title: <Title>Test Name</Title>,
      dataIndex: "0",
      key: "0",
      render: (_, { quiz }) => <SubTitle>{quiz?.title}</SubTitle>,
      width: 350,
    },
    {
      title: <Title>Total Question</Title>,
      dataIndex: "1",
      key: "1",
      render: (_, { total }) => <SubTitle>{total}</SubTitle>,
      width: 180,
    },
    {
      title: <Title>Attempt</Title>,
      dataIndex: "2",
      key: "2",
      render: (_, { attempt }) => <SubTitle>{attempt}</SubTitle>,
      width: 110,
    },
    {
      title: <Title>Correct</Title>,
      dataIndex: "3",
      key: "3",
      render: (_, { correct }) => <SubTitle>{correct}</SubTitle>,
      width: 100,
    },
    {
      title: <Title>Score/Total Marks</Title>,
      dataIndex: "4",
      key: "4",
      render: (_, { score, outof }) => (
        <SubTitle>
          {score}/{outof}
        </SubTitle>
      ),
      width: 200,
    },
    {
      title: <Title>Time Taken</Title>,
      dataIndex: "5",
      key: "5",
      render: (_, { time_duration }) => <SubTitle>{time_duration}</SubTitle>,
      width: 150,
    },
    {
      title: <Title>Date</Title>,
      dataIndex: "6",
      key: "6",
      render: (_, { created_at }) => (
        <SubTitle>{dateParse(created_at)}</SubTitle>
      ),
      width: 150,
    },
  ];

  return (
    <Table
      loading={isLoading}
      columns={columns}
      dataSource={data?.data}
      scroll={{ y: 500, x: 800 }}
      pagination={{
        total: total,
        current: page,
        pageSize: perPage,
        showSizeChanger: false,
        onChange: (page) => setPage(page),
      }}
    />
  );
};

const Title = styled.p`
  font-size: 1.2em;
  margin-bottom: 0%;
  padding: 1em 1em 1em 1em;
  font-weight: 600;
`;

const SubTitle = styled.p`
  font-size: 1em;
  margin-bottom: 0%;
  padding: 1em 1em 1em 1em;
  font-weight: 600;
`;

export default Activities;
