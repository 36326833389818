import { Button, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import CustomModal from "../../component/common/CustomModal";
import showNotification from "../../utils/notify";
import api from "../../config/api";

const CommentReply = ({ userName, id }) => {
  const [loading, setLoading] = useState(false);
  const [isModelOpen, setModelOpen] = useState(false);
  const [content, setContent] = useState();

  const openModal = (content) => {
    setModelOpen(true);
    setContent(content);
  };

  const onFinish = async (values: string) => {
    const formValue = { reply: values.reply };
    try {
      setLoading(true);
      const { data } = await api.post(
        `answers/${values?.id}/comments`,
        formValue
      );
      if (data?.status === true) {
        showNotification("success", "Reply given successfully", 3);
        setTimeout(() => {
          window.location.href = "/submission";
        }, 1000);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <>
      <Button
        onClick={() => {
          openModal(
            <>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "1.2em",
                }}
              >
                Reply to {userName}
              </p>
              <Form size="large" layout="vertical" onFinish={onFinish}>
                <Form.Item
                  name="reply"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Reply!",
                    },
                  ]}
                >
                  <TextArea id="reply" rows={10} placeholder="Enter Reply" />
                </Form.Item>

                <Form.Item name="id" initialValue={id}>
                  <Input
                    id="id"
                    placeholder="Enter Reply"
                    disabled={true}
                    hidden
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    loading={loading}
                    disabled={loading}
                    type="primary"
                    htmlType="submit"
                    size="large"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </>
          );
        }}
        style={{ marginBottom: "2%" }}
      >
        Reply
      </Button>

      <CustomModal
        height="80vh"
        ConfirmationText="Close"
        onDone={() => setModelOpen(false)}
        open={isModelOpen}
        width={"80%"}
        style={"modal-wrapper-answer"}
        hideModal={() => setModelOpen(false)}
        message={content}
      />
    </>
  );
};

export default CommentReply;
