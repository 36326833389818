import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import AnswerWritingSubmissions from "./AnswerWritingSubmissions";
import AnsWritingStart from "./AnsWritingCategory";
import PageContainer from "../../component/Layout/PageContainer";
import AppMetaHeader from "../../component/AppMetaHeader";

const MySubmission = () => {
  const [loading, setLoading] = useState(false);
  const [isClicked, setIsClicked] = React.useState(false);
  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    document.title = "Answer Writing | Khan Global Studies";
  }, []);

  const handleTabChange = (key: any) => {
    setActiveKey(key);
    setIsClicked(!isClicked);
  };

  const items = [
    {
      key: "1",
      label: (
        <span className={activeKey === "1" ? (!isClicked ? "tabs_1" : "") : ""}>
          All Answer Writing
        </span>
      ),
      children: <AnsWritingStart />,
    },

    {
      key: "2",
      label: (
        <span className={activeKey === "2" ? (isClicked ? "tabs_1" : "") : ""}>
          Answer Writing Submission
        </span>
      ),
      children: <AnswerWritingSubmissions />,
    },
  ];

  const getCurrentPageTitle = () => {
    const activeItem = items.find((item) => item.key === activeKey);

    return `${activeItem ? activeItem.label.props.children : ""}`;
  };

  return (
    <>
      <AppMetaHeader currentPageTitle="Answer Writing" />
      <PageContainer
        currentPageTitle={getCurrentPageTitle()}
        loading={loading}
        transparent={true}
      >
        <Tabs
          tabBarStyle={{
            width: "100%",
            position: "fixed",
            zIndex: "1",
            backgroundColor: "#EDF4FF",
            paddingBottom: "0.5%",
          }}
          size="large"
          items={items}
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={handleTabChange}
        />
      </PageContainer>
    </>
  );
};

export default MySubmission;
