import { Card } from "antd";
import React, { memo } from "react";
import DataCard from "./DataCard";

interface StatCardProps {
  icon: React.ReactNode;
  icon2: React.ReactNode;
  title: string;
  number: number;
  color?: string;
  back?: string;
}

const StatCard = ({
  icon,
  title,
  number,
  color = "#171A68",
  back = "",
  icon2,
}: StatCardProps) => {
  return (
    <Card style={{ backgroundColor: back, fontSize: "0.7rem" }} size="small">
      <DataCard
        title={title}
        value={number}
        precision={2}
        valueStyle={{ color: color }}
        prefix={icon}
        icon2={icon2}
        suffix=" "
      />
    </Card>
  );
};

export default memo(StatCard);
