import { create } from "zustand";
import { createJSONStorage, persist } from 'zustand/middleware';

interface INotificationStore {
    notification: any[];
    readNotification: any[];
    setNotification: (data: any) => void;
    loading: boolean
    setLoading: any
    setMarkAsRead: (id: number) => void;
}


export const useNotificationStore = create(persist<INotificationStore>((set) => ({
    notification: [],
    loading: true,
    readNotification: [],
    setLoading: (loading) => set({ loading }),

    setNotification: (data) => {
        if (data?.length) {
            set((state) => {
                return { ...state, notification: data };
            });
        }
    },

    // setMarkAsRead: (id) => {
    //     set((state) => ({
    //         ...state,
    //         //unique element store
    //         readNotification: [...state.readNotification, id],
    //     }));
    // }

    setMarkAsRead: (id) => {
        set((state) => ({
            ...state,
            readNotification: state.readNotification.includes(id)
                ? state.readNotification
                : [...state.readNotification, id],
        }));
    }
}), {
    name: "useNotificationStore",
    storage: createJSONStorage(() => localStorage),
}))