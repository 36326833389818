import { Row, Col, Card, Tag } from "antd";
import React, { useState } from "react";
import { formatDateTimeAMPM } from "../../helpers/help";
import DoubtsReply from "./DoubtsReply";
import CustomModal from "../../component/common/CustomModal";
import styled from "styled-components";
import moment from "moment";
import PdfViewer from "../../utils/pdfViewer";

type IProps = {
  title: string;
  type: string;
  query: string;
  asked_at: string;
  attachment?: File;
  reply: string;
  lesson: string;
  lecture: string;
};
const AskedDoubtCard = ({
  title,
  type,
  query,
  asked_at,
  attachment,
  reply,
  lesson,
  lecture,
}: IProps) => {
  const [isModelOpen, setModelOpen] = useState(false);
  const [content, setContent] = useState();

  const openModal = (content) => {
    setModelOpen(true);
    setContent(content);
  };

  return (
    <Card style={{ borderRadius: "1.5em" }}>
      <Row>
        <Col span={10}>
          <b>Course Name:</b>
        </Col>
        <Col span={14} className="card-content">
          {title}
        </Col>
        <Col span={10}>
          <b>Lesson:</b>
        </Col>
        <Col span={14} className="card-content">
          {lesson}
        </Col>
        <Col span={10}>
          <b>Lecture:</b>
        </Col>
        <Col span={14} className="card-content">
          {lecture}
        </Col>
        <Col span={10}>
          <b>Doubt Type:</b>
        </Col>
        <Col span={14}>{type}</Col>
        {query && (
          <>
            <Col span={10}>
              <b>Query:</b>
            </Col>
            <Col span={14} className="card-content">
              {query}
            </Col>
          </>
        )}
        <Col span={10}>
          <b>Asked on:</b>
        </Col>
        {/* <Col span={14}>{formatDateTimeAMPM(asked_at)}</Col> */}
        <Col span={14}>{new Date(asked_at).toLocaleString()}</Col>

        <Col span={12} style={{ marginTop: "2%" }}>
          {attachment === "" ? (
            <TagButton2>My Attachment</TagButton2>
          ) : (
            <TagButton
              onClick={() =>
                openModal(
                  // <img src={attachment} alt="Attachment" />
                  attachment && attachment?.endsWith(".pdf") ? (
                    <PdfViewer pdfUrl={attachment} height={"100%"} />
                  ) : (
                    <img src={attachment} alt="file" />
                  )
                )
              }
            >
              My Attachment
            </TagButton>
          )}
        </Col>

        <Col span={12} style={{ marginTop: "2%" }}>
          {reply === null ? (
            <TagButton2>View Response</TagButton2>
          ) : (
            <TagButton
              onClick={() =>
                openModal(<DoubtsReply query={query} reply={reply} />)
              }
            >
              View Response
            </TagButton>
          )}
        </Col>
      </Row>
      <CustomModal
        height="60vh"
        ConfirmationText="Close"
        onDone={() => setModelOpen(false)}
        open={isModelOpen}
        width={"60%"}
        hideModal={() => setModelOpen(false)}
        message={content}
      />
    </Card>
  );
};

const TagButton = styled(Tag)`
  background: #eaf0ff;
  color: #2e3192;
  border: none;
  font-weight: 700;
  width: 90%;
  text-align: center;
  padding: 0.3em;
  border-radius: 1em;
  cursor: pointer;
`;

const TagButton2 = styled(Tag)`
  background: #ededed;
  color: #727272;
  border: none;
  font-weight: 700;
  width: 90%;
  text-align: center;
  padding: 0.3em;
  border-radius: 1em;
  cursor: not-allowed;
`;
export default AskedDoubtCard;
