import React, { useState } from "react";
import { useMount } from "../../hooks";
import UniPlayer from "../../utils/uniPlayer";
import useControllerVisible from "../../hooks/useControllerVisible";
import { Image } from "antd";
import HlsPlayer from "./hlsPlayer";
import useOrientation from "../../hooks/useOrientation";
import YoutubeWrapper from "./youtubeWrapper";
import { useAuthStore } from "../../store/auth";

const CustomPlayer = ({ selectedVideo, onEnd, isLive, url }) => {
  const [player, setPlayer] = useState<UniPlayer | undefined>();
  // const [quality, setQuality] = React.useState("360p");
  const [poster, setPoster] = React.useState(true);
  const src = player?.videoUrl || player?.video_url || url;
  const { isController } = useControllerVisible(src);
  const { isFullScreen, setIsFullScreen } = useOrientation();
  const thumb = player?.thumb;

  const hdSrc = player?.hd_video_url;

  const { user } = useAuthStore();

  useMount(() => {
    if (!selectedVideo) return;
    const player = new UniPlayer(selectedVideo);
    setPlayer(player);
  }, [selectedVideo]);

  // useMount(() => {
  //   setPoster(true);
  //   if (src?.includes(".m3u8")) {
  //     setQuality("Auto");
  //   }
  // });

  return (
    <div className="Container" id="video-container">
      <div
        className="video-container "
        id="custom-video-container"
        style={{ position: "relative" }}
      >
        <div
          style={{
            height: `100%`,
            width: "100%",
            overflow: "hidden",
            background: "black",
          }}
        >
          <div className="custom-player-wrapper">
            {thumb && <Image src={thumb} width={"70%"} />}
          </div>
          <span className="blinkPhone">{user?.phone}</span>
          {!src && (
            <div
              className="custom-player-wrapper"
              style={{ textAlign: "center", fontSize: "2em", color: "red" }}
            >
              Coming Soon ! Hold Tight.
            </div>
          )}

          {src?.includes("m3u8") ? (
            <HlsPlayer
              isFullScreen={isFullScreen}
              setIsFullScreen={() => setIsFullScreen((screen) => !screen)}
              visible={isController}
              url={src}
              isLive={isLive}
            />
          ) : (
            <YoutubeWrapper
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              visible={isController}
              thumb={thumb}
              hd={hdSrc}
              sd={src}
              isLive={isLive}
              poster={poster}
            />
          )}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

CustomPlayer.layout = "empty";

export default CustomPlayer;
