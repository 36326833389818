import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import AppMetaHeader from "../../component/AppMetaHeader";
import PageContainer from "../../component/Layout/PageContainer";
import { useApi, useMount } from "../../hooks";
import AllCourse from "./AllCourse";
import RecentlyAdded from "./RecentlyAdded";
import ExpiringCourse from "./ExpiringCourse";
import MyOngoing from "./MyOngoing";

interface IMyCourse {
  id: number;
  slug: string;
  title: string;
  image: {
    small: string;
    medium: string;
  };
  is_recent: boolean;
  is_expire_soon: boolean;
}
function CourseListing() {
  const [activeKey, setActiveKey] = useState("1");
  const [isClicked, setIsClicked] = useState(false);
  const { data, isLoading, load } = useApi<IMyCourse[]>("user/v2/courses");
  const [
    allCourse,
    recentlyAdded,
    expiringCourse,
    ongoingCourse,
  ]: IMyCourse[][][][] = [[], [], [], []];

  useMount(() => {
    load();
  });

  useEffect(() => {
    document.title = "Courses | Khan Global Studies";
  }, []);

  const onChange = (key: string) => {
    setActiveKey(key);
    setIsClicked(true);
  };

  data?.forEach((datum) => {
    allCourse.push(datum);
    datum.is_recent && recentlyAdded.push(datum);
    datum.is_expire_soon && expiringCourse.push(datum);
    if (!datum.is_recent && !datum.is_expire_soon) {
      ongoingCourse.push(datum);
    }
  });

  const items = [
    {
      key: "1",
      label: (
        <span className={activeKey === "1" ? (isClicked ? "tabs_1" : "") : ""}>
          All
        </span>
      ),
      children: <AllCourse loading={isLoading} data={allCourse} />,
    },
    {
      key: "2",
      label: (
        <span className={activeKey === "2" ? (isClicked ? "tabs_1" : "") : ""}>
          My Ongoing
        </span>
      ),
      children: <MyOngoing loading={isLoading} data={ongoingCourse} />,
    },
    {
      key: "3",
      label: (
        <span
          className={
            activeKey === "3" ? (!isClicked ? "tabs_1" : "tabs_1") : ""
          }
        >
          Recently Added
        </span>
      ),
      children: <RecentlyAdded loading={isLoading} data={recentlyAdded} />,
    },

    {
      key: "4",
      label: (
        <span className={activeKey === "4" ? (isClicked ? "tabs_1" : "") : ""}>
          Expiring Soon
        </span>
      ),
      children: <ExpiringCourse data={expiringCourse} />,
    },
  ];

  // const getCurrentPageTitle = () => {
  //   const activeItem = items.find((item) => item.key === activeKey);
  //   return `${activeItem ? activeItem.label.props.children : ""}`;
  // };

  return (
    <>
      <AppMetaHeader currentPageTitle="My Courses" />
      <PageContainer
        currentPageTitle={"All Courses"}
        // title={"Courses"}
        // // loading={isLoading}
        // url="/Courses"
        transparent={true}
      >
        <Tabs
          tabBarStyle={{
            width: "100%",
            paddingBottom: "0.5%",
            position: "fixed",
            zIndex: "1",
            backgroundColor: "#EDF4FF",
          }}
          defaultActiveKey="1"
          items={items}
          activeKey={activeKey}
          onChange={onChange}
          size="small"
        />
      </PageContainer>
    </>
  );
}

export default CourseListing;
