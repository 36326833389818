export default function PyqIcon() {
  return (
    <svg
      width="18"
      height="25"
      viewBox="0 0 18 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.234106 7.34793e-05C0.102049 0.0254247 -0.00232296 0.153737 3.93196e-05 0.288156V24.4871C6.81279e-05 24.6379 0.137282 24.7751 0.288122 24.7751H17.5728C17.7237 24.7751 17.8609 24.6379 17.8609 24.4871V6.0498C17.8638 5.97372 17.8327 5.89743 17.7799 5.84273L12.0183 0.0810791C11.9636 0.0281583 11.8873 -0.00168382 11.8113 7.34793e-05H0.234106ZM0.576204 0.576238H11.5232V6.0498C11.5232 6.20064 11.6604 6.33786 11.8113 6.33789H17.2848V24.199H0.576204V0.576238ZM12.0994 0.981336L16.8797 5.76172H12.0994V0.981336ZM8.93048 10.9472C7.72462 10.9472 6.60119 11.5341 6.34675 12.6037C6.31034 12.7645 6.39051 12.9332 6.55383 12.9728C6.70815 13.0102 6.88043 12.8956 6.90494 12.7387C7.0801 12.0022 7.91006 11.5234 8.93048 11.5234C10.0687 11.5234 10.947 12.3024 10.947 13.2159C10.947 14.5727 10.459 14.9826 9.88475 15.5115C9.31052 16.0404 8.6424 16.6931 8.6424 18.1492C8.63951 18.3014 8.77825 18.4373 8.93048 18.4373C9.0827 18.4373 9.22072 18.3014 9.21856 18.1492C9.21856 16.854 9.70274 16.467 10.2809 15.9346C10.859 15.4022 11.5232 14.727 11.5232 13.2159C11.5232 11.9404 10.3366 10.9472 8.93048 10.9472ZM8.95756 19.0135C8.63934 19.0135 8.38139 19.2714 8.38139 19.5897C8.38139 19.9079 8.63934 20.1658 8.95756 20.1658C9.27574 20.1658 9.53372 19.9079 9.53372 19.5897C9.53372 19.2714 9.27574 19.0135 8.95756 19.0135Z"
        fill="#03045E"
      />
    </svg>
  );
}
