import React from "react";
import { Col, Row, Space, Tag } from "antd";
import { getCourseCatType } from "../helpers/help";
import styled from "styled-components";

const ItemCard = ({ order }) => {
  if (!order) return <></>;
  const category = getCourseCatType(order?.item?.category_id);
  const lang = order.item?.lang ? order.item?.lang || 2 : "NA";

  return (
    <Row gutter={[24, 24]} style={{ marginBottom: "5%", marginTop: "3%" }}>
      <Col xl={8} lg={8} md={24} sm={24} xs={24}>
        <img
          src={order.item?.image?.large || "/kgs.jpg"}
          width="100%"
          alt="course"
          style={{ borderRadius: "0.5em" }}
        />
      </Col>
      <Col xl={16} lg={16} md={24} sm={24} xs={24}>
        <CourseTitle>{order?.item.title || order?.item.name}</CourseTitle>
        <CourseSubTitle>
          {order?.item?.validity &&
            `Validity - ${order?.item?.validity} Month, `}
          {order?.item?.duration && `Duration - ${order?.item?.duration} Month`}
        </CourseSubTitle>
        <Space size={[0, 8]} wrap>
          {category === "NA" ? "" : <Title>{category}</Title>}
          {lang === "NA" ? (
            ""
          ) : (
            <Title>
              {lang === 0 && "Hindi"}
              {lang === 1 && "English"}
              {lang === 2 && "Hindi/English"}
            </Title>
          )}

          <Title>
            {"₹"} {order?.amount}/-
          </Title>
        </Space>
      </Col>
    </Row>
  );
};

const CourseTitle = styled.p`
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: 0%;
`;

const CourseSubTitle = styled.p`
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 1.6%;
  color: #606060;
`;

const Title = styled(Tag)`
  font-size: 0.9em;
`;

export default ItemCard;
