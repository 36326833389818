import React from "react";
import { Card, Avatar } from "antd";
import AppMetaHeader from "../component/AppMetaHeader";
import PageContainer from "../component/Layout/PageContainer";
import { Link } from "@tanstack/react-router";
import Meta from "antd/es/card/Meta";
import { newDateParse } from "../helpers/help";
import styled from "styled-components";
import { useNotificationStore } from "../store/notificatonStore";

const Notification = () => {
  const { notification, setMarkAsRead, readNotification } =
    useNotificationStore();

  return (
    <>
      <AppMetaHeader currentPageTitle="Notifications" />
      <PageContainer currentPageTitle="Notifications" transparent={true}>
        <Card
          size="default"
          style={{
            height: "83vh",
            overflow: "scroll",
            border: "1.2em solid white",
          }}
          className="hideScrollbar"
        >
          {notification?.length
            ? notification.map((item) => {
                return (
                  <Link to={item?.redirect_at}>
                    <Card
                      style={{
                        borderRadius: "1em",
                        backgroundColor: readNotification.includes(item?.id)
                          ? "#FFF"
                          : "#EBF2FD",
                        marginBottom: "3%",
                        border: readNotification.includes(item?.id)
                          ? "2px solid #EBF2FD"
                          : "",
                      }}
                      onClick={() => {
                        setMarkAsRead(item?.id);
                      }}
                    >
                      <Meta
                        avatar={<Avatar src={item?.img_url} size={"large"} />}
                        title={item.title}
                        description={<p>{item?.body}</p>}
                      />
                      <DateTitle>
                        <span>{newDateParse(item?.published_at)} </span>
                        <span>
                          {readNotification.includes(item?.id) ? (
                            <svg
                              width="15"
                              height="9"
                              viewBox="0 0 15 9"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.5253 0.029879L6.62891 2.92627L7.81194 4.1093L10.7083 1.21291L9.5253 0.029879Z"
                                fill="#2E3192"
                              />
                              <path
                                d="M3.92308 5.6538L1.21154 2.94226L0 4.1538L2.71154 6.86534L3.92308 8.07688L5.42308 6.51918L4.26923 5.30765L3.92308 5.6538Z"
                                fill="#2E3192"
                              />
                              <path
                                d="M13.7883 0L8.19212 5.65385L5.42289 2.94231L4.26904 4.15385L6.98058 6.86538L8.19212 8.07692L14.9998 1.21154L13.7883 0Z"
                                fill="#2E3192"
                              />
                            </svg>
                          ) : (
                            ""
                          )}
                        </span>
                      </DateTitle>
                    </Card>
                  </Link>
                );
              })
            : null}
        </Card>
      </PageContainer>
    </>
  );
};

const DateTitle = styled.p`
  float: right;
  font-size: 0.8em;
`;

export default Notification;
