import React from "react";
import parse from "html-react-parser";
import styled from "styled-components";

const DoubtsReply = ({ query, reply }) => {
  return (
    <div>
      <Heading>Response</Heading>
      <p>
        <b>My Query:</b> {query}
      </p>
      <p>
        <b>Reply:</b>
        {parse(String(reply))}
      </p>
    </div>
  );
};

const Heading = styled.h2`
  font-weight: 700;
`;

export default DoubtsReply;
