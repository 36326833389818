import React, { useState } from "react";
import Hls from "hls.js";
import { useRef } from "react";
import { HlsControls } from "../../utils/controls";
import { useMount } from "../../hooks";
import { IQuality, IRATE } from "../../interfaces/player";
import ControlBar from "./controlBar";
import { useMediaQuery } from "react-responsive";

export default function HlsPlayer({
  visible,
  url = "",
  isLive,
  isFullScreen,
  setIsFullScreen,
}) {
  let vidRef = useRef<HTMLVideoElement>(null);
  const [hls, setHls] = useState<Hls>();
  const [loading, setLoading] = useState(false);
  const [controls, setControls] = useState<HlsControls | undefined>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackRate, setPlaybackRate] = useState<IRATE>({
    name: "1x",
    value: 1,
  });
  const [quality, setQuality] = useState<IQuality>("Auto");
  const [volume, setVolume] = useState(75);

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  useMount(() => {
    if (!controls) return;
    controls.Volume = volume / 100;
  }, [volume]);

  useMount(() => {
    if (!controls) return;
    controls.rate = playbackRate.value;
  }, [playbackRate]);

  useMount(() => {
    if (!controls) return;
    // controls.rate = playbackRate.value
  }, [quality]);

  const togglePlay = () => {
    setIsPlaying((playing) => {
      controls?.[playing ? "pause" : "play"]();
      return !playing;
    });
    // if (isPlaying) {

    //   return controls?.pause();
    // }
    // controls?.play();
    // setIsPlaying(true);
  };

  useMount(() => {
    const video = vidRef.current;
    if (!video) return;
    const hls = new Hls();
    setHls(hls);
    setControls(new HlsControls(video, hls, isLive));
    hls.loadSource(url);
    hls.attachMedia(video);
    hls.on(Hls.Events.MANIFEST_PARSED, function () {
      video.play();
    });
    video.addEventListener("playing", () => setLoading(false));
    video.addEventListener("waiting", () => setLoading(true));
  }, [vidRef]);

  // const progressHandler = (progress) => {
  //   const timeline = document.getElementById("timeline");
  //   timeline?.style?.setProperty("--preview-progress", progress.loaded);
  //   timeline?.style?.setProperty("--progress-position", progress.played);
  // };

  React.useEffect(() => {
    if (hls) {
      hls.loadSource(url);
    }
  }, [url]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: isMobile
          ? isFullScreen
            ? "100vh"
            : "45vh"
          : isFullScreen
          ? "100vh"
          : "80vh",
        position: "relative",
        overflow: "hidden",
      }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <video
        width={"100%"}
        height={"100%"}
        ref={vidRef}
        autoPlay={true}
        style={{ objectFit: "contain" }}
        onPause={() => setIsPlaying(false)}
        onPlay={() => {
          setIsPlaying(true);
        }}
        // poster={poster}
      />
      {controls && (
        <ControlBar
          type="hls"
          isLoading={loading}
          controls={controls}
          isPlaying={isPlaying}
          isVisible={controls && visible && url}
          onPlay={togglePlay}
          isFullScreen={isFullScreen}
          setFullScreen={setIsFullScreen}
          volume={volume}
          setVolume={setVolume}
          playbackRate={playbackRate}
          setPlaybackRate={setPlaybackRate}
          quality={quality}
          setQuality={setQuality}
          url={url}
          // setVisible={setVisible}
          // visible={visible}
          // hide={controls && visible && url}

          // playing={isPlaying}
          // rate={rate}
          // togglePlay={togglePlay}
          // onRateChange={(rate) => setRate(rate)}
          // triggerFullScreen={() => setIsFullScreen(!isFullScreen)}
          // isFullScreen={isFullScreen}
          // zoom={zoom}
          // toggleZoom={() => setZoom(!zoom)}
        />
      )}
    </div>
  );
}
