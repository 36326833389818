export default function CloudIcon() {
  return (
    <svg
      width="272"
      height="268"
      viewBox="0 0 272 268"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="136" cy="123.329" r="94.0161" fill="#EAF9FF" />
      <circle cx="136" cy="123.329" r="73.4839" fill="#C7DDF1" />
      <path
        d="M133.247 216.015C124.615 224.837 112.576 230.313 99.2581 230.313C83.3616 230.313 69.2872 222.512 60.6539 210.529C56.3923 222.078 45.2868 230.313 32.2581 230.313C15.547 230.313 2 216.766 2 200.055C2 183.343 15.547 169.796 32.2581 169.796C39.8195 169.796 46.7331 172.57 52.037 177.155C54.8123 153.538 74.8954 135.216 99.2581 135.216C115.673 135.216 130.145 143.534 138.689 156.184C145.803 150.501 154.823 147.103 164.637 147.103C182.745 147.103 198.151 158.671 203.868 174.82C208.226 172.312 213.28 170.877 218.669 170.877C234.491 170.877 247.425 183.241 248.336 198.834C250.04 198.225 251.877 197.893 253.79 197.893C262.743 197.893 270 205.151 270 214.103C270 223.055 262.743 230.313 253.79 230.313C247.615 230.313 242.247 226.86 239.51 221.78C234.147 227.057 226.788 230.313 218.669 230.313C208.654 230.313 199.796 225.358 194.412 217.767C186.857 225.507 176.308 230.313 164.637 230.313C152.109 230.313 140.874 224.775 133.247 216.015Z"
        fill="white"
      />
      <path
        d="M2 200.055C2 183.343 15.547 169.796 32.2581 169.796C39.8195 169.796 46.7331 172.57 52.037 177.155C54.8123 153.538 74.8954 135.216 99.2581 135.216C115.673 135.216 130.145 143.534 138.689 156.184C145.803 150.501 154.823 147.103 164.637 147.103C182.745 147.103 198.151 158.671 203.868 174.82C208.226 172.312 213.28 170.877 218.669 170.877C234.491 170.877 247.425 183.241 248.336 198.834C250.04 198.225 251.877 197.893 253.79 197.893C262.743 197.893 270 205.151 270 214.103"
        stroke="#184680"
        stroke-width="2.06618"
        stroke-linecap="round"
      />
      <path
        d="M183.723 213.388C184.145 213.81 184.829 213.81 185.251 213.388L190.796 207.843C190.816 207.824 190.845 207.773 190.879 207.708C191.042 207.394 191.113 207.035 191.113 206.681L191.113 76.8609L81.9677 76.8609L81.9677 206.14L81.9677 206.681C81.9677 207.221 82.2177 207.774 82.2861 207.843L87.8317 213.388C88.2537 213.81 88.9379 213.81 89.3599 213.388L94.589 208.159L99.818 213.388C100.24 213.81 100.924 213.81 101.346 213.388L106.575 208.159L111.804 213.388C112.226 213.81 112.911 213.81 113.333 213.388L118.562 208.159L123.791 213.388C124.213 213.81 124.897 213.81 125.319 213.388L130.548 208.159L135.777 213.388C136.199 213.81 136.883 213.81 137.305 213.388L142.534 208.159L147.763 213.388C148.185 213.81 148.87 213.81 149.292 213.388L154.521 208.159L159.75 213.388C160.172 213.81 160.856 213.81 161.278 213.388L166.507 208.159L171.736 213.388C172.158 213.81 172.842 213.81 173.264 213.388L178.494 208.159L183.723 213.388Z"
        fill="#E3E7F7"
        stroke="#0C2B42"
        stroke-width="1.54963"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M121.952 139.538C124.339 139.538 126.274 137.603 126.274 135.216C126.274 132.828 124.339 130.893 121.952 130.893C119.564 130.893 117.629 132.828 117.629 135.216C117.629 137.603 119.564 139.538 121.952 139.538ZM151.129 139.538C153.516 139.538 155.452 137.603 155.452 135.216C155.452 132.828 153.516 130.893 151.129 130.893C148.742 130.893 146.806 132.828 146.806 135.216C146.806 137.603 148.742 139.538 151.129 139.538Z"
        fill="#0C2B42"
      />
      <path
        d="M154.371 162.982C150.476 157.308 143.943 153.587 136.54 153.587C129.138 153.587 122.605 157.308 118.71 162.982"
        stroke="#0C2B42"
        stroke-width="2.58272"
        stroke-linecap="round"
      />
      <circle
        cx="101.419"
        cy="60.6511"
        r="3.24194"
        stroke="#0C2B42"
        stroke-width="1.54963"
      />
      <circle
        cx="222.452"
        cy="162.232"
        r="2.16129"
        stroke="#0C2B42"
        stroke-width="1.54963"
      />
      <path
        d="M101.419 96.3125H150.048M101.419 107.659H130.597"
        stroke="#ABC0DC"
        stroke-width="1.54963"
        stroke-linecap="round"
      />
      <path
        d="M92.7742 194.651H180.306M92.7742 183.845H145.294"
        stroke="#ABC0DC"
        stroke-width="1.54963"
        stroke-linecap="round"
      />
      <circle
        cx="53.3307"
        cy="145.482"
        r="4.8629"
        stroke="#0C2B42"
        stroke-width="1.54963"
      />
      <path
        d="M199.795 77.9415H210.565M192.194 68.6186V59.5706"
        stroke="#6E7191"
        stroke-width="1.54963"
        stroke-linecap="round"
      />
      <path
        d="M199.162 70.5346L206.325 63.3713"
        stroke="#6E7191"
        stroke-width="1.54963"
        stroke-linecap="round"
      />
      <ellipse
        cx="136.54"
        cy="235.175"
        rx="54.5726"
        ry="4.8629"
        fill="#ABC0DC"
      />
    </svg>
  );
}
