export default function ScoreIcon2() {
  return (
    <svg
      width="27"
      height="32"
      viewBox="0 0 27 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7215 5.71429H16.875V0.790857C17.1101 0.952 17.3329 1.13371 17.5343 1.33943L21.1815 5.04457C21.384 5.24914 21.5618 5.47543 21.7215 5.71429ZM6.75 22.8571C6.75 16.5554 11.7968 11.4286 18 11.4286C19.5998 11.4286 21.1208 11.7749 22.5 12.3897V8.27657C22.5 8.18286 22.4786 8.09257 22.473 8H15.75C15.1279 8 14.625 7.48914 14.625 6.85714V0.0274285C14.5339 0.0217142 14.4439 0 14.3527 0H3.375C1.51425 0 0 1.53829 0 3.42857V26.2857C0 28.176 1.51425 29.7143 3.375 29.7143H9.02137C7.60162 27.8011 6.75 25.4309 6.75 22.8571ZM17.631 22.8571H18.5636L18.0979 21.6731L17.631 22.8571ZM27 22.8571C27 27.8983 22.9624 32 18 32C13.0376 32 9 27.8983 9 22.8571C9 17.816 13.0376 13.7143 18 13.7143C22.9624 13.7143 27 17.816 27 22.8571ZM22.0028 25.4366L19.1419 18.1726C18.9709 17.7383 18.5569 17.4537 18.0968 17.4537C17.6366 17.4537 17.2237 17.7383 17.0516 18.1726L14.1908 25.4377C13.9601 26.024 14.2414 26.6891 14.8185 26.9234C14.9546 26.9794 15.0964 27.0046 15.2359 27.0046C15.6814 27.0046 16.1044 26.7337 16.281 26.2857L16.731 25.1429H19.4636L19.9136 26.2846C20.1443 26.872 20.8046 27.1566 21.3761 26.9223C21.9532 26.688 22.2334 26.0229 22.0028 25.4366Z"
        fill="#253D86"
      />
    </svg>
  );
}
