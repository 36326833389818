import React from "react";
import { Col, Row, Select } from "antd";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { TYPE } from "../../interfaces/player.d";
import BackButton from "../BackButton";

export default function PlayerHeader({
  videoType,
  lesson,
  setLesson,
  setVideoType,
  handleToggle,
  course,
  open,
  lId,
}) {
  const filterOption = (input, option) => {
    return option.name.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <Row
      style={{
        backgroundColor: "white",
        width: "100%",
        boxShadow: "0px 0.3em 0.3em 0px #00000040",
        padding: "0.7em 1.4em",
      }}
      align={"middle"}
    >
      <Col xxl={1} xl={1} md={1} sm={1} xs={0}>
        <BackButton />
      </Col>
      <Col xxl={14} xl={13} md={13} sm={0} xs={0}>
        <h2 style={{ marginBottom: "0%" }}>{course?.course?.title}</h2>
        <h4 style={{ color: "#767676", marginBottom: "0%" }}>{lesson?.name}</h4>
      </Col>
      <Col
        xxl={6}
        xl={6}
        md={10}
        sm={23}
        xs={24}
        className="courseHeader"
        id="color987"
      >
        {lId && (
          <Select
            className="select-box"
            style={{
              // background: "#F2F1FF",
              // border: "1px solid #2E3192",
              // color: "#2E3192",
              // borderRadius: "1em",
              // fontWeight: "bold",
              width: "100%",
            }}
            showSearch
            filterOption={filterOption}
            options={course?.lessons}
            fieldNames={{ label: "name", value: "id" }}
            onSelect={(value, activeLesson) => {
              setLesson(activeLesson);
            }}
            defaultValue={lId}
            allowClear
            // id="color987"
          />
        )}
      </Col>
      <Col xxl={2} xl={3} md={23} sm={23} xs={23} className="courseHeader">
        <div className="switch">
          <input
            id="language-toggle"
            className="check-toggle check-toggle-round-flat"
            type="checkbox"
            name="language"
            value={videoType}
            onChange={(e) => {
              setVideoType(e.target.checked ? TYPE.pdf : TYPE.video);
            }}
          />
          <label htmlFor="language-toggle"></label>
          <span className="off">Notes</span>
          <span className="on">Videos</span>
        </div>
      </Col>
      <Col xxl={1} xl={1} md={1} sm={1} xs={1} className="courseHeader">
        <div
          style={{
            cursor: "pointer",
            marginRight: "0.625rem",
            float: "right",
          }}
          onClick={handleToggle}
        >
          {open ? (
            <CloseOutlined style={{ color: "#353297", fontSize: "1.5em" }} />
          ) : (
            <MenuOutlined style={{ color: "#353297", fontSize: "1.5em" }} />
          )}
        </div>
      </Col>
    </Row>
  );
}
