import React from "react";
import { Col, Row } from "antd";
import { Link, useNavigate } from "@tanstack/react-router";
import CustomCard from "../../component/GlobalCard";
import { TitleContainer, SubTitleContainer } from "../../component/GlobalCard";
import Shimmer from "../../component/Shimmer";
import { useMediaQuery } from "react-responsive";

const AnsWritingCategory = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate({ from: "/" });
  React.useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const answerWriting = [
    {
      batch_id: 1,
      title: "UPSC Answer Writing",
      slug: "upsc-mains-answer-writing",
      thumb: "images/india.svg",
    },
    {
      batch_id: 2,
      title: "BPSC Answer Writing",
      slug: "bpsc-mains-answer-writing",
      thumb: "images/india.svg",
    },
    {
      batch_id: 3,
      title: "UP-PSC Answer Writing",
      slug: "uppsc-mains-answer-writing",
      thumb: "images/india.svg",
    },
    {
      batch_id: 4,
      title: "MP-PSC Answer Writing",
      slug: "mppsc-mains-answer-writing",
      other_img: "images/circle.svg",
    },
  ];

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 426px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(max-width: 1025px)",
  });

  const isExtraLageScreen = useMediaQuery({
    query: "(max-width: 1441px)",
  });

  return (
    <div
      style={{
        marginTop: isSmallScreen
          ? "4em"
          : isMediumScreen
          ? "4em"
          : isLargeScreen
          ? "4em"
          : isExtraLageScreen
          ? "4em"
          : "4em",
      }}
    >
      <Shimmer type="test-series" loading={isLoading} count={4}>
        <Row gutter={[15, 15]}>
          {answerWriting.map((elem, index: any) => {
            return (
              <>
                {
                  <Col
                    xxl={6}
                    xl={6}
                    lg={8}
                    md={12}
                    sm={12}
                    xs={24}
                    key={elem?.batch_id}
                  >
                    <Link
                      to={`/answer-writing/${elem.slug}?batch=${elem.batch_id}`}
                    >
                      <CustomCard
                        title={<TitleContainer>{elem.title}</TitleContainer>}
                        type="avatar"
                        desc={
                          <SubTitleContainer>
                            Mains Answer Writing
                          </SubTitleContainer>
                        }
                        avatar={{
                          url: elem.thumb || (elem.other_img as string),
                          height: "100%",
                          width: "90%",
                          className: "img-tag",
                        }}
                        className={"card-active"}
                      />
                    </Link>
                  </Col>
                }
              </>
            );
          })}
        </Row>
      </Shimmer>
    </div>
  );
};

export default AnsWritingCategory;
