import React from "react";
import type { MenuProps } from "antd";
import { Badge, Card, Col, Menu, Row } from "antd";
import { Link } from "@tanstack/react-router";
import {
  CourseIcon,
  DashboardIcon,
  DoubtIcon,
  LiveIcon,
  OrderIcon,
  QuizIcon,
  RecommendIcon,
  Submission,
} from "../icons/sideBar";
import ContactUs from "../../pages/ContactUs";
import { liveClassesStore } from "../../store/liveClassesStore";
import StudyMaterials from "../icons/StudyMaterials";
import { usePublicStore } from "../../store/usePublicStore";
import PracticeIcon from "../icons/sideBar/PracticeIcon";
import CallingIcon from "../icons/CallingIcon";
import CallingIcon2 from "../icons/CallingIcon2";

type MenuItem = Required<MenuProps>["items"][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  selectedIcon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    selectedIcon,
    children,
    label,
  } as MenuItem;
}

export default function SidebarMenu({ collapsed }) {
  const { liveStore } = liveClassesStore((state: any) => state);
  const root = window?.location?.pathname.split("/")[1];

  const { active } = usePublicStore();

  const resources = active?.config?.resources;

  const filterItems = resources?.filter((item) => item.includes("AW"));

  const items: MenuItem[] = [
    getItem(
      <Link to="/" from="/">
        Dashboard
      </Link>,
      "home",
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <DashboardIcon />
      </svg>
    ),

    getItem(
      <Link to="/live" from="/">
        Live Classes
        <Badge
          className="badge"
          style={{ marginLeft: "7px", boxShadow: "none", fontWeight: "bold" }}
          count={liveStore?.length}
        />
      </Link>,
      "live",
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <LiveIcon />
      </svg>
    ),

    getItem(
      <Link to="/courses" from="/">
        My Courses
      </Link>,
      "courses",
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <CourseIcon />
      </svg>
    ),

    getItem(
      <Link to="/quizzes" from="/">
        My Test Series
      </Link>,
      "quizzes",
      <svg
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <QuizIcon />
      </svg>
    ),

    filterItems?.length > 0
      ? getItem(
          <Link to="/submission" from="/">
            Answer Writing
          </Link>,
          "submission",
          <svg
            width="17"
            height="21"
            viewBox="0 0 17 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <Submission />
          </svg>
        )
      : filterItems === undefined
      ? getItem(
          <Link to="/submission" from="/">
            Answer Writing
          </Link>,
          "submission",
          <svg
            width="17"
            height="21"
            viewBox="0 0 17 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <Submission />
          </svg>
        )
      : undefined,

    getItem(
      <Link to="/recommended-courses" from="/">
        Courses
      </Link>,
      "recommended-courses",
      <svg
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <RecommendIcon />
      </svg>
    ),

    getItem(
      <Link to="/test-series" from="/">
        Test Series
      </Link>,
      "test-series",
      <svg
        width="16"
        height="22"
        viewBox="0 0 16 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.2561 22C13.3052 22 13.3538 21.9903 13.3992 21.9716C13.4446 21.9528 13.4858 21.9253 13.5205 21.8906C13.5552 21.8558 13.5827 21.8147 13.6015 21.7693C13.6203 21.724 13.63 21.6754 13.63 21.6263V19.3962H15.6262C15.6753 19.3962 15.7239 19.3865 15.7692 19.3677C15.8146 19.3489 15.8558 19.3214 15.8905 19.2867C15.9252 19.252 15.9528 19.2108 15.9716 19.1655C15.9903 19.1201 16 19.0715 16 19.0225V2.67259C16 2.61671 15.9874 2.56154 15.9633 2.51114C15.9391 2.46074 15.904 2.4164 15.8604 2.38136L13.0015 0.0824791C12.9351 0.0290542 12.8524 -5.23507e-05 12.7672 7.06852e-08H2.74387C2.69477 -3.46324e-06 2.64616 0.00966054 2.6008 0.0284399C2.55545 0.0472192 2.51423 0.0747462 2.47952 0.109449C2.4448 0.144152 2.41727 0.18535 2.39848 0.230692C2.3797 0.276034 2.37003 0.324631 2.37003 0.373708V2.60383H0.373832C0.324738 2.60383 0.276125 2.61349 0.230768 2.63227C0.185411 2.65105 0.144199 2.67858 0.109485 2.71328C0.0747709 2.74798 0.0472348 2.78918 0.0284492 2.83452C0.00966368 2.87987 -3.53413e-06 2.92846 9.69183e-10 2.97754V21.6263C-3.53413e-06 21.6754 0.00966368 21.724 0.0284492 21.7693C0.0472348 21.8147 0.0747709 21.8558 0.109485 21.8906C0.144199 21.9253 0.185411 21.9528 0.230768 21.9716C0.276125 21.9903 0.324738 22 0.373832 22H13.2561ZM3.1177 0.747413H12.6355L15.2523 2.85156V18.6488H13.63V5.27643C13.6299 5.22055 13.6174 5.16538 13.5932 5.11498C13.5691 5.06458 13.5339 5.02023 13.4904 4.9852L10.6314 2.68632C10.5651 2.63289 10.4824 2.60378 10.3972 2.60384H3.1177V0.747413ZM0.747664 3.35125H10.2655L12.8823 5.4554V21.2526H0.747664V3.35125Z"
          fill="white"
        />
        <path
          d="M4.43349 9.07482C4.84157 9.07481 5.24049 8.95382 5.57979 8.72717C5.91909 8.50051 6.18354 8.17837 6.33969 7.80147C6.49584 7.42457 6.53669 7.00984 6.45706 6.60974C6.37744 6.20963 6.18091 5.84211 5.89234 5.55366C5.60378 5.26521 5.23612 5.06878 4.83588 4.9892C4.43564 4.90963 4.02078 4.95049 3.64376 5.10662C3.26675 5.26275 2.94451 5.52713 2.71781 5.86633C2.4911 6.20554 2.3701 6.60433 2.37012 7.01228C2.37071 7.55914 2.5883 8.08343 2.97513 8.47011C3.36197 8.85678 3.88645 9.07426 4.43349 9.07482ZM4.43349 5.697C4.69369 5.69702 4.94805 5.77416 5.16439 5.91868C5.38074 6.0632 5.54936 6.26861 5.64893 6.50893C5.74849 6.74925 5.77454 7.01369 5.72378 7.26881C5.67301 7.52392 5.54771 7.75826 5.36372 7.94219C5.17973 8.12612 4.94531 8.25138 4.69011 8.30213C4.4349 8.35288 4.17038 8.32684 3.92998 8.22731C3.68958 8.12777 3.48411 7.95921 3.33954 7.74294C3.19497 7.52666 3.11779 7.27239 3.11778 7.01228C3.11812 6.66355 3.25684 6.3292 3.50351 6.08261C3.75018 5.83602 4.08465 5.69734 4.43349 5.697Z"
          fill="white"
        />
        <path
          d="M11.0913 11H5.08428C4.98513 11 4.89005 11.0394 4.81994 11.1095C4.74983 11.1795 4.71045 11.2746 4.71045 11.3737C4.71045 11.4728 4.74983 11.5679 4.81994 11.638C4.89005 11.708 4.98513 11.7474 5.08428 11.7474H11.0913C11.1904 11.7474 11.2855 11.708 11.3556 11.638C11.4257 11.5679 11.4651 11.4728 11.4651 11.3737C11.4651 11.2746 11.4257 11.1795 11.3556 11.1095C11.2855 11.0394 11.1904 11 11.0913 11Z"
          fill="white"
        />
        <path
          d="M11.0854 13.4881H2.54424C2.4451 13.4881 2.35001 13.5275 2.2799 13.5975C2.2098 13.6676 2.17041 13.7627 2.17041 13.8618C2.17041 13.9609 2.2098 14.056 2.2799 14.126C2.35001 14.1961 2.4451 14.2355 2.54424 14.2355H11.0854C11.1846 14.2355 11.2797 14.1961 11.3498 14.126C11.4199 14.056 11.4593 13.9609 11.4593 13.8618C11.4593 13.7627 11.4199 13.6676 11.3498 13.5975C11.2797 13.5275 11.1846 13.4881 11.0854 13.4881Z"
          fill="white"
        />
        <path
          d="M11.0854 16.0684H2.54424C2.4451 16.0684 2.35001 16.1078 2.2799 16.1779C2.2098 16.248 2.17041 16.343 2.17041 16.4421C2.17041 16.5412 2.2098 16.6363 2.2799 16.7064C2.35001 16.7765 2.4451 16.8158 2.54424 16.8158H11.0854C11.1846 16.8158 11.2797 16.7765 11.3498 16.7064C11.4199 16.6363 11.4593 16.5412 11.4593 16.4421C11.4593 16.343 11.4199 16.248 11.3498 16.1779C11.2797 16.1078 11.1846 16.0684 11.0854 16.0684Z"
          fill="white"
        />
        <path
          d="M11.0854 18.6488H2.54424C2.4451 18.6488 2.35001 18.6881 2.2799 18.7582C2.2098 18.8283 2.17041 18.9234 2.17041 19.0225C2.17041 19.1216 2.2098 19.2166 2.2799 19.2867C2.35001 19.3568 2.4451 19.3962 2.54424 19.3962H11.0854C11.1846 19.3962 11.2797 19.3568 11.3498 19.2867C11.4199 19.2166 11.4593 19.1216 11.4593 19.0225C11.4593 18.9234 11.4199 18.8283 11.3498 18.7582C11.2797 18.6881 11.1846 18.6488 11.0854 18.6488Z"
          fill="white"
        />
      </svg>
    ),

    getItem(
      <Link to="/study-materials" from="/">
        Study Materials
      </Link>,
      "study-materials",
      <svg
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <StudyMaterials />
      </svg>
    ),

    getItem(
      <Link to="/practice" from="/">
        Practice
      </Link>,
      "practice",
      <svg
        width="19"
        height="21"
        viewBox="0 0 19 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <PracticeIcon />
      </svg>
    ),
  ];

  const matched =
    items.find(
      (item) =>
        item?.key == root ||
        (root == "answer-writing" && item?.key == "submission")
    ) || items[0];

  return (
    <>
      <Menu
        theme="dark"
        mode="inline"
        items={items}
        selectedKeys={[String(matched?.key)]}
        style={{
          background: "#2E3192 ",
          color: "white",
        }}
      />
      {/* {collapsed ? (
        <Card style={{ width: "260px", margin: "auto", marginTop: "2%" }}>
          <Row align={"middle"}>
            <Col span={14}>
              <p style={{ margin: "0", fontWeight: "700" }}>
                Get Free academic Counseling & Course Details
              </p>
              <p
                style={{
                  background: "#FF8057",
                  color: "white",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  padding: "3%",
                  borderRadius: "1.2em",
                  fontWeight: "700",
                  fontSize: "0.9em",
                  margin: "0%",
                  marginTop: "10%",
                }}
              >
                <CallingIcon /> +91 8757354880
              </p>
            </Col>
            <Col span={10}>
              <CallingIcon2 />
            </Col>
          </Row>
        </Card>
      ) : (
        ""
      )} */}
    </>
  );
}
