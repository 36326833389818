import React, { useEffect, useState } from "react";
import PaginationComp from "../../utils/PaginationComp";
import api from "../../config/api";
import { Col, Drawer, Row } from "antd";
import PdfViewer from "../../utils/pdfViewer";
import DownloadCard from "./DownloadCard";
import CaIcon from "../../component/icons/CaIcon";
import Shimmer from "../../component/Shimmer";
import AppMetaHeader from "../../component/AppMetaHeader";
import PageContainer from "../../component/Layout/PageContainer";

interface IResource {
  url: string;
  name: string;
}

const CAListing = () => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [perPage, setPerPage] = useState();
  const [loading, setLoading] = useState(false);
  const [currentAffairsData, setCurrentAffairsData] = useState<any[]>();
  const [open, setOpen] = useState(false);
  const [resource, setResource] = useState<IResource>();

  useEffect(() => {
    const fetchcurrentAffairs = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(`/resources/1?page=${page}`);
        setTotal(data?.total);
        setPerPage(data?.per_page);
        setCurrentAffairsData(data?.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchcurrentAffairs();
  }, [page]);

  const showDrawer = (item) => {
    setOpen(true);
    setResource(item);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppMetaHeader currentPageTitle="Current Affairs MCQ PDF" />
      <PageContainer
        currentPageTitle="Current Affairs MCQ PDF"
        title="Study Materials"
        url="/study-materials"
      >
        <Shimmer type="downloads" loading={loading} count={4}>
          <Row gutter={[15, 15]} style={{ marginBottom: "2%" }}>
            {currentAffairsData?.map((item) => (
              <Col xxl={6} xl={6} lg={8} md={12} sm={12} xs={24} key={item?.id}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    showDrawer(item);
                  }}
                >
                  <DownloadCard title={item?.name} avatar={<CaIcon />} />
                </div>
              </Col>
            ))}
          </Row>
          <PaginationComp
            page={page}
            total={total}
            perPage={perPage}
            onChange={(page) => setPage(page)}
          />
        </Shimmer>

        <Drawer
          title={resource?.name}
          onClose={onClose}
          open={open}
          width="70%"
        >
          <PdfViewer pdfUrl={resource?.url} height={"700px"} />
        </Drawer>
      </PageContainer>
    </>
  );
};

export default CAListing;
