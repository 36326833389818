import React, { useEffect } from "react";
import Dashboard from "../component/dashboard";
import AppMetaHeader from "../component/AppMetaHeader";
import { Col, Row } from "antd";
import RecentActivity from "../component/dashboard/RecentActivity";
import { useAuthStore } from "../store/auth";
import { useSearch } from "@tanstack/react-router";

export default function Home() {
  const { user, token } = useAuthStore();
  const userName = user?.name?.split(" ")[0] || "";

  const { redirect } = useSearch({
    from: "",
  });

  const search = useSearch({ from: "" });
  const isAuth = search.isAuth;

  useEffect(() => {
    document.title =
      "Khan Global Studies Dashboard: Login and Register Here.!!";
  }, []);

  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.delete("redirect");
  urlSearchParams.delete("isAuth");
  const newSearchParams = urlSearchParams.toString();

  useEffect(() => {
    if (token && redirect && isAuth) {
      window.location.href = `${redirect}?auth_token=${token}&${newSearchParams}`;
    }
  }, [token, redirect]);

  return (
    <>
      <AppMetaHeader
        title={`Hello ${userName}, Welcome back!`}
        currentPageTitle="Your Dashboard Today"
      />
      <Row>
        <Col xl={19} lg={19} md={24} xs={24} sm={24}>
          <Dashboard />
        </Col>
        <Col lg={5} xl={5} md={0} sm={0} xs={0}>
          <RecentActivity />
        </Col>
      </Row>
    </>
  );
}
