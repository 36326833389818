import React from "react";
import Sider from "antd/es/layout/Sider";
import useMobile from "../../hooks/useMobile";
import QuizIndex from "./QuizIndex";
import { CloseCircleOutlined } from "@ant-design/icons";

export default function QuizDrawer({ collapsed, setCollapsed, onScroll }) {
  const isMobile = useMobile();

  return (
    <Sider
      width={!isMobile ? "25em" : "100%"}
      theme="light"
      collapsedWidth={0}
      reverseArrow
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      trigger={null}
    >
      <div>
        {isMobile && (
          <CloseCircleOutlined
            style={{ fontSize: "20px" }}
            onClick={() => setCollapsed(true)}
          />
        )}
      </div>
      <div>
        <QuizIndex handleScroll={onScroll} />
      </div>
    </Sider>
  );
}
