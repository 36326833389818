import React from "react";
import { Col, Dropdown, Menu, Tag } from "antd";
import { DownOutlined } from "@ant-design/icons";

const items = [
  {
    key: "1",
    value: "en",
    label: "English",
  },
  {
    key: "2",
    value: "hi",
    label: "Hindi",
  },
];

export enum LANG {
  Hi = "hi",
  En = "en",
}

const ChangeLanguage = ({ language, setLanguage }) => {
  const menu = (
    <Menu onClick={(e) => setLanguage(e.key)}>
      {items.map((item) => (
        <Menu.Item key={item.value}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Col
      sm={4}
      xs={4}
      style={{ display: "flex", justifyContent: "end" }}
    >
      <p style={{ fontWeight: "bold" }}>
        <span className="hideLanguageText">View in </span>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Tag onClick={(e) => e.preventDefault()}>
            {language === LANG.En ? "English" : "Hindi"}
            <DownOutlined style={{ marginLeft: "10px" }} />
          </Tag>
        </Dropdown>
      </p>
    </Col>
  );
};

export default ChangeLanguage;
