import React from "react";
export default function RecommendIcon() {
  return (
    <>
      <path
        d="M17 20H3.18182C2.60316 20 2.04821 19.769 1.63904 19.3579C1.22987 18.9467 1 18.3891 1 17.8077C1 17.2263 1.22987 16.6686 1.63904 16.2575C2.04821 15.8464 2.60316 15.6154 3.18182 15.6154H15.5455C15.9312 15.6154 16.3012 15.4614 16.574 15.1873C16.8468 14.9132 17 14.5415 17 14.1538V2.46154C17 2.07391 16.8468 1.70217 16.574 1.42807C16.3012 1.15398 15.9312 1 15.5455 1H3.18182C2.6131 0.999797 2.06679 1.22273 1.65925 1.62131C1.25171 2.01988 1.01517 2.5626 1 3.13385V17.7492"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5459 15.6155V20.0001"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  );
}
