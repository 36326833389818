export default function LectureIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5" y="5" width="22" height="22" rx="6" fill="#B4D6A4" />
      <path
        d="M10 17.5C11.7574 15.7426 13.5 14 14.5 13C15.5 12 16.5 15.5 17.5 15.5C18.5 15.5 21.5 11 21.5 11"
        stroke="#49982E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M23.4511 19.309C23.1517 18.3877 21.8483 18.3877 21.549 19.309L20.9019 21.3004H18.808C17.8393 21.3004 17.4365 22.54 18.2203 23.1094L19.9142 24.3402L19.2672 26.3316C18.9678 27.2529 20.0223 28.019 20.806 27.4496L22.5 26.2188L24.194 27.4496C24.9777 28.019 26.0322 27.2529 25.7328 26.3316L25.0858 24.3402L26.7798 23.1094C27.5635 22.54 27.1607 21.3004 26.192 21.3004L24.0981 21.3004L23.4511 19.309Z"
        fill="#49982E"
        stroke="white"
      />
    </svg>
  );
}
