export default function OrderIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 1V6.84616"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.6924 16.3462H16.3462"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 6.84619H20V18.5385C20 18.9261 19.846 19.2979 19.5719 19.572C19.2978 19.8461 18.9261 20 18.5385 20H2.46154C2.07391 20 1.70217 19.8461 1.42807 19.572C1.15398 19.2979 1 18.9261 1 18.5385V6.84619Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 6.84616L3.19231 2.62231C3.42865 2.14624 3.79032 1.74363 4.23842 1.45778C4.68651 1.17192 5.20406 1.01366 5.73538 1H15.2646C15.8084 1.00029 16.3414 1.15227 16.8036 1.43887C17.2657 1.72547 17.6388 2.1353 17.8808 2.62231L20 6.84616"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
