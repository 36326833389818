import React, { useState } from "react";
import { Button, Col, Row } from "antd";
import useQuizStore from "../../store/quizStore";
import styled from "styled-components";
import { IStatus } from "../../interfaces/quiz";

const QuizIndex = ({ handleScroll }) => {
  const { quiz, status } = useQuizStore((status) => status);
  const [questions, setQuestions] = useState(quiz?.questions);

  const [isQuizSubmit, setIsQuizSubmit] = useState(false);
  const handleQuestion = (position: number) => {
    handleScroll(position);
  };

  const handleModal = (val: string) => {
    setIsQuizSubmit(!isQuizSubmit);
  };

  const getColorClass = (status: IStatus) => {
    if (status.bookmark) return "question-bookmarked";
    if (status.attempt !== -1) return "question-attempted";
    if (status.visited) return "currentQuestion";
    return "question-default";
  };

  type TFilterType =
    | "attempted"
    | "notAnswered"
    | "bookmarked"
    | "notVisited"
    | "all";

  const handleFilter = (type: TFilterType) => {
    if (type === "bookmarked") {
      return setQuestions(
        quiz?.questions.filter((question) => status[question.id].bookmark)
      );
    }
    if (type === "attempted") {
      return setQuestions(
        quiz?.questions.filter((question) => status[question.id].attempt !== -1)
      );
    }
    if (type === "notAnswered") {
      return setQuestions(
        quiz?.questions.filter(
          (question) =>
            status[question.id].attempt === -1 && status[question.id].visited
        )
      );
    }
    if (type === "notVisited") {
      return setQuestions(
        quiz?.questions.filter((question) => !status[question.id].visited)
      );
    }
    setQuestions(quiz?.questions);
  };

  return (
    <div style={{ width: "100%", background: "#F0F0F0", height: "92vh" }}>
      <div
        style={{
          width: "100%",
          height: "10%",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        Select which Question number you want to attempt.
      </div>
      <div
        style={{
          width: "100%",
          height: "80%",
          overflowY: "scroll",
        }}
        // className="video-content-container"
      >
        <Row style={{ paddingBottom: "5%" }}>
          {questions?.map((item, index) => {
            const currentStatus: IStatus = status[item.id];
            const className = getColorClass(currentStatus);
            return (
              <Col xl={4} lg={6} md={6} sm={4} xs={6}>
                <IndexBtn
                  className={className}
                  onClick={() => handleQuestion(currentStatus.position)}
                >
                  {currentStatus.position}
                </IndexBtn>
              </Col>
            );
          })}
        </Row>
      </div>
      <div
        style={{
          width: "100%",
          height: "10%",
          background: "white",
          borderTop: "1px solid #00000040",
          position: "relative",
        }}
      >
        <Row
          align={"middle"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Button
            size={"small"}
            onClick={() => handleFilter("all")}
            style={{
              margin: "3px 2px",
              color: "#2e3192",
              border: "1px solid #2e3192",
            }}
          >
            <b>All</b>
          </Button>
          <Button
            size={"small"}
            onClick={() => handleFilter("bookmarked")}
            style={{
              margin: "3px 2px",
              color: "#2e3192",
              border: "1px solid #2e3192",
            }}
            className="question-bookmarked"
          >
            <b>Bookmarked</b>{" "}
          </Button>
          <Button
            onClick={() => handleFilter("attempted")}
            size={"small"}
            style={{
              color: "#2e3192",
              border: "1px solid #2e3192",
              margin: "3px 2px",
            }}
            className="question-attempted"
          >
            <b>Attempted</b>
          </Button>
          <Button
            size={"small"}
            onClick={() => handleFilter("notVisited")}
            style={{
              margin: "3px 2px",
              color: "#2e3192",
              border: "1px solid #2e3192",
            }}
            className="question-default"
          >
            <b>Not-Visited</b>
          </Button>
          <Button
            onClick={() => handleFilter("notAnswered")}
            size={"small"}
            style={{
              margin: "3px 2px",
              color: "#2e3192",
              border: "1px solid #2e3192",
            }}
            className="currentQuestion"
          >
            <b>Not Answered</b>
          </Button>
        </Row>
      </div>
    </div>
  );
};
export default QuizIndex;

const IndexBtn = styled(Button)`
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 2px;
    box-shadow: 0px 1px 2px gray;
    margin-left: 0.8rem;
    margin-top: 0.8rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: '#2e3192';
    font-size: 1.4
    font-weight: 800
`;
