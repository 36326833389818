import React from "react";
import { Carousel } from "antd";
import Shimmer from "./Shimmer";
import { useMediaQuery } from "react-responsive";
import { usePublicStore } from "../store/usePublicStore";

const Banner = () => {
  const { publicData, isLoading, banner } = usePublicStore();

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 426px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(max-width: 1025px)",
  });

  const isExtraLageScreen = useMediaQuery({
    query: "(max-width: 1441px)",
  });

  return (
    <>
      <Shimmer type="banner" loading={isLoading} count={1}>
        <Carousel autoplay>
          {publicData?.banners?.map((item) => (
            <a
              href={item?.redirect_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={item.url}
                width="100%"
                height={
                  isSmallScreen
                    ? "130vh"
                    : isMediumScreen
                    ? "180vh"
                    : isLargeScreen
                    ? "230vh"
                    : isExtraLageScreen
                    ? "270vh"
                    : "300vh"
                }
                style={{ borderRadius: "1em" }}
              />
            </a>
          ))}
        </Carousel>
      </Shimmer>
    </>
  );
};

export default Banner;
