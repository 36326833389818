import { create } from "zustand";
import { persist } from 'zustand/middleware';

interface IRecommendCoursesStore {
    rcIsLoading: boolean
    setRcIsLoading: any
    courses: any[];
    setCourses: (data: any[]) => void;
    testSeries: any[]
    setTestSeries: (data: any[]) => void
}

export const recommendStore = create(persist<IRecommendCoursesStore>((set) => ({
    testSeries: [],
    setTestSeries: (value) => set({ testSeries: value }),
    rcIsLoading: true,
    setRcIsLoading: (rcIsLoading) => set({ rcIsLoading }),
    courses: [],
    setCourses: (data) => set({ courses: data })
}), {
    name: "recommendStore"
}))