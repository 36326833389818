import { Avatar, Card, Col, Row } from "antd";
import React from "react";
import { useApi, useMount } from "../../hooks";
import Shimmer from "../../component/Shimmer";
import { Link } from "@tanstack/react-router";
import { useNcertStore } from "../../store/useNcertStore";
import Meta from "antd/es/card/Meta";
import DownloadCard from "./DownloadCard";
import NcertIcon from "../../component/icons/NcertIcon";
import AppMetaHeader from "../../component/AppMetaHeader";
import PageContainer from "../../component/Layout/PageContainer";

const NcertBooks = () => {
  const { ncertData, isLoading, setActive, setClassName } = useNcertStore();

  return (
    <>
      <AppMetaHeader currentPageTitle="NCERT Books" />
      <PageContainer
        currentPageTitle="NCERT Books"
        title="Study Materials"
        url="/study-materials"
      >
        <Shimmer type="test-series" loading={isLoading} count={4}>
          <Row gutter={[15, 15]}>
            {ncertData?.map((elem, index: any) => {
              return (
                <Col
                  xxl={6}
                  xl={6}
                  lg={8}
                  md={12}
                  sm={12}
                  xs={24}
                  key={elem?.id}
                >
                  <Link
                    to={`/study-materials/ncert-book-pdf?c=${elem?.slug}`}
                    onClick={() => {
                      setActive(elem?.subjects);
                      setClassName(elem?.name);
                    }}
                  >
                    <DownloadCard title={elem?.name} avatar={<NcertIcon />} />
                  </Link>
                </Col>
              );
            })}
          </Row>
        </Shimmer>
      </PageContainer>
    </>
  );
};

export default NcertBooks;
