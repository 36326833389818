import React from "react";
export default function LeftCollapse() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 0C17.8408 0 23 5.15916 23 11.5C23 17.8408 17.8408 23 11.5 23C5.15915 23 -1.90735e-06 17.8408 -1.90735e-06 11.5C-1.90735e-06 5.15916 5.1589 0 11.5 0ZM11.5 22.1863C17.3926 22.1863 22.1863 17.3923 22.1863 11.5C22.1863 5.60714 17.3926 0.813689 11.5 0.813689C5.6074 0.813689 0.813686 5.60714 0.813686 11.5C0.813686 17.3923 5.6074 22.1863 11.5 22.1863ZM10.7896 6.56957C10.9485 6.41087 11.2061 6.41087 11.3651 6.56957C11.524 6.72852 11.524 6.98638 11.3651 7.14508L7.41673 11.0932H16.8743C17.0992 11.0932 17.2812 11.2751 17.2812 11.5C17.2812 11.7244 17.0992 11.9068 16.8743 11.9068H7.41673L11.3651 15.8549C11.524 16.0136 11.524 16.2715 11.3651 16.4304C11.2061 16.5891 10.9485 16.5891 10.7896 16.4304L6.32142 11.962C6.1985 11.8386 6.13052 11.6743 6.13052 11.5C6.13052 11.3249 6.1985 11.1611 6.32142 11.0377L10.7896 6.56957Z"
        fill="white"
      />
    </svg>
  );
}
