import React, { useEffect, useState } from "react";
import { Row, Col, Tabs } from "antd";
import YourResult from "../../component/quiz/result/yourResult";
import TopLeader from "../../component/quiz/result/topLeader";
import ReviewAndExplanation from "../../component/quiz/result/reviewAndExplanation";
import Header from "../../component/quiz/result/header";
import useApi from "../../hooks/useApi";
import { useParams, useRouter } from "@tanstack/react-router";
import useMount from "../../hooks/useMount";
import { useAuthStore } from "../../store/auth";

const QuizResult = () => {
  const router = useRouter();
  const { user } = useAuthStore();

  const { qId } = useParams({ from: "quizzes/$quizzesType/$qId/start" });
  const { data, load } = useApi(`quizzes/${qId}/leaders?phone=${user?.phone}`);
  useMount(() => {
    load();
  });

  const items = [
    {
      key: "1",
      label: "Your Result",
      children: <YourResult />,
    },
    {
      key: "2",
      label: "Top 10 Leader",
      children: <TopLeader data={data} />,
    },
    {
      key: "3",
      label: "Review and Explanation",
      children: <ReviewAndExplanation />,
    },
  ];

  const [activeKey, setActiveKey] = useState("1");

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{ height: "100%" }}
      className="quizSVG"
    >
      <Col
        span={23}
        style={{
          height: "95vh",
          borderRadius: "0.5em",
        }}
      >
        <Row style={{ height: "10%" }}>
          <Header />
        </Row>
        <Row
          style={{
            height: "90%",
            background: "white",
            paddingTop: "1%",
            borderRadius: "0em 0em 0.5em 0.5em",
          }}
        >
          <Col
            xxl={19}
            xl={19}
            lg={19}
            md={19}
            sm={22}
            xs={22}
            style={{ marginLeft: "4%" }}
          >
            <Tabs
              items={items}
              defaultActiveKey="1"
              activeKey={activeKey}
              onChange={handleTabChange}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default QuizResult;
