import React from "react";
import parse from "html-react-parser";
import useQuizStore from "../../../store/quizStore";

export const Option = ({ elem, item, i, updateQuestion }) => {
  const status = useQuizStore((state) => state.status);
  const answer = status[item.id].attempt;
  if (!elem) return;
  return (
    <div
      className={answer !== i ? "quiz-options-wrapper" : "blueColor"}
      onClick={() => {
        updateQuestion(item?.id, i);
      }}
    >
      <div
        style={{ margin: "15px 15px" }}
        className={`
          custom-btn
          ${answer === i && "quiz-start-btn-self_1"}`}
      >
        <b>{(i + 10).toString(36).toUpperCase()}</b>
      </div>
      <div className="quiz-options">
        <p
          style={{
            paddingLeft: "8px",
            marginTop: "0.8rem",
          }}
        >
          <p>{parse(String(elem))}</p>     
        </p>
      </div>
    </div>
  );
};
