export default function DashboardIcon() {
  return (
    //   <svg
    //   width="18"
    //   height="22"
    //   viewBox="0 0 18 22"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >

    <>
      <path
        d="M19.2693 9.76929H13.4232C13.0196 9.76929 12.6924 10.0965 12.6924 10.5001V19.2693C12.6924 19.6729 13.0196 20.0001 13.4232 20.0001H19.2693C19.6729 20.0001 20.0001 19.6729 20.0001 19.2693V10.5001C20.0001 10.0965 19.6729 9.76929 19.2693 9.76929Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.2693 1H13.4232C13.0196 1 12.6924 1.32718 12.6924 1.73077V4.66846C12.6924 5.07205 13.0196 5.39923 13.4232 5.39923H19.2693C19.6729 5.39923 20.0001 5.07205 20.0001 4.66846V1.73077C20.0001 1.32718 19.6729 1 19.2693 1Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.57692 1H1.73077C1.32718 1 1 1.32718 1 1.73077V10.5C1 10.9036 1.32718 11.2308 1.73077 11.2308H7.57692C7.98051 11.2308 8.30769 10.9036 8.30769 10.5V1.73077C8.30769 1.32718 7.98051 1 7.57692 1Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.57692 15.6008H1.73077C1.32718 15.6008 1 15.928 1 16.3316V19.2693C1 19.6729 1.32718 20.0001 1.73077 20.0001H7.57692C7.98051 20.0001 8.30769 19.6729 8.30769 19.2693V16.3316C8.30769 15.928 7.98051 15.6008 7.57692 15.6008Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  );
}
