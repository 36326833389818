import React from 'react'

import { Link } from '@tanstack/react-router'
import { Button } from 'antd'

const DataNotFound = ({ image = "i", title = "No Data Found", isButton = false, btnTitle = "Buy Now", btnLink = "/courses" }) => {
  const src = `${process.env.PUBLIC_URL}/images/${image}.svg`
  return (
    <div className='no-data-found mt-2' >
      <h3>{title}</h3>
      <img alt='Not found' src={src} className="no-data-found-img" />
      {isButton === true && <div>
        <Link to={btnLink}>
          <Button><i className='bx bx-rupee'></i> {btnTitle}</Button>
        </Link>
      </div>}
    </div>
  )
}

export default DataNotFound