import { create } from "zustand";
import { persist } from "zustand/middleware";
import { TTimer } from "../interfaces/quiz";

interface IQuizTimer {
    timer: TTimer | null;
    setInitialTime: (time: TTimer) => void;
    updateTimer: () => boolean;
    resetTimer: () => void;

}

export const useTimerStore = create(
    persist<IQuizTimer>(
        (set, get) => ({
            setInitialTime: (time) => {
                const { minutes, seconds } = time;
                const timer =
                    minutes > 60
                        ? {
                            hours: Math.floor(minutes / 60),
                            minutes: minutes % 60,
                            seconds,
                        }
                        : { ...time, hours: 0 };
                set({ timer });
            },
            timer: null,
            updateTimer: () => {
                function getTimer(prevTimer: TTimer) {
                    if (prevTimer.seconds > 0) {
                        return { ...prevTimer, seconds: prevTimer.seconds - 1 };
                    }
                    if (prevTimer.minutes > 0) {
                        return {
                            ...prevTimer,
                            minutes: prevTimer.minutes - 1,
                            seconds: 59,
                        };
                    }
                    if (prevTimer.hours! > 0) {
                        return { hours: prevTimer.hours! - 1, minutes: 59, seconds: 59 };
                    }
                    return false;
                }
                const timer = getTimer(get().timer!);
                if (timer) {
                    set({ timer: timer });
                    return true;
                }
                return false;
            },
            resetTimer: () => {
                set({ timer: null });
            },
        }),
        {
            name: "timer-storage",
        }
    )
);
