import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import packageJson from '../../package.json';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Loader from '../component/loader';

const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];

const PdfViewer = ({ pdfUrl, height }) => {

    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <div>
            {pdfUrl &&
                <div>
                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
                        <div style={{ height: height }}>
                            <Viewer
                                fileUrl={pdfUrl}
                                renderLoader={() => <div className='h-100 w-100'><Loader /></div>}
                                plugins={[
                                    defaultLayoutPluginInstance,
                                ]}
                            />
                        </div>
                    </Worker>
                </div>
            }
        </div>
    )
}

export default PdfViewer