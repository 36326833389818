import { create } from "zustand";
import { persist } from 'zustand/middleware'
interface IPublicStore {
    publicData: any
    active: any;
    storePublicData: any
    setActive: any
    isLoading: boolean
    setIsLoading: any
}

const DEFAULT_BATCHES = { id: null, name: "All Course", url: "home", short_info: "Explore All Exams at KGS", color: "#DEEFFF", icon: "https://khanglobalstudies.com/icons/exams/all.png" }

export const usePublicStore = create(persist<IPublicStore>((set) => ({
    active: {},
    setActive: (data) => set(({ active: data })),
    isLoading: true,
    setIsLoading: (isLoading) => set({ isLoading }),
    publicData: [],
    storePublicData: (data) => {
        if (data?.batches?.length) {
            return set(({ publicData: { ...data, batches: [DEFAULT_BATCHES, ...data.batches] } }))
        }
    },

}), {
    name: "publicStore"
})); 
