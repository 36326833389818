import { Button, Result } from "antd";
import React from "react";
import { useApi, useMount } from "../hooks";
import { Link, useSearch } from "@tanstack/react-router";
import Loader from "../component/loader";
import styled from "styled-components";
import { IOrders } from "./payment";

const PaymentStatus = () => {
  const { order_id } = useSearch({
    from: "/payment-status",
  });

  const { data, isLoading, load } = useApi<IOrders>(`orders/${order_id}`);

  useMount(() => {
    load();
  }, [order_id]);

  return isLoading ? (
    <div style={{ position: "absolute", top: "50%", left: "50%" }}>
      <Loader />
    </div>
  ) : (
    <Result
      status="success"
      title={
        <Title>
          Successfully Purchased <Title2>{data?.course?.title}</Title2>
        </Title>
      }
      subTitle={
        <SubTitle>
          Order Id: KGS-{data?.id}.
          <br /> Amount: {"₹"} {data?.amount}/-
          <br />
          If you have any questions regarding your purchase,
          <br />
          you can contact us on enquiry@khanglobalstudies.com & Call us at +91
          8757354880.
          <br /> Thank You !!!
        </SubTitle>
      }
      extra={[
        <>
          <Link to={"/home"}>
            <Button type="primary" size="large">
              Home
            </Button>
          </Link>
          <Link
            to={
              data?.test_series
                ? `/quizzes/${data?.test_series?.slug}`
                : `/courses/${data?.course?.slug}/play`
            }
          >
            <Button type="primary" size="large">
              Go to {data?.course?.title}
            </Button>
          </Link>
          ,
        </>,
      ]}
    />
  );
};

const Title = styled.p`
  font-size: 1.2em;
  margin-bottom: 0%;
`;

const Title2 = styled.p`
  font-weight: 600;
  margin-bottom: 0%;
`;

const SubTitle = styled.p`
  font-size: 1.2em;
  margin-bottom: 0%;
  font-weight: 550;
`;

export default PaymentStatus;
