import { Radio } from "antd";
import React from "react";
import styled from "styled-components";

const PaymentGateway = (props) => {
  return (
      <div
        style={{
          display: "flex",
          border: "1px solid #CECECE",
          borderRadius: "16px",
          padding: "15px",
          marginBottom: "15px",
        }}
      >
        <Radio {...props}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Title>{props?.title}</Title>
              <p style={{ marginBottom: "0%" }}>
                UPI(NIL), Credit Card(NIL), Debit Card(NIL)
              </p>
            </div>
            <div style={{ marginLeft: "3%" }}>
              {props?.img && (
                <img
                  src={props?.img}
                  width="70px"
                  height="auto"
                  alt="payment-img"
                />
              )}
            </div>
          </div>
        </Radio>
      </div>
  );
};

const Title = styled.p`
  font-size: 1.3em;
  font-weight: 700;
  margin-bottom: 0%;
  color: #3e4192;
`;

export default PaymentGateway;
