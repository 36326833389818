import React from "react";
import AppMetaHeader from "../../component/AppMetaHeader";
import PageContainer from "../../component/Layout/PageContainer";
import { Link, useSearch } from "@tanstack/react-router";
import { useNcertStore } from "../../store/useNcertStore";
import { Col, Row } from "antd";
import DownloadCard from "./DownloadCard";
import NcertIcon from "../../component/icons/NcertIcon";
import Shimmer from "../../component/Shimmer";

const SubjectListing = () => {
  const { ncertData, isLoading, active, className } = useNcertStore();

  const { c } = useSearch({
    from: "/study-materials/ncert-book-pdf",
  });

  return (
    <>
      <AppMetaHeader currentPageTitle="NCERT Books" />

      <PageContainer
        currentPageTitle={className}
        transparent={true}
        title="NCERT Books"
        url="/study-materials"
      >
        <Shimmer type="downloads" loading={isLoading} count={4}>
          <Row gutter={24}>
            {active?.map((item) => (
              <Col
                xxl={6}
                xl={6}
                lg={8}
                md={12}
                sm={12}
                xs={24}
                style={{ marginBottom: "2%" }}
              >
                <Link
                  to={`/study-materials/ncert-book-pdfs?c=${c}&subject=${item?.slug}`}
                >
                  <DownloadCard title={item?.name} avatar={<NcertIcon />} />
                </Link>
              </Col>
            ))}
          </Row>
        </Shimmer>
      </PageContainer>
    </>
  );
};

export default SubjectListing;
