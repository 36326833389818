import { Row, Col, Button } from "antd";
import React from "react";

const HeaderLayout = (props) => {
  return (
    <>
      <Row
        style={{
          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
          position: "relative",
          backgroundColor: "white",
          marginBottom: "10px",
          width: "100%",
          background: "#ffffff",
          height: "100%",
        }}
        align="middle"
      >
        <Col
          xl={16}
          lg={16}
          md={20}
          sm={20}
          xs={20}
          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
        >
          <h3
            style={{ fontWeight: "800", color: "#000000" }}
            className="header-title"
          >
            <strong style={{ fontWeight: "800", color: "#9f9f9f" }}>
              {props?.quiz?.title}
            </strong>
          </h3>
        </Col>
        <Col
          xl={8}
          lg={8}
          md={4}
          sm={4}
          xs={4}
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            style={{ marginTop: "0.6rem" }}
            className="quittest"
            onClick={() => props.onQuit()}
          >
            <b>Quit test</b>
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default HeaderLayout;
