import { Link } from "@tanstack/react-router";
import { Col, Row, Tag } from "antd";
import React from "react";
import CustomCard from "../../component/GlobalCard";
import EmptyResult from "../../component/Empty";
import { dateParse } from "../../helpers/help";
import styled from "styled-components";
import CourseTag from "./CourseTag";
import { useMediaQuery } from "react-responsive";

const ExpiringCourse = (data) => {
  const isSmallScreen = useMediaQuery({
    query: "(max-width: 426px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(max-width: 1025px)",
  });

  const isExtraLageScreen = useMediaQuery({
    query: "(max-width: 1441px)",
  });
  const clickHandler = () => {
    return;
  };

  return (
    <Row
      gutter={[16, 8]}
      style={{
        marginTop: isSmallScreen
          ? "4em"
          : isMediumScreen
          ? "4em"
          : isLargeScreen
          ? "4em"
          : isExtraLageScreen
          ? "4em"
          : "4em",
      }}
    >
      {data?.data?.length ? (
        data?.data?.map((elem) => {
          return (
            <Col
              key={elem?.id}
              xs={24}
              sm={12}
              md={8}
              lg={6}
              xl={6}
              style={{ marginTop: 10 }}
            >
              <Link to={`/courses/${elem.slug}/play`}>
                <CustomCard
                  title={elem.title}
                  type="cover"
                  cover={{
                    url: elem?.image?.small || "/kgs.jpg",
                    height: "100%",
                    width: "100%",
                  }}
                  // actions={[
                  //   {
                  //     title: (
                  //       <img src="/images/live.svg" className="course-logo" />
                  //     ),
                  //     subtitle: "Live",
                  //     onClick: clickHandler,
                  //     type: "danger",
                  //     size: "medium",
                  //     className: "live",
                  //   },
                  //   {
                  //     title: (
                  //       <img
                  //         src="/images/recorded.svg"
                  //         className="course-logo"
                  //       />
                  //     ),
                  //     subtitle: "Recorded",
                  //     onClick: clickHandler,
                  //     type: "success",
                  //     size: "large",
                  //     className: "recoreded",
                  //   },
                  //   {
                  //     title: "",
                  //     subtitle: "Hindi",
                  //     onClick: clickHandler,
                  //     type: "secondary",
                  //     size: "large",
                  //     className: "hindi",
                  //   },
                  // ]}

                  desc={
                    <>
                      <CourseTag cat_id={elem?.category_id} />
                      <ExpireTag>
                        <svg
                          width="9"
                          height="9"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 0C2.24211 0 0 2.24211 0 5C0 7.75789 2.24211 10 5 10C7.75789 10 10 7.75789 10 5C10 2.24211 7.75789 0 5 0ZM8.24211 7.67368L7.34737 6.77895C7.18947 6.98947 7 7.17895 6.77895 7.34737L7.67368 8.24211C6.91579 8.86316 5.96842 9.21053 5 9.21053C2.68421 9.21053 0.789474 7.32632 0.789474 5C0.789474 4.03158 1.13684 3.08421 1.75789 2.32632L2.65263 3.22105C2.81053 3.01053 3 2.82105 3.22105 2.65263L2.32632 1.75789C3.08421 1.13684 4.03158 0.789474 5 0.789474C7.31579 0.789474 9.21053 2.67368 9.21053 4.98947C9.2 5.96842 8.86316 6.91579 8.24211 7.67368Z"
                            fill="#FAAD14"
                          />
                          <path
                            d="M4.99983 2.6106C3.68404 2.6106 2.61035 3.68428 2.61035 5.00007C2.61035 6.31586 3.67351 7.38954 4.99983 7.38954C6.31561 7.38954 7.3893 6.31586 7.3893 5.00007C7.3893 3.68428 6.31561 2.6106 4.99983 2.6106ZM6.02088 5.29481H4.99983C4.84193 5.29481 4.70509 5.16849 4.70509 5.00007V3.74744C4.70509 3.58954 4.8314 3.4527 4.99983 3.4527C5.15772 3.4527 5.29456 3.57902 5.29456 3.74744V4.71586H6.02088C6.17877 4.71586 6.31561 4.84217 6.31561 5.0106C6.31561 5.17902 6.17877 5.29481 6.02088 5.29481Z"
                            fill="#FAAD14"
                          />
                        </svg>
                        {"  "}
                        {dateParse(elem.end_at)}
                      </ExpireTag>
                    </>
                  }
                />
              </Link>
            </Col>
          );
        })
      ) : (
        <EmptyResult
          title={
            "Either You haven't purchased any courses or there are no courses expiring soon."
          }
          showDes={false}
          status={"404"}
        />
      )}
    </Row>
  );
};

const ExpireTag = styled(Tag)`
  background-color: #fff7e8;
  color: #faad14;
  border-radius: 1em;
  border: 0.1em solid #faad14;
  font-weight: 500;
  margin-bottom: 3%;
`;

export default ExpiringCourse;
