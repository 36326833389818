import { Card, Tag } from "antd";
import moment from "moment";
import React from "react";
import { Row, Col } from "antd";
import { Link } from "@tanstack/react-router";
import { TitleContainer, SubTitleContainer } from "./GlobalCard";
import styled from "styled-components";
import Live from "./icons/live";
import Recorded from "./icons/recorded";
import CustomCard from "./GlobalCard";

type IProps = {
  title: string;
  description?: string;
  published_at?: string;
  image: string;
  published?: string;
};

const CourseCard = ({
  title,
  image,
  published_at,
  description,
  published,
}: IProps) => {
  return (
    <>
      <CustomCard
        className={"card-active"}
        title={<TitleContainer>{title}</TitleContainer>}
        type="avatar"
        desc={
          <SubTitleContainer>
            {/* {title}&nbsp;|&nbsp; */}
            <DateTimeDisplay dateTime={published} format="Do MMM YYYY" />
          </SubTitleContainer>
        }
        avatar={{ url: image }}
      />
    </>
  );

  // <Card
  //   size="small"
  //   hoverable={true}
  //   bordered={true}
  //   className="course-card"
  //   cover={
  //     <div className="imageContainer" >
  //       <img width={"100%"} alt={title} src={image} />

  //       {description && (
  //         <div className="videoPlay">
  //           <div className="videoPlayButton">
  //             <svg
  //               width="33"
  //               height="33"
  //               viewBox="0 0 33 33"
  //               fill="none"
  //               xmlns="http://www.w3.org/2000/svg"
  //             >
  //               <path
  //                 fill-rule="evenodd"
  //                 clip-rule="evenodd"
  //                 d="M16.5 32.625C25.4056 32.625 32.625 25.4056 32.625 16.5C32.625 7.59441 25.4056 0.375 16.5 0.375C7.59441 0.375 0.375 7.59441 0.375 16.5C0.375 25.4056 7.59441 32.625 16.5 32.625ZM12.9078 8.5321L25.6765 15.6258C26.3623 16.0068 26.3623 16.9932 25.6765 17.3742L12.9078 24.4679C12.1079 24.9123 11.125 24.3339 11.125 23.4189V9.58108C11.125 8.6661 12.1079 8.08774 12.9078 8.5321Z"
  //                 fill="white"
  //               />
  //             </svg>
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   }

  // >
  //   <strong className="card-content">{title}</strong>

  //   {description && (
  //     <p
  //       className="card-content"
  //       style={{
  //         color: "gray",
  //         marginTop: 0,
  //         marginBottom: 0,
  //         fontSize: "1em",
  //       }}
  //     >
  //       {description}
  //     </p>
  //   )}
  //   {published_at && (
  //     <DateTimeDisplay dateTime={published_at} format="Do MMM YYYY hh:mm A" />
  //   )}
  //   {/* {published && (
  //     <DateTimeDisplay dateTime={published} format="Do MMM YYYY" />
  //   )} */}
  // </Card>
  // );
};

const DateTimeDisplay = ({ dateTime, format }) => {
  if (!dateTime) {
    return null;
  }

  return <span>{moment(dateTime).format(format)}</span>;
};
export default CourseCard;
