import React, { useEffect } from "react";
import Time from "../../utils/time";
import TimeLine from "./timeLine";
import Controls, { HlsControls } from "../../utils/controls";
import ActionButton from "./actionButton";
import VolumeBar from "./volumeBar";
import PlayerSettings from "./playerSettings";
import { IQuality, IRATE } from "../../interfaces/player";

interface IProps {
  url: string;
  isLoading: boolean;
  isPlaying: boolean;
  isVisible: boolean;
  isFullScreen: boolean;
  controls: Controls | HlsControls;
  volume: number;
  setVolume: React.Dispatch<React.SetStateAction<number>>;
  onPlay: () => void;
  setFullScreen: () => void;
  playbackRate: IRATE;
  setPlaybackRate: (rate: IRATE) => void;
  type: "hls" | "yt";
  setQuality: (quality: IQuality) => void;
  quality: IQuality;
}

function createListener(eventType, listener) {
  return function (cb: "addEventListener" | "removeEventListener") {
    document[cb](eventType, listener);
  };
}

export default function ControlBar({
  url,
  isLoading,
  isPlaying,
  isVisible,
  controls,
  volume,
  isFullScreen,
  playbackRate,
  setVolume,
  onPlay,
  setFullScreen,
  setPlaybackRate,
  type,
  setQuality,
  quality,
}: IProps) {
  const controlRef = React.useRef<HTMLDivElement>(null);
  const [goLive, setGoLive] = React.useState(false);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const actions: Record<string, Function> = {
        " ": () => onPlay(),
        Tab: () => e.preventDefault(),
        ArrowLeft: () => controls?.seekBackward(5),
        ArrowRight: () => controls?.seekForward(5),
        ArrowUp: () =>
          setVolume((volume) => (volume <= 90 ? volume + 10 : 100)),
        ArrowDown: () =>
          setVolume((volume) => (volume >= 10 ? volume - 10 : 0)),
      };
      actions[e.key || e.code]?.();
    };
    const listener = createListener("keydown", handleKeyDown);

    listener("addEventListener");
    return () => listener("removeEventListener");
  }, []);

  return (
    <>
      <ActionButton
        loading={isLoading}
        playing={isPlaying}
        visible={isVisible}
        onForward={() => controls?.seekForward(5)}
        onBackward={() => controls?.seekBackward(5)}
        onPlay={onPlay}
      />
      <div className={`control-bar-container `} ref={controlRef}>
        <TimeLine
          url={url}
          controls={controls}
          playing={isPlaying}
          togglePlayer={onPlay}
          onScrub={(scrub) => {}}
          onChange={(percent) => {
            if (controls?.isLive) {
              percent < 0.95 ? setGoLive(true) : setGoLive(false);
            }
          }}
        />
        <div className="control-bar">
          <div className="control-bar-left">
            <i
              className={`bx bx-${isPlaying ? "pause" : "play"}`}
              onClick={onPlay}
            ></i>
            <VolumeBar volume={volume} changeVolume={setVolume} />
            {controls && (
              <Time
                playing={isPlaying}
                controls={controls}
                goLive={goLive}
                goLiveHandler={() => setGoLive(false)}
              />
            )}
          </div>
          <div className="control-bar-right">
            <PlayerSettings
              rate={playbackRate}
              onRateChange={(rate) => setPlaybackRate(rate)}
              type={type}
              controls={controls}
              quality={quality}
              setQuality={setQuality}
            />
            <div
              id="fullscreen-btn"
              className={`${isFullScreen ? "landscape" : "portrait"}`}
              onClick={() => {
                setFullScreen();
              }}
              style={{ marginLeft: "0.5em", marginTop: "0.1em" }}
            >
              <i
                className={`bx bx-${isFullScreen ? "exit-" : ""}fullscreen`}
                style={{ color: "#ffffff" }}
              ></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// const SoundSlide = ({ volume, changeVolume }) => {
//   const [vol, setVol] = React.useState(volume);
//   const volChange = (vol) => {
//     changeVolume(vol);
//     setVol(vol);
//   };

//   return (
//     <div className="sound-container">
//       {volume == 0 ? (
//         <i className="bx bx-volume-mute" onClick={() => volChange(75)}></i>
//       ) : (
//         <i className="bx bx-volume-low" onClick={() => volChange(0)}></i>
//       )}
//       <input
//         type="range"
//         min="0"
//         max="100"
//         style={{
//           background: `linear-gradient(to right, rgba(30,176,76) ${vol}%, white 60%)`,
//         }}
//         value={volume}
//         id="range2"
//         onChange={(e) => {
//           volChange(e.target.value);
//         }}
//       />
//     </div>
//   );
// };
