import { TFeedBack } from "../pages/CoursePlayer";

export const  getKey = (feedBack:TFeedBack)=>`fd_${feedBack.id}_${feedBack.type}`


export function throttle(func, delay)  {
    let prev = 0;
    return (...args) => {
        let now = new Date().getTime();
        if (now - prev > delay) {
            prev = now;
            return func(...args);
        }
    }
}
export default function debounce(func,ms){
    let lastCb:NodeJS.Timer;
    return (...args:any[])=>{
        clearTimeout(lastCb)
        lastCb = setTimeout(()=>func(...args),ms)
    }
}
