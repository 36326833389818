import React from "react";
import AppMetaHeader from "../../component/AppMetaHeader";
import PageContainer from "../../component/Layout/PageContainer";
import { usePublicStore } from "../../store/usePublicStore";
import DownloadCard from "../../pages/downloads/DownloadCard";
import { Avatar, Card, Col, Image, Row } from "antd";
import Meta from "antd/es/card/Meta";
import { Link } from "@tanstack/react-router";
import { PracticeData } from "./practiceData";

const Practice = () => {
  const { active } = usePublicStore();

  const dailyQuiz = active?.config?.daily_quiz;

  return (
    <>
      <AppMetaHeader currentPageTitle="Practice" />

      <PageContainer currentPageTitle="Practice">
        <Row gutter={[24, 24]}>
          {(dailyQuiz === undefined ? PracticeData : dailyQuiz).map(
            (item, index) => (
              <Col xxl={4} xl={6} lg={8} md={12} sm={12} xs={24}>
                <Link to={`/practice/${item?.slug}?id=${item?.type}`}>
                  <Card
                    className={"card-active"}
                    style={{ cursor: "pointer" }}
                    key={index}
                  >
                    <Meta
                      avatar={
                        <Avatar
                          // src={item?.image}
                          icon={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "17%",
                              }}
                            >
                              <img src={item?.image} width="27px" />
                            </div>
                          }
                          size={50}
                          style={{ background: "#E8E8E8" }}
                        />
                      }
                      title={
                        <div
                          style={{
                            marginLeft: "5%",
                            whiteSpace: "break-spaces",
                          }}
                          className="card-content2"
                        >
                          {item?.name}
                        </div>
                      }
                    />
                  </Card>
                </Link>
              </Col>
            )
          )}
        </Row>
      </PageContainer>
    </>
  );
};

export default Practice;
