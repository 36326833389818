import React from "react";

type TProps = {
  volume: number;
  changeVolume: (vol: number) => void;
};

const VolumeBar = ({ volume, changeVolume }: TProps) => {
  function getVolumeClass() {
    if (volume === 0) return "bx-volume-mute";
    if (volume >= 50) return "bx-volume-full";
    return "bx-volume-low";
  }

  return (
    <div className="sound-container">
      <i
        className={`bx ${getVolumeClass()}`}
        onClick={() => changeVolume(volume === 0 ? 75 : 0)}
      ></i>
      <input
        type="range"
        min="0"
        max="100"
        style={{
          background: `linear-gradient(to right, rgba(30,176,76) ${volume}%, white 60%)`,
        }}
        value={volume}
        id="range2"
        onChange={(e) => {
          changeVolume(Number(e.target.value));
        }}
      />
    </div>
  );
};

export default VolumeBar;
