import React, { useState } from "react";
import showNotification from "../../utils/notify";
import TextArea from "antd/es/input/TextArea";
import { Button } from "antd";
import { useNavigate } from "@tanstack/react-router";
import { useAuthStore } from "../../store/auth";
import api from "../../config/api";

const AttemptAnswerWriting = ({ id, que }) => {
  const textAreaRef = React.useRef();
  const [wordCount, setWordCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ansWritten, setAnsWritten] = useState("");
  const [formData, setFormData] = useState({
    ansWritten: "",
  });
  const [ansFile, setAnsFile] = useState<File | null>(null);
  const navigate = useNavigate({ from: "/" });

  const { user } = useAuthStore();

  function onChangeFormData(e) {
    e.preventDefault();
    setWordCount(e.target.value.trim().split(" ").length);
    setFormData((pre) => ({
      ...pre,
      [e.target.id]: setAnsWritten(e.target.value.trim()),
    }));
  }

  const onSubmit = async () => {
    const fd = new FormData();
    if (ansFile && ansWritten) {
      showNotification(
        "error",
        "Please choose either a file attachment OR enter a written answer, not both.",
        3
      );
    } else if (ansFile || ansWritten) {
      fd.append(ansFile ? "attachment" : "answer", ansFile || ansWritten);
      fd.append("name", user?.name);
      fd.append("phone", user?.phone);

      try {
        setLoading(true);
        const res = await api.post(`subjectives-questions/${id}`, fd, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (res?.data.status == true) {
          showNotification(
            "success",
            "You have submitted your Answer successfully.",
            3
          );
          setTimeout(() => {
            window.location.href = "/submission";
          }, 1000);
        }
      } catch (error) {
        setLoading(false);
        showNotification(
          "error",
          "Error submitting your answer. Please try again.",
          3
        );
      }
    } else {
      showNotification(
        "error",
        "Please provide either a file attachment or a written answer.",
        3
      );
    }
  };

  function onChange(e) {
    e.preventDefault();

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if (file.size > 1048576) {
        showNotification("error", "The file uploaded is more than 1 MB", 3);
        e.target.value = null;
        setAnsFile(null);
      } else {
        setAnsFile(file);
        showNotification("success", "The file is ready to be uploaded", 3);
      }
    } else {
      e.target.value = null;
      setAnsFile(null);
    }
  }

  return (
    <div>
      <h3>Attempt Answer</h3>
      <h4>Question:</h4>
      <b>{que}</b>
      <div>
        <form>
          <h3>Write Your Answer Here:</h3>
          <TextArea
            maxLength={2000}
            rows={6}
            ref={textAreaRef}
            id="answer"
            onChange={onChangeFormData}
            placeholder="Enter Your Answer"
          />
          <p>
            Your Answer in Words:
            {wordCount > 250 ? "You have to do it in 250 Words" : wordCount}
          </p>
          <h2 className="ansWritingTextCenter">OR</h2>
          <h3>Upload Your Answer Here:</h3>
          <p style={{ color: "red" }}>
            Note: Answer is uploaded in PDF format and PDF is not more than 1 MB
          </p>
          <input
            type="file"
            onChange={onChange}
            id="attachment"
            style={{ fontSize: "15px" }}
            accept=".pdf"
          />
          <br></br>
          <br></br>

          <Button
            size="large"
            type="primary"
            onClick={onSubmit}
            loading={loading}
            disabled={loading}
          >
            Submit Answer
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AttemptAnswerWriting;
