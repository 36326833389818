import { Col, Row, Form, Select, Input, Button, Card } from "antd";
import React, { useEffect, useState } from "react";
import api from "../../config/api";
import showNotification from "../../utils/notify";
import { useApi, useMount, useStorage } from "../../hooks";
import { useAuthStore } from "../../store/auth";
import styled from "styled-components";

const { TextArea } = Input;

const AskDoubt = ({ url, lesson, cId }) => {
  const { user } = useAuthStore();
  const { data: allCourses, isLoading, load } = useApi<any>(`/all-courses`);
  const [attachment, setAttachment] = useState<string | Blob | null>(null);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      selectSubject: lesson?.name,
    });
  }, [lesson?.name, form]);

  const [formData, setFormData] = useState({
    lecture: "",
    message: "",
  });

  useMount(() => {
    load();
  });

  const [selectedData, setSelectedData] = useState({
    selectCourse: cId || "",
    doubtType: "",
    selectSubject: "",
    lecture: "",
  });

  useMount(() => {}, [lesson]);

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  function onChangeSelect(id: string, e: string) {
    setSelectedData((prevState) => ({
      ...prevState,
      [id]: e,
    }));
  }

  // function onChangeAttachment(e) {
  //   if (e.target.files) {
  //     if (e.target.files[0].size >= 1048576) {
  //       showNotification("error", "The file uploaded is more than 1 MB", 3);
  //       e.target.value = null;
  //       setAttachment(null);
  //     } else {
  //       setAttachment(e.target.files[0]);
  //       showNotification("success", "The file is ready to be upload", 3);
  //     }
  //   }
  // }

  function onChangeAttachment(e) {
    e.preventDefault();

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if (file.size > 1048576) {
        showNotification("error", "The file uploaded is more than 1 MB", 3);
        e.target.value = null;
        setAttachment(null);
      } else {
        setAttachment(file);
        showNotification("success", "The file is ready to be uploaded", 3);
      }
    } else {
      e.target.value = null;
      setAttachment(null);
    }
  }

  const doubt_type = [
    { id: 1, title: "Subject Related" },
    { id: 2, title: "Notes Related" },
    { id: 3, title: "Quiz Related" },
    { id: 4, title: "Teacher Related" },
    { id: 5, title: "App/Website Related" },
  ];

  const handleSubmit = async () => {
    const fd = new FormData();

    const requiredFields = [
      user?.name,
      user?.phone,
      user?.email,
      selectedData.selectCourse || cId,
      selectedData.selectSubject || lesson?.name,
      formData.lecture || selectedData.lecture,
      selectedData.doubtType,
    ];

    if (
      requiredFields.some((field) => !field) ||
      (!formData.message && !attachment)
    ) {
      showNotification("error", "Please fill all fields before submitting.", 3);
      return;
    }

    fd.append("name", user?.name);
    fd.append("phone", user?.phone);
    fd.append("email", user?.email);
    fd.append("course_id", selectedData.selectCourse);
    fd.append("lesson", selectedData.selectSubject || lesson?.name);
    fd.append("lecture", formData.lecture || selectedData.lecture);
    fd.append("message", formData.message);
    fd.append("type", selectedData.doubtType);
    fd.append("attachment", attachment!);

    setLoading(true);
    const res = await api.post(`/doubts`, fd, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    setLoading(false);

    if (res?.status === 200) {
      showNotification(
        "success",
        "You have submitted your Doubt successfully.",
        3
      );
      setTimeout(() => {
        window.location.href = url ? url : "/home";
      }, 1000);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <h1 style={{ fontWeight: "600", marginBottom: "0px" }}>
        We are here to solve your Doubts
      </h1>
      <p style={{ fontSize: "1.1em", fontWeight: "500", marginBottom: "1%" }}>
        Submit your doubt in the form listed below, our team will reach back to
        you with the solution.
      </p>

      <Form form={form} layout="vertical" initialValues={{ remember: true }}>
        <Form.Item
          name="selectCourse"
          style={{ marginBottom: "1rem" }}
          label="Select Course"
          rules={[
            {
              required: true,
              message: "Please Select Course!",
            },
          ]}
          initialValue={cId || undefined}
        >
          <Select
            size="large"
            placeholder="Select Course"
            id="selectCourse"
            onChange={(e) => onChangeSelect("selectCourse", e)}
            disabled={cId ? true : false}
          >
            {allCourses?.map((item) => (
              <Select.Option key={item?.id} value={item?.id}>
                {item?.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {selectedData?.selectCourse && (
          <Form.Item
            name="selectSubject"
            style={{ marginBottom: "1rem" }}
            label="Select Subject"
            rules={[
              {
                required: true,
                message: "Please Select Subject!",
              },
            ]}
            initialValue={lesson?.name}
          >
            <Select
              size="large"
              placeholder="Select Subject"
              id="selectSubject"
              onChange={(e) => onChangeSelect("selectSubject", e)}
              disabled={lesson?.id ? true : false}
            >
              {allCourses
                ?.find((i) => i.id === Number(selectedData?.selectCourse))
                ?.lessons?.map((sub) => (
                  <Select.Option key={sub} value={sub}>
                    {sub}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}

        <Row justify="space-between">
          <Col span={11}>
            <Form.Item
              style={{ marginBottom: "1rem" }}
              label="Enter Lecture Number"
              rules={[
                {
                  required: true,
                  message: "Please Enter Lecture!",
                },
              ]}
            >
              {lesson ? (
                <Select
                  size="large"
                  placeholder="Select Lecture"
                  id="lecture"
                  onChange={(e) => onChangeSelect("lecture", e)}
                >
                  {lesson?.videos?.map((item) => (
                    <Select.Option key={item?.id} value={item?.name}>
                      {item?.name}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Input
                  size="large"
                  type="text"
                  id="lecture"
                  onChange={onChange}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="Doubt Type" style={{ marginBottom: "1rem" }}>
              <Select
                size="large"
                placeholder="Select Doubt Type"
                id="doubtType"
                onChange={(e) => onChangeSelect("doubtType", e)}
              >
                {doubt_type?.map((item) => (
                  <Select.Option key={item.id} value={item.title}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Doubt in words (upto 255 words) :">
          <TextArea rows={4} id="message" onChange={onChange} size="large" />
        </Form.Item>

        <Form.Item>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              // size="large"
              type="file"
              id="attachment"
              accept=".jpeg,.png,.jpg,.pdf"
              onChange={onChangeAttachment}
              style={{
                border: "1px solid #E1E0E0",
                padding: "0.5em",
                borderRadius: "0.8em",
              }}
            />
            <p
              style={{
                marginBottom: "0%",
                color: "black",
                fontWeight: "500",
                marginLeft: "3%",
              }}
            >
              *<b>Note:</b> File will be not more than 1 MB*
            </p>
          </div>
        </Form.Item>

        <SubmitButton
          type="primary"
          size="large"
          loading={loading}
          disabled={loading}
          onClick={handleSubmit}
        >
          Submit
        </SubmitButton>
      </Form>
    </div>
  );
};

const SubmitButton = styled(Button)`
  background-color: #2e3192;
  color: white;
  box-shadow: none;
  width: 10em;
`;

export default AskDoubt;
