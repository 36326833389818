import { Link } from "@tanstack/react-router";
import { Col, Row } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

import { useApi, useMount } from "../../hooks";
import { ITestResponse, ITestSeries } from "../../interfaces/quiz.types";
import CustomCard from "../../component/GlobalCard";
import Shimmer from "../../component/Shimmer";
import PaginationComp from "../../utils/PaginationComp";
import { useMediaQuery } from "react-responsive";
import EmptyResult from "../../component/Empty";

const AllQuiz: React.FC = ({ isChecked }) => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(null);
  const [perPage, setPerPage] = useState(null);
  const { data, load, isLoading } = useApi<ITestResponse>("user/test-series");
  const [online, offline]: ITestSeries[][] = [[], []];
  

  useMount(() => {
    load({ page });
  }, [page]);

  useMount(() => {
    if (!data) return;
    setTotal(data?.total);
    setPerPage(data?.per_page);
  }, [data]);

  data?.data?.forEach((item) => {
    (item?.category_id === 1 || item?.category_id === 2) && online.push(item);
    (item.category_id === 3 || item?.category_id === 2) && offline.push(item);
  });

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 426px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(max-width: 1025px)",
  });

  const isExtraLageScreen = useMediaQuery({
    query: "(max-width: 1441px)",
  });

  return (
    <div
      style={{
        marginTop: isSmallScreen
          ? "0em"
          : isMediumScreen
          ? "0em"
          : isLargeScreen
          ? "0em"
          : isExtraLageScreen
          ? "0em"
          : "0em",
      }}
    >
      <Shimmer type="test-series" loading={isLoading} count={4}>
        <Row gutter={[16, 24]} style={{ width: "100%" }}>
          {(isChecked ? offline : online)?.length === 0 ? (
            <EmptyResult
              title={"No Data Found!"}
              showDes={false}
              status={"404"}
            />
          ) : (
            (isChecked ? offline : online)?.map((elem, index: any) => {
              return (
                <>
                  <Col
                    xxl={6}
                    xl={8}
                    lg={8}
                    md={12}
                    sm={12}
                    xs={24}
                    key={elem?.id}
                  >
                    <Link to={`/quizzes/${elem?.slug}`} key={elem.id}>
                      <CustomCard
                        style={{ width: "100%" }}
                        title={<TestContainer>{elem.title}</TestContainer>}
                        type="avatar"
                        desc={
                          <TestSubConatiner>
                            {"Hindi / English"}
                          </TestSubConatiner>
                        }
                        avatar={{
                          url: "images/india.svg",
                          height: "100%",
                          width: "100%",
                        }}
                        className={"card-active"}
                        border={{
                          borderColor: "pink",
                          borderRadius: "1em",
                          borderWidth: "0.2em",
                        }}
                      />
                    </Link>
                  </Col>
                </>
              );
            })
          )}
        </Row>

        <br />

        {/* {(isChecked ? offline : online)?.length === 0 && ( */}
        <PaginationComp
          page={page}
          total={total}
          perPage={perPage}
          onChange={(page) => setPage(page)}
        />
        {/* )} */}
      </Shimmer>
    </div>
  );
};

export const TestContainer = styled.span`
  color: #2f2e41;
  font-family: Urbanist;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 0.7rem;
  // white-space: nowrap
  white-space: normal;
`;
const TestSubConatiner = styled.span`
  color: #757575;
  font-family: Urbanist;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: -0.2rem;
  white-space: nowrap;
`;

export default AllQuiz;
