import {
  Badge,
  Col,
  Input,
  Row,
  Drawer,
  Image,
  Card,
  Avatar,
  Modal,
  Button,
  Tag,
} from "antd";
import React, { useState } from "react";
import { Popover } from "antd";
import SearchIcon from "./icons/profile/SearchIcon";
import NotificationIcon from "./icons/profile/NotificationIcon";
import { useApi, useMount, useStorage } from "../hooks";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import NotificationContent from "./NotificationContent";
import ProfileContent from "./ProfileContent";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import SidebarMenu from "./Layout/SidebarMenu";
import api from "../config/api";
import Shimmer from "../component/Shimmer";
import EmptyResult from "../component/Empty";
import Meta from "antd/es/card/Meta";
import { usePublicStore } from "../store/usePublicStore";
import BookSVG from "./icons/BookSVG";
import Pattern1 from "./icons/Pattern1";
import Pattern2 from "./icons/Pattern2";
import useDebounce from "../hooks/useDebounce";
import StoreIcon from "./icons/StoreIcon";
import StoreIcon2 from "./icons/StoreIcon2";
import { useAuthStore } from "../store/auth";
import { useNotificationStore } from "../store/notificatonStore";
import CallingIcon from "./icons/CallingIcon";
import CallingIcon2 from "./icons/CallingIcon2";

type Iprops = {
  title?: string;
  currentPageTitle: string;
  data?: string;
};
interface ISearchProps {
  batch_id: number;
  id: number;
  image: {
    id: number;
    thumb: string;
    large: string;
    medium: string;
    small: string;
  };
  slug: string;
  title: string;
}

interface IDataprops {
  body: string;
  id: number;
  img_url: string;
  title: string;
  published_at: Date;
}

const AppMetaHeader = ({ title, currentPageTitle }: Iprops) => {
  const { token } = useAuthStore();
  const { notification, readNotification } = useNotificationStore();
  // const { data, isLoading, load } = useApi<IDataprops[] | null>(
  //   "notifications?type=drawer"
  // );
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [searchData, setSearchData] = useState<ISearchProps[] | null>();
  const [selectedExam, setSelectedExam] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const [recentSearch, setRecentSearch] = useStorage<any>("RECENTSEARCH", []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setSearchData([]);
    setIsModalOpen(false);
  };

  const handleChange = async (query) => {
    if (!query) {
      return setSearchData([]), setIsSearched(false);
    }
    setLoading(true);

    const data = await api.get(`/search?q=${query}`);
    setSearchData(data?.data);
    setIsSearched(true);

    const isExist = recentSearch.some(
      (exam) => exam.toUpperCase() === query.toUpperCase()
    );

    if (!isExist) {
      setRecentSearch([...recentSearch, query]);
    }

    setLoading(false);
  };
  useDebounce(handleChange, query);
  // useMount(() => {
  //   load();
  // });

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const content =
    notification?.length > 0 ? (
      <NotificationContent data={notification} />
    ) : (
      "No New Notification"
    );
  const profile = <ProfileContent />;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const topSearch = ["UPSC", "NEET", "BPSC", "SSC"];

  const { publicData, active, setActive } = usePublicStore();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 426px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(max-width: 1025px)",
  });

  const isExtraLageScreen = useMediaQuery({
    query: "(max-width: 1441px)",
  });

  const isMedium = useMediaQuery({
    query: "(max-width: 576px)",
  });

  return (
    <>
      <Header>
        <Col xl={0} lg={0} md={0} sm={1} xs={1}>
          <Row justify="center">
            <MenuOutlined
              // size={10}
              style={{ color: "black", fontSize: "20px" }}
              onClick={() => setDrawerOpen(!drawerOpen)}
            />
            <Drawer
              width={"100%"}
              placement="left"
              onClose={() => setDrawerOpen(!drawerOpen)}
              open={drawerOpen}
              maskClosable={false}
              mask={false}
              title={
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    marginTop: "3%",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Image
                      height={"4em"}
                      width={"4em"}
                      style={{ paddingRight: "1px" }}
                      src={
                        "https://kgs.nyc3.cdn.digitaloceanspaces.com/uploads/hbC0ocuRD41Btvm2JRqK9gkjCxMPkH2YIXShLG5Q.png"
                      }
                      preview={false}
                    />
                  </div>
                  <div
                    style={{
                      color: "white",
                      fontSize: "1.6em",
                      paddingLeft: "2%",
                    }}
                  >
                    KGS
                  </div>
                </div>
              }
              style={{ background: "#2e308a" }}
              className="custom-drawer"
            >
              <SidebarMenu />
              <Card style={{ width: "260px", margin: "auto", marginTop: "2%" }}>
                <Row align={"middle"}>
                  <Col span={14}>
                    <p style={{ margin: "0", fontWeight: "700" }}>
                      Get Free academic Counseling & Course Details
                    </p>
                    <p
                      style={{
                        background: "#FF8057",
                        color: "white",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        padding: "3%",
                        borderRadius: "1.2em",
                        fontWeight: "700",
                        fontSize: "0.9em",
                        margin: "0%",
                        marginTop: "10%",
                      }}
                    >
                      <CallingIcon /> +91 8757354880
                    </p>
                  </Col>
                  <Col span={10}>
                    <CallingIcon2 />
                  </Col>
                </Row>
              </Card>
            </Drawer>
          </Row>
        </Col>
        <Col xl={8} lg={8} md={10} sm={0} xs={0}>
          {title}
          <h2 style={{ marginBottom: "0%" }}>{currentPageTitle}</h2>
        </Col>

        <Col
          xl={16}
          lg={16}
          md={14}
          sm={23}
          xs={23}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* <Row gutter={[8, 8]} align={"middle"}> */}
          <Col
            xl={6}
            lg={6}
            md={5}
            sm={6}
            // xs={isSmallScreen ? 9 : 6}
            offset={1}
          >
            <Input
              size="large"
              onClick={showDrawer}
              id="color123"
              // disabled={true}
              value={
                active?.name
                  ? active?.name
                  : publicData?.batches?.length > 0
                  ? publicData?.batches?.[0]?.name
                  : "All Course"
              }
              style={{
                backgroundColor: "#EAEEFD",
                color: "#2E3192",
                fontWeight: "500",
                border: "1px solid #2E3192",
                cursor: "pointer",
                outline: "none",
                caretColor: "transparent",
              }}
              suffix={
                <div onClick={showDrawer}>
                  <svg
                    width="11"
                    height="6"
                    viewBox="0 0 11 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.86575 5.84492L10.9752 0.162605C10.9887 0.147757 10.997 0.129913 10.9993 0.111122C11.0017 0.0923312 10.9978 0.073356 10.9883 0.0563753C10.9787 0.0393947 10.9639 0.0250976 10.9454 0.0151257C10.9269 0.00515376 10.9056 -8.85064e-05 10.8838 1.12537e-06L9.76206 1.07633e-06C9.69096 1.07323e-06 9.62275 0.0289926 9.57922 0.0768914L5.50006 4.61468L1.4209 0.0768911C1.37737 0.0277317 1.30916 7.06847e-07 1.23806 7.03739e-07L0.116326 6.54706e-07C0.0191002 6.50456e-07 -0.0345926 0.0970588 0.0249042 0.162605L5.13437 5.84492C5.17774 5.89322 5.23322 5.93229 5.29659 5.95916C5.35996 5.98603 5.42954 6 5.50006 6C5.57058 6 5.64016 5.98603 5.70353 5.95916C5.7669 5.93229 5.82238 5.89322 5.86575 5.84492Z"
                      fill="#2E3192"
                    />
                  </svg>
                </div>
              }
            />
          </Col>

          <Col
            xl={6}
            lg={5}
            md={4}
            sm={5}
            // xs={isSmallScreen ? 3 : 4}
            offset={1}
          >
            {isMedium ? (
              <div
                onClick={showModal}
                style={{
                  width: "2.5em",
                  height: "2.5em",
                  // background: "red",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: "0.5em",
                  border: "1px solid grey",
                  cursor: "pointer",
                }}
              >
                <SearchIcon />
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  border: "1px solid #CDCDCD",
                  borderRadius: "0.5em",
                  cursor: "pointer",
                  alignItems: "center",
                  display: "flex",
                  paddingLeft: "3%",
                  height: "3em",
                }}
                onClick={showModal}
              >
                <SearchIcon />{" "}
                <p
                  style={{
                    marginBottom: "0%",
                    marginLeft: "3%",
                    color: "#999999",
                    fontSize: isLargeScreen ? "0.8em" : "1.2em",
                  }}
                >
                  Search
                </p>
              </div>
            )}
          </Col>

          <Col
            xl={5}
            lg={6}
            md={7}
            sm={5}
            // xs={isSmallScreen ? 3 : 5}
            offset={1}
          >
            {isMedium ? (
              <a
                href={`https://store.khanglobalstudies.com?auth_token=${token}`}
                style={{ background: "yellow" }}
              >
                <div
                  style={{
                    width: "2.6em",
                    height: "2.5em",
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    borderRadius: "0.5em",
                  }}
                >
                  <StoreIcon />
                </div>
              </a>
            ) : (
              <a
                href={`https://store.khanglobalstudies.com?auth_token=${token}`}
                style={{ background: "yellow" }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "3em",
                    background: "#EAEEFD",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "1em",
                    padding: "2%",
                  }}
                >
                  <StoreIcon2 />
                  <span
                    style={{
                      fontSize: isMediumScreen
                        ? "0.9em"
                        : isLargeScreen
                        ? "0.8em"
                        : "1.1em",
                      marginLeft: "5%",
                      fontWeight: "600",
                      color: "#020887",
                      padding: "2%",
                    }}
                  >
                    KGS Store
                  </span>
                </div>
              </a>
            )}
          </Col>

          <Col
            xl={1}
            lg={1}
            md={1}
            sm={1}
            // xs={isSmallScreen ? 2 : 2}
            offset={1}
          >
            <Popover
              content={content}
              placement={isMobile ? "bottom" : "bottomRight"}
              arrow={false}
            >
              <div style={{ cursor: "pointer" }}>
                <Badge
                  count={notification?.length - readNotification?.length}
                  size="small"
                  style={{ fontSize: "0.7em" }}
                >
                  <NotificationIcon />
                </Badge>
              </div>
            </Popover>
          </Col>

          <Col
            xl={1}
            lg={1}
            md={2}
            sm={2}
            // xs={isSmallScreen ? 2 : 2}
            offset={1}
          >
            <Popover placement="topRight" content={profile} arrow={false}>
              <div
                style={{
                  background: "#EAEEFD",
                  width: "3em",
                  display: "flex",
                  justifyContent: "center",
                  height: "3em",
                  alignItems: "center",
                  borderRadius: "0.7em",
                  cursor: "pointer",
                }}
              >
                <img
                  src="https://static.vecteezy.com/system/resources/thumbnails/001/503/756/small/boy-face-avatar-cartoon-free-vector.jpg"
                  width={"100%"}
                  height={"100%"}
                  style={{
                    borderRadius: "100%",
                    width: "2.2em",
                    height: "2.2em",
                  }}
                  alt="Avatar-Img"
                />
              </div>
            </Popover>
          </Col>
          {/* </Row> */}
        </Col>
      </Header>

      <Drawer
        onClose={onClose}
        closable={false}
        open={open}
        style={{ backgroundColor: "#2E3192", color: "white" }}
        bodyStyle={{ padding: "0%" }}
        width={"30em"}
      >
        <div style={{ height: "17vh", width: "100%" }}>
          <Row>
            <Col span={15}>
              <div style={{ position: "absolute", top: "5em" }}>
                <Pattern1 />
              </div>
            </Col>
            <Col span={9}>
              <div
                style={{
                  fontSize: "1rem",
                  right: "2%",
                  top: "3%",
                  position: "absolute",
                }}
              >
                <CloseOutlined onClick={onClose} />
              </div>
              <Row justify={"end"}>
                <Pattern2 />
              </Row>

              <div style={{ position: "absolute", right: "20%", top: "3em" }}>
                <BookSVG />
              </div>
            </Col>
          </Row>
          <div>
            <p
              style={{
                zIndex: "1",
                marginTop: "-4.2em",
                paddingLeft: "6%",
                fontWeight: "700",
                fontSize: "1.6rem",
                marginBottom: "1%",
              }}
            >
              {active?.name ? active?.name : "Select Your Exam"}
            </p>

            <p style={{ width: "22em", paddingLeft: "6%" }}>
              {active?.short_info ? active?.short_info : ""}
            </p>
          </div>
        </div>
        <Card
          bodyStyle={{
            height: "82.5vh",
          }}
          style={{ borderRadius: "2em 2em 0em 0em" }}
          className="video-content-container"
        >
          {publicData?.batches?.map((item) => (
            <Card
              onClick={() => {
                setActive(item);
                window.location.reload();
                onClose();
              }}
              className="selectExam"
              bodyStyle={{
                padding: "1em 1em 1em 0.8em",

                border: item?.id === active?.id ? "0.15em solid #2E308A" : "",
                borderRadius: item?.id === active?.id ? "0.8em" : "",
              }}
            >
              <Meta
                avatar={
                  <Avatar
                    shape="circle"
                    size={55}
                    icon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "22%",
                        }}
                      >
                        <img src={item?.icon} width="28px" />
                      </div>
                    }
                    style={{ backgroundColor: item?.color }}
                  />
                }
                title={<DrawerTitle>{item?.name}</DrawerTitle>}
                description={
                  <p
                    style={{
                      marginTop: "-3%",
                      marginBottom: "0%",
                      lineHeight: "1.2",
                      marginLeft: "3%",
                    }}
                  >
                    {item?.short_info && `(${item?.short_info})`}
                  </p>
                }
              />
            </Card>
          ))}
        </Card>
      </Drawer>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={
          isSmallScreen || isMediumScreen || isLargeScreen || isExtraLageScreen
            ? "90%"
            : "50%"
        }
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <div style={{ height: "80vh", padding: "2%" }}>
          <div style={{ marginBottom: "2%" }}>
            <Input
              size="large"
              placeholder="Search here for courses"
              // allowClear
              prefix={<SearchIcon />}
              style={{ padding: "0.5em" }}
              id="course"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
            <div>
              Popular Searches: {isSmallScreen ? <br /> : ""}
              {topSearch?.map((item) => (
                <SearchButton
                  style={{ margin: "1%", marginTop: "2%" }}
                  onClick={() => {
                    handleChange(item.toUpperCase());
                    setQuery(item.toUpperCase());
                  }}
                >
                  {item}
                </SearchButton>
              ))}
            </div>
            <div>
              Recent Searches:{isSmallScreen ? <br /> : ""}
              {recentSearch?.length > 0
                ? recentSearch
                    ?.reverse()
                    ?.slice(0, 5)
                    ?.map((item) => (
                      <SearchButton
                        style={{ margin: "1%", cursor: "pointer" }}
                        onClick={() => {
                          handleChange(item.toUpperCase());
                          setQuery(item.toUpperCase());
                        }}
                      >
                        {item.toUpperCase()}
                      </SearchButton>
                    ))
                : " No Recent Searches"}
            </div>
          </div>

          <div
            style={{
              overflowY: "scroll",
              height: "70%",
            }}
            className="hideScrollbar"
          >
            <Shimmer type="search" loading={loading} count={4}>
              {isSearched ? (
                <Row gutter={[16, 8]}>
                  {searchData?.length ? (
                    searchData?.map((elem) => {
                      return (
                        <Col
                          key={elem?.id}
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          style={{ marginTop: 1 }}
                        >
                          <a
                            href={`https://khanglobalstudies.com/courses/${
                              elem.slug ? elem?.slug : elem?.id
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <HoverSearch size="small">
                              <Row>
                                <Col span={3}>
                                  <img
                                    src={
                                      (elem?.image?.small as string) ||
                                      "/kgs.jpg"
                                    }
                                    width="100%"
                                    alt="img"
                                    style={{ borderRadius: "0.5em" }}
                                  />
                                </Col>
                                <AnchorColor span={20} offset={1}>
                                  <p>{elem.title}</p>
                                </AnchorColor>
                              </Row>
                            </HoverSearch>
                          </a>
                        </Col>
                      );
                    })
                  ) : (
                    <EmptyResult
                      title={"Sorry No Data Found!"}
                      showDes={false}
                      status={"404"}
                    />
                  )}
                </Row>
              ) : (
                <EmptyResult
                  title={"You have not Search anything!"}
                  showDes={false}
                  // status={"404"}
                />
              )}
            </Shimmer>
          </div>
        </div>
      </Modal>
    </>
  );
};

const Header = styled(Row)`
  background-color: white;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0px 4px 12px 0px #00000014;
  height: 10vh;
`;

const DrawerTitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 0%;
  color: #2e308a;
  font-weight: 500;
  margin-left: 3%;
`;

const SearchButton = styled(Tag)`
  &:hover {
    background-color: #eaf0ff;
  }
`;

const HoverSearch = styled(Card)`
  &:hover {
    background-color: #eaf0ff;
    color: #2e3192;
  }
`;

const AnchorColor = styled(Col)`
  color: #6b6b6b;
  font-weight: 700;
  font-size: 1.1em;
  &:hover {
    color: #2e3192;
  }
`;
export default AppMetaHeader;
