export default function IncorrectIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0C7.16429 0 0 7.16429 0 16C0 24.8357 7.16429 32 16 32C24.8357 32 32 24.8357 32 16C32 7.16429 24.8357 0 16 0ZM21.9071 22.0786L19.55 22.0679L16 17.8357L12.4536 22.0643L10.0929 22.075C9.93571 22.075 9.80714 21.95 9.80714 21.7893C9.80714 21.7214 9.83214 21.6571 9.875 21.6036L14.5214 16.0679L9.875 10.5357C9.83184 10.4834 9.8079 10.4178 9.80714 10.35C9.80714 10.1929 9.93571 10.0643 10.0929 10.0643L12.4536 10.075L16 14.3071L19.5464 10.0786L21.9036 10.0679C22.0607 10.0679 22.1893 10.1929 22.1893 10.3536C22.1893 10.4214 22.1643 10.4857 22.1214 10.5393L17.4821 16.0714L22.125 21.6071C22.1679 21.6607 22.1929 21.725 22.1929 21.7929C22.1929 21.95 22.0643 22.0786 21.9071 22.0786Z"
        fill="#FF718B"
      />
    </svg>
  );
}
