import React, { useState } from "react";
import { Col, Row } from "antd";
import AskedDoubtCard from "./AskedDoubtCard";
import EmptyResult from "../../component/Empty";
import Loader from "../../component/loader";
import { useApi, useMount, useStorage } from "../../hooks";
import { IUser } from "../../interfaces/user.type";
import { IDoubt } from "../../interfaces";
import PaginationComp from "../../utils/PaginationComp";
import { useAuthStore } from "../../store/auth";
import { useMediaQuery } from "react-responsive";
import Shimmer from "../../component/Shimmer";
import useSize from "../../hooks/size";

type TDoubtRes = {
  data: IDoubt[];
  total: any;
  per_page: any;
};
const SQUAD_API = "https://squad.kgs.live/api/";

const AskedDoubts = () => {
  const { isS, isM, isL, isXL } = useSize();
  const { user } = useAuthStore();
  const [page, setPage] = useState(1);
  const { data, load, isLoading } = useApi<TDoubtRes>(
    `${SQUAD_API}doubts/${user?.phone}`
  );

  const [total, setTotal] = useState();
  const [perPage, setPerPage] = useState();

  useMount(() => {
    load({ page });
  }, [page]);

  useMount(() => {
    setTotal(data?.total);
    setPerPage(data?.per_page);
  }, [data]);

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 426px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(max-width: 1025px)",
  });

  const isExtraLageScreen = useMediaQuery({
    query: "(max-width: 1441px)",
  });

  return (
    <div
      style={{
        marginTop: isSmallScreen
          ? "4em"
          : isMediumScreen
          ? "4em"
          : isLargeScreen
          ? "4em"
          : isExtraLageScreen
          ? "4em"
          : "4em",
      }}
    >
      <Shimmer type="doubts" loading={isLoading} count={4}>
        <Row
          gutter={[15, 15]}
          style={{
            backgroundColor: "grey",
            width: "100%",
            background: "transparent",
            height: "68vh",
            overflowY: "scroll",
          }}
          className="video-content-container"
        >
          {data?.data?.length > 0 ? (
            data?.data.map((d) => (
              <Col key={d.id} lg={8} xl={8} md={24} sm={24} xs={24}>
                <AskedDoubtCard
                  title={d?.course_name}
                  type={d?.type}
                  query={d?.message}
                  asked_at={d?.created_at}
                  attachment={d?.attachment}
                  reply={d?.reply}
                  lesson={d?.lesson}
                  lecture={d?.lecture}
                />
              </Col>
            ))
          ) : (
            <EmptyResult
              title={"You have not asked any Doubt!"}
              status={"404"}
              showDes={false}
            />
          )}
        </Row>

        {data?.data?.length > 0 ? (
          <Row style={{ marginTop: "1%", float: "right" }}>
            <PaginationComp
              page={page}
              total={total}
              perPage={perPage}
              onChange={(page) => setPage(page)}
            />
          </Row>
        ) : (
          ""
        )}
      </Shimmer>
    </div>
  );
};

export default AskedDoubts;
