import { Col, Row, Tag } from "antd";
import React from "react";
import AskDoubt from "./AskDoubt";
import DoubtIcon from "../../component/icons/DoubtIcon";
import styled from "styled-components";
import CallButton from "../../component/icons/CallButton";

const DoubtCard = ({ url, lesson, cId }) => {
  return (
    <Row gutter={24}>
      <Col xl={12} lg={12} md={0} sm={0} xs={0}>
        <div
          style={{
            textAlign: "center",
            paddingTop: "5%",
          }}
        >
          <Title>Get Your Doubts Cleared!</Title>
          <DoubtIcon />
        </div>
        <div style={{ paddingLeft: "3%" }}>
          <ContactTitle>Reach out to us</ContactTitle>
          <SubTitle>Get your question Answered</SubTitle>
          <ContactButton>
            <CallButton /> +91 8757354880
          </ContactButton>
        </div>
      </Col>
      <Col xl={12} lg={12} md={24} sm={24} xs={24} style={{ paddingTop: "2%" }}>
        <AskDoubt url={url} lesson={lesson} cId={cId} />
      </Col>
    </Row>
  );
};

const Title = styled.p`
  margin-bottom: 0%;
  font-weight: 500;
  font-size: 1.5em;
`;

const ContactTitle = styled.p`
  font-weight: 600;
  font-size: 1.3em;
  margin-bottom: 0%;
`;

const SubTitle = styled.p`
  font-size: 1em;
  font-weight: 500;
`;

const ContactButton = styled(Tag)`
  display: flex;
  background-color: #e8e9ff;
  color: black;
  border: none;
  border-radius: 1.2em;
  font-size: 1.2em;
  font-weight: 700;
  align-item: center;
  width: 10em;
  padding: 1.5%;
  justify-content: space-between;
`;

export default DoubtCard;
