import React, { useEffect, useRef } from "react";
import isMobile from "../../utils/isMobile";
import { Timeline } from "antd";

export default function TimeLine({
  url,
  controls,
  playing,
  togglePlayer,
  onScrub,
  onChange,
}) {
  return url?.includes("m3u8") ? (
    <HLSTimeLine
      url={url}
      controls={controls}
      playing={playing}
      togglePlayer={togglePlayer}
      onScrub={onScrub}
      onChange={onChange}
    />
  ) : (
    <YTTimeLine
      url={url}
      controls={controls}
      playing={playing}
      togglePlayer={togglePlayer}
      onScrub={onScrub}
      onChange={onChange}
    />
  );
}

export function HLSTimeLine(props) {
  const { url, controls, playing, togglePlayer, onScrub, onChange } = props;
  const timeLine = React.useRef();
  const [touch, setTouch] = React.useState(false);
  const [initiate, setInitiate] = React.useState(
    controls?.isLive ? true : false
  );

  const [isScrubbing, setIsScrubbing] = React.useState(false);
  let interval;
  let touchInterval;

  React.useEffect(() => {
    if (playing) {
      if (!isScrubbing) {
        startSeeking();
      }
    } else {
      stopSeeking();
    }
  }, [playing]);

  const handleDrag = (e) => {
    const rect = timeLine?.current.getBoundingClientRect();

    const percent =
      Math.min(Math.max(0, e?.touches[0]?.clientX - rect.x), rect.width) /
      rect.width;

    percent &&
      timeLine?.current.style.setProperty("--progress-position", percent);
    percent && controls.seekTo(percent * 100);
  };

  const handleTimelineUpdate = (e) => {
    const rect = timeLine?.current.getBoundingClientRect();

    const percent =
      Math.min(Math.max(0, e.x - rect.x), rect.width) / rect.width;
    // timeLine?.current?.style?.setProperty('--preview-progress', percent);
    setIsScrubbing((scrubbing) => {
      if (scrubbing) {
        e.preventDefault();
        stopSeeking();
        timeLine?.current.style.setProperty("--progress-position", percent);
      }
      return scrubbing;
    });
  };

  const handlePress = (e, noSeek = true) => {
    const rect = timeLine?.current.getBoundingClientRect();
    const percent =
      Math.min(Math.max(0, e?.touches[0]?.clientX - rect.x), rect.width) /
      rect.width;
    stopSeeking();

    percent &&
      timeLine?.current?.style?.setProperty("--progress-position", percent);
    onChange(percent);
    percent && !touch && controls.seekTo(percent * 100);
  };

  const toggleScrubbing = (e) => {
    const rect = timeLine?.current.getBoundingClientRect();
    stopSeeking();
    const percent =
      Math.min(Math.max(0, e.x - rect.x), rect.width) / rect.width;
    timeLine?.current?.style?.setProperty("--progress-position", percent);
    onChange(percent);
    const isScrubbing = (e.buttons & 1) == 1;
    if (isScrubbing) {
      stopSeeking();
      setIsScrubbing(true);
      controls.pause(false);
      // controls.seekTo(percent * 100);
    } else {
      setIsScrubbing(false);
      controls.seekTo(percent * 100);
      if (!playing) {
        controls.play(true);
      }
    }
  };

  const startSeeking = () => {
    if (playing) {
      if (initiate) {
        timeLine?.current?.style.setProperty("--progress-position", 100);

        setInitiate(false);
        return;
      }
      interval = setInterval(() => {
        const percentage = controls.Progress;

        setIsScrubbing((scrub) => {
          if (!scrub) {
            timeLine?.current?.style.setProperty(
              "--progress-position",
              percentage / 100
            );
          }
          return scrub;
        });
      }, 100);
    }
  };
  const stopSeeking = () => {
    clearInterval(interval);
  };

  React.useEffect(() => {
    if (isMobile()) {
      timeLine?.current?.addEventListener("touchstart", (e) => {
        handlePress(e);
      });

      timeLine?.current?.addEventListener("touchmove", (e) => {
        setTouch(true);
        controls.pause();
        stopSeeking();
        handleDrag(e);
      });

      timeLine?.current?.addEventListener("touchend", (e) => {
        setTouch(false);
        setTouch((t) => {
          if (t) {
            clearTimeout(t);
          } else {
            touchInterval = setTimeout(() => {
              controls.play();
            }, 1000);
          }

          return t;
        });
      });

      return;
    }

    if (timeLine) {
      timeLine?.current?.addEventListener("mousemove", (e) => {
        handleTimelineUpdate(e);
      });

      timeLine?.current?.addEventListener("mousedown", toggleScrubbing);

      document?.addEventListener("mouseup", (e) => {
        setIsScrubbing((scrub) => {
          if (scrub) {
            controls.play();
            toggleScrubbing(e);
          }
          // return scrub;
        });
      });

      document?.addEventListener("mouseleave", (e) => {
        setIsScrubbing((scrub) => {
          if (scrub) {
            togglePlayer(true);
            toggleScrubbing(e);
          }
          // return scrub;
        });
      });

      document.addEventListener("mousemove", (e) => {
        setIsScrubbing((scrub) => {
          if (scrub) {
            handleTimelineUpdate(e);
          }
          return scrub;
        });
      });
    }
  }, []);
  return (
    <div id="timeline" className="timeline-container" ref={timeLine}>
      <div className="timeline">
        <div className="thumb-indicator"></div>
      </div>
    </div>
  );
}

export function YTTimeLine(props) {
  const { url, controls, playing, togglePlayer, onScrub, onChange } = props;
  const timeLine = React.useRef<HTMLDivElement | null>(null);
  const scrubbing = useRef(false);
  const [initiate, setInitiate] = React.useState(
    controls?.isLive ? true : false
  );
  const touchInterval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    timeLine?.current?.style?.setProperty("--progress-position", "0");
  }, [url]);

  React.useEffect(() => {
    playing && !scrubbing.current && startSeeking();
  }, [playing]);

  React.useEffect(() => {
    attachListener(true);
    return () => {
      attachListener(false);
    };
  }, []);

  const startSeeking = () => {
    if (initiate) {
      timeLine?.current?.style.setProperty("--progress-position", "100");
      return setInitiate(false);
    }
  };

  function attachListener(attach: boolean) {
    if (!timeLine.current) return;
    const listener = attach ? "addEventListener" : "removeEventListener";
    if (isMobile()) {
      timeLine?.current[listener]("touchstart", handleTouchStart);
      timeLine?.current[listener]("touchmove", handleTouchMove);
      timeLine?.current[listener]("touchend", handleTouchEnd);
      return;
    }
    timeLine?.current[listener]("mousemove", handleMouseMove);
    timeLine?.current[listener]("mousedown", handleMouseDown);
    document[listener]("mouseup", handleScrubbing);
    document[listener]("mouseleave", handleScrubbing);
    document[listener]("mousemove", handleScrubbing);
  }

  function updateTimeLine(x: number, timeLine): number {
    const rect = timeLine!.current!.getBoundingClientRect();
    const percent = Math.min(Math.max(0, x - rect.x), rect.width) / rect.width;
    timeLine?.current?.style?.setProperty(
      "--progress-position",
      String(percent)
    );
    onChange(percent);
    controls.seekTo(percent * 100);
    return percent;
  }
  function handleTouchMove(event: TouchEvent) {
    updateTimeLine(event?.touches[0]?.clientX, timeLine);
  }
  function handleTouchStart(event: TouchEvent) {
    updateTimeLine(event?.touches[0]?.clientX, timeLine);
  }
  function handleTouchEnd(event: TouchEvent) {
    touchInterval.current = setTimeout(() => {
      togglePlayer(true);
    }, 1000);
  }

  function handleScrubbing(event: MouseEvent) {
    if (scrubbing.current) handleMouseDown(event);
  }
  function handleMouseMove(event: MouseEvent) {
    // handleTimelineUpdate(event.nativeEvent);
  }
  function handleMouseDown(event: MouseEvent) {
    if (!timeLine.current) return;

    updateTimeLine(event.x, timeLine);
    const pressed = (event.buttons & 1) === 1;
    if (pressed) {
      scrubbing.current = true;
      return;
    }
    scrubbing.current = false;
  }

  return (
    <div id="timeline" className="timeline-container" ref={timeLine}>
      <div className="timeline">
        <div className="thumb-indicator"></div>
      </div>
    </div>
  );
}
