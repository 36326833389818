import moment from "moment";
import api from "../config/api";
import { useQueryClient } from "@tanstack/react-query";
import parse from "html-react-parser";
import showNotification from "../utils/notify";

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const dateParse = (str) => {
  return moment(parse(str)).format("DD-MMM-YYYY");
};

export const onlyDateParse = (str, isHtml = false) => {
  if (isHtml) {
    return moment(parse(str)).format("YYYY-MM-DD");
  }
  return moment(str).format("YYYY-MM-DD");
};

export const convertToSlug = (Text, append = "") => {
  var str = Text.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
  if (!append) {
    return str;
  }
  return str + "-" + append;
};

export const ConvertToSlug = (Text) => {
  return Text.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const getRandomNum = (max) => {
  return Math.floor(Math.random() * max) + 1;
};

export const formatDate = (publish_at) => {
  const p = moment(publish_at, "YYYY-MM-DDTHH:mm:ss").fromNow();
  return p;
};

export const formatDateTime = (publish_at) => {
  const p = moment(publish_at, "YYYY-MM-DDTHH:mm:ss").format(
    "DD-MMM-YY HH:mm A"
  );
  return p;
};

export const formatDateOnly = (publish_at) => {
  const p = moment(publish_at, "YYYY-MM-DDTHH:mm:ss").format("DD-MMMM-YYYY");
  return p;
};

export const formatDateTimeAMPM = (publish_at) => {
  const p = moment(publish_at, "YYYY-MM-DDTHH:mm:ss").format(
    "DD-MMM-YYYY HH:MM A"
  );
  return p;
};

export const formatHourAndMinutes = (duration) => {
  const minutes = duration % 60;
  const hours = Math.floor(duration / 60);

  return `${hours}:${minutes}`;
};

export const getCourseCatType = (cat_id) => {
  if (!cat_id) return "NA";
  if (cat_id == 1) return "Live + Recorded";
  if (cat_id == 2) return "Live";
  if (cat_id == 3) return "Recorded";
  if (cat_id == 4) return "Offline";
  if (cat_id == 5) return "Offline + Online";
};

export const getLanguageType = (lang) => {
  if (lang === 0) return "Hindi";
  if (lang === 1) return "English";
  if (lang === 0) return "Bilingual";
};

export const ytVideoIDFromUrl = (url) => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};

export function inArray(needle, haystack) {
  var length = haystack.length;
  for (var i = 0; i < length; i++) {
    if (haystack[i] == needle) return true;
  }
  return false;
  //   return match && match[7].length == 11 ? match[7] : false;
}

export const isFormValid = (data) => {
  const isValid = Object.values(data).every((item) => item != "");
  return isValid;
};

export function getTaxDetail(amount) {
  let total = amount;
  if (total === 0) {
    total = 1;
  }
  if (total <= 0) {
    return { tax: 0, amount: total, total: total };
  }

  const taxRate = 118;
  const price = (total / taxRate) * 100;
  const tax = price * 0.18;

  return {
    tax: parseFloat(tax.toFixed(2)),
    amount: parseFloat(price.toFixed(2)),
    total: total,
  };
}

export const AnswerWritingTitle = (batch) => {
  if (batch == 1) return "UPSC Answer Writing";
  if (batch == 2) return "BPSC Answer Writing";
  if (batch == 3) return "UP-PSC Answer Writing";
  if (batch == 4) return "MP-PSC Answer Writing";
};
export const AnswerWritingSlug = (batch) => {
  if (batch == 1) return "upsc-mains-answer-writing";
  if (batch == 2) return "bpsc-mains-answer-writing";
  if (batch == 3) return "uppsc-mains-answer-writing";
  if (batch == 4) return "mppsc-mains-answer-writing";
};

export const AnswerWritingThumb = (batch) => {
  if (batch === 1) return "/images/india.svg";
  if (batch === 2) return "/images/india.svg";
  if (batch === 3) return "/images/india.svg";
  if (batch === 4) return "/images/india.svg";
};

export const UpcomingClasses = async (queryClient, navigate) => {
  const classesData = queryClient.getQueryData(["allClasses"]);
  if (!classesData) {
    queryClient.prefetchQuery(["allClasses"], async () => {
      const { data } = await api.get("lives");
      return data;
    });
  }
  navigate({ to: "/live" });
};

export const PurchaseCourses = async (queryClient, navigate) => {
  const purchaseData = queryClient.getQueryData(["purchaseCourses"]);
  if (!purchaseData) {
    queryClient.prefetchQuery(["purchaseCourses"], async () => {
      const { data } = await api.get("user/v2/courses");
      return data;
    });
  }
  navigate({ to: "/courses" });
};

export const fetchTestSeries = async (queryClient, navigate) => {
  const quizData = queryClient.getQueryData(["testSeries"]);
  if (!quizData) {
    queryClient.prefetchQuery(["testSeries"], async () => {
      const { data } = await api.get(`user/test-series`);
      return data;
    });
  }
  navigate({ to: "/quizzes" });
};

export const fetchUser = async (queryClient, navigate) => {
  const user = queryClient.getQueryData(["user"]);
  if (!user) {
    queryClient.prefetchQuery(["user"], async () => {
      const { data } = await api.get(`user`);
      return data;
    });
  }
  navigate({ to: "/profile" });
};

export const onCopy = (event) => {
  event.preventDefault();
  showNotification("warning", "Copy is not allowed!");
};

export const newDateParse = (date: Date) => {
  return moment(date).format('Do MMM YYYY, h:mm A')
}