export default function PDFIcon() {
  return (
    <svg
      width="31"
      height="38"
      viewBox="0 0 31 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.184 38H1.216C0.544422 38 0 37.4556 0 36.784V1.216C0 0.544422 0.544422 0 1.216 0H22.002V6.92664C22.002 7.73925 22.6607 8.398 23.4734 8.398H30.4V36.784C30.4 37.4556 29.8556 38 29.184 38ZM5.47899 24.624H7.02392V22.5173H8.19766C9.77269 22.5173 11.0367 21.6746 11.0367 20.0494V20.0293C11.0367 18.5948 10.0235 17.6016 8.34814 17.6016H5.47899V24.624ZM7.02392 21.1429V18.996H8.21773C8.99019 18.996 9.47173 19.3672 9.47173 20.0594V20.0795C9.47173 20.6814 9.02029 21.1429 8.24782 21.1429H7.02392ZM12.1804 24.624H14.9191C17.1261 24.624 18.651 23.0891 18.651 21.1128V21.0927C18.651 19.1164 17.1261 17.6016 14.9191 17.6016H12.1804V24.624ZM14.9191 18.996C16.1831 18.996 17.0359 19.8688 17.0359 21.1128V21.1329C17.0359 22.3768 16.1831 23.2296 14.9191 23.2296H13.7253V18.996H14.9191ZM20.0254 24.624H21.5703V21.9053H24.921V20.5008H21.5703V19.0061H25.3724V17.6016H20.0254V24.624ZM22.8 6.384V0L30.4 7.6H24.016C23.3444 7.6 22.8 7.05558 22.8 6.384Z"
        fill="#2E3192"
      />
    </svg>
  );
}
