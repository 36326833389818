import React from "react";
import { Row, Col, Card, Tabs, Collapse } from "antd";
import QCurriculam from "./qCurriculam";
import parse from "html-react-parser";
import { useParams } from "@tanstack/react-router";
import PageContainer from "../../component/Layout/PageContainer";
import AppMetaHeader from "../../component/AppMetaHeader";
import { useApi, useMount } from "../../hooks";
import FAQ from "./FAQ";
import OverView from "./OverView";
import Activities from "./Activities";
import AdmitCard from "./AdmitCard";
import PdfSolution from "./PdfSolution";
import Resources from "./Resources";
import styled from "styled-components";
import VideoSolution from "./VideoSolution";
import { useMediaQuery } from "react-responsive";

const QuizzesType = () => {
  const [activeKey, setActiveKey] = React.useState("1");
  const [isClicked, setIsClicked] = React.useState(false);
  const handleTabChange = (key: any) => {
    setActiveKey(key);
    setIsClicked(true);
  };

  const { quizzesType }: { quizzesType: string } = useParams({ from: "" });
  const {
    data: testSeries,
    isLoading,
    load,
  } = useApi<any>(`test-series/${quizzesType}`);

  useMount(() => {
    load();
  }, [quizzesType]);

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 426px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(max-width: 1025px)",
  });

  const isExtraLageScreen = useMediaQuery({
    query: "(max-width: 1441px)",
  });

  const OptionalTab = {
    key: "4",
    label: (
      <span
        className={activeKey === "4" ? (!isClicked ? "tabs_1" : "tabs_1") : ""}
      >
        Admit Card
      </span>
    ),
    children: (
      <Container>
        <Title>Admit Card</Title>
        <BodyContainer>
          <AdmitCard
            id={testSeries?.id}
            catId={testSeries?.category_id}
            center={testSeries?.centers}
          />
        </BodyContainer>
      </Container>
    ),
    // )
  };

  let items = [
    {
      key: "1",
      label: (
        <span
          className={
            activeKey === "1" ? (!isClicked ? "tabs_1" : "tabs_1") : ""
          }
        >
          Schedule
        </span>
      ),
      children: (
        <Container>
          <Title>Schedule</Title>
          <BodyContainer>
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <QCurriculam
                  quizzes={testSeries?.quizzes!}
                  quizzesType={quizzesType}
                />
              </Col>
            </Row>
          </BodyContainer>
        </Container>
      ),
    },

    {
      key: "3",
      label: (
        <span
          className={
            activeKey === "3" ? (!isClicked ? "tabs_1" : "tabs_1") : ""
          }
        >
          Activities
        </span>
      ),
      children: (
        <Container>
          <Title>Activities</Title>
          <BodyContainer>
            <Activities id={testSeries?.id} />
          </BodyContainer>
        </Container>
      ),
    },
    {
      key: "5",
      label: (
        <span
          className={
            activeKey === "5" ? (!isClicked ? "tabs_1" : "tabs_1") : ""
          }
        >
          PDF Solutions
        </span>
      ),
      children: (
        <Container>
          <Title>PDF Solutions</Title>
          <BodyContainer>
            <PdfSolution id={testSeries?.id} />
          </BodyContainer>
        </Container>
      ),
    },
    {
      key: "6",
      label: (
        <span
          className={
            activeKey === "6" ? (!isClicked ? "tabs_1" : "tabs_1") : ""
          }
        >
          Video Solutions
        </span>
      ),
      children: (
        <Container>
          <Title>Video Solutions</Title>
          <BodyContainer>
            <VideoSolution id={testSeries?.id} />
          </BodyContainer>
        </Container>
      ),
    },
    {
      key: "7",
      label: (
        <span
          className={
            activeKey === "7" ? (!isClicked ? "tabs_1" : "tabs_1") : ""
          }
        >
          Resources
        </span>
      ),
      children: (
        <Container>
          <Title>Resources</Title>
          <BodyContainer>
            <Resources resources={testSeries?.details?.pdfs} />
          </BodyContainer>
        </Container>
      ),
    },
    {
      key: "8",
      label: (
        <span className={activeKey === "8" ? (isClicked ? "tabs_1" : "") : ""}>
          FAQs
        </span>
      ),
      children: (
        <Container>
          <Title>FAQs</Title>
          <BodyContainer>
            <FAQ testSeries={testSeries} />
          </BodyContainer>
        </Container>
      ),
    },
    {
      key: "9",
      label: (
        <span className={activeKey === "9" ? (isClicked ? "tabs_1" : "") : ""}>
          Overview
        </span>
      ),
      children: (
        <Container>
          <Title>Overview</Title>
          <BodyContainer>
            <OverView testSeries={testSeries} />
          </BodyContainer>
        </Container>
      ),
    },
  ];

  if (testSeries?.category_id !== 1) {
    const index = 3;
    items = [...items.slice(0, index), OptionalTab, ...items.slice(index)];
  }

  return (
    <>
      <AppMetaHeader currentPageTitle="My Test Series" />
      {testSeries ? (
        <PageContainer
          currentPageTitle={testSeries?.title}
          title="Test Series"
          url="/quizzes"
          loading={isLoading}
          transparent={true}
        >
          <Tabs
            tabBarStyle={{
              width: "100%",
              paddingBottom: "0.5%",
              position: "fixed",
              zIndex: "1",
              backgroundColor: "#EDF4FF",
            }}
            items={items}
            defaultActiveKey="1"
            activeKey={activeKey}
            onChange={handleTabChange}
            size="small"
            scroll={{ y: 500, x: 500 }}
          />
        </PageContainer>
      ) : (
        <></>
      )}
    </>
  );
};

const Container = styled.div`
  background: white;
  border-radius: 0.5em;
  margin-top: ${({
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isExtraLargeScreen,
  }) =>
    isSmallScreen || isMediumScreen || isLargeScreen || isExtraLargeScreen
      ? "4em"
      : "4em"};
`;

const Title = styled.h2`
  font-weight: 600;
  background: #eaf0ff;
  padding: 1% 1% 1% 2%;
  border-radius: 0.3em 0.3em 0em 0em;
`;

const BodyContainer = styled.div`
  padding: 3%;
`;

export default QuizzesType;
