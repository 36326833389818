import React, { useContext } from 'react'
import { createContext, useState } from 'react';

export const NavbarContext = createContext(null)

export const NavbarStatus = ({ children }) => {

  const [showNavbar, setShowNavbar] = useState(false)
  
  const updateNavbarStatus = () => {
    setShowNavbar(!showNavbar)
  }

  return (
    <NavbarContext.Provider value={{ showNavbar, updateNavbarStatus }}>
      {children}
    </NavbarContext.Provider>
  )
}

export default NavbarContext;
export const useNavbarStatus = () => useContext(NavbarContext);