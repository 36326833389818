import { Avatar, Card } from "antd";
import Meta from "antd/es/card/Meta";
import React from "react";

interface IProps {
  title: string;
  avatar: any;
}

export default function DownloadCard(props: IProps) {
  return (
    <Card className={"card-active"}>
      <Meta
        avatar={
          <Avatar
            src={
              <div
                style={{
                  textAlign: "center",
                  marginTop: "15%",
                }}
              >
                {props?.avatar}
              </div>
            }
            style={{
              backgroundColor: "#CBEDFF",
            }}
            size={50}
          />
        }
        title={
          <div
            style={{
              marginLeft: "5%",
              whiteSpace: "break-spaces",
            }}
            className="card-content2"
          >
            {props?.title}
          </div>
        }
      />
    </Card>
  );
}
