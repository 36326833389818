import React, { useEffect } from "react";
import AppMetaHeader from "../../component/AppMetaHeader";
import PageContainer from "../../component/Layout/PageContainer";
import { Col, Row } from "antd";
import { usePublicStore } from "../../store/usePublicStore";
import { Link } from "@tanstack/react-router";
import DownloadCard from "./DownloadCard";
import PyqIcon from "../../component/icons/PyqIcon";
import NcertIcon from "../../component/icons/NcertIcon";
import CaIcon from "../../component/icons/CaIcon";
import OtherIcon from "../../component/icons/OtherIcon";
import Infograph from "../../component/icons/Infograph";
import MindMapIcon from "../../component/icons/MindMap";

const DownloadListing = () => {
  const { active } = usePublicStore();
  const resources = active?.config?.resources;

  useEffect(() => {
    document.title = "Study Materials | Khan Global Studies";
  }, []);

  const items = [
    "NCERT_BOOK",
    "PYQ_QUESTIONS",
    "CURRENT_AFFAIRS_PDF",
    "MIND_MAPS",
    "INFOGRAPHICS",
    "OTHERS",
  ];

  const filteredItems = resources?.filter(
    (item) => !item.includes("AW") && !item.includes("NEWS")
  );

  return (
    <>
      <AppMetaHeader currentPageTitle="Study Materials" />
      <PageContainer currentPageTitle="Study Materials" transparent={true}>
        <Row gutter={[15, 15]}>
          {(resources === undefined ? items : filteredItems).map(
            (item, index) => (
              <Col xxl={6} xl={6} lg={8} md={12} sm={12} xs={24} key={index}>
                <Link
                  to={
                    item === "NCERT_BOOK"
                      ? "/study-material/ncert-books"
                      : item === "PYQ_QUESTIONS"
                      ? "/study-material/pyq"
                      : item === "CURRENT_AFFAIRS_PDF"
                      ? "/study-material/current-affairs-mcq-pdf"
                      : item === "MIND_MAPS"
                      ? "/study-material/mind-maps"
                      : item === "INFOGRAPHICS"
                      ? "/study-material/infographics"
                      : item === "OTHERS"
                      ? "/study-material/others"
                      : ""
                  }
                >
                  <DownloadCard
                    title={
                      item === "NCERT_BOOK"
                        ? "Ncert Books"
                        : item === "PYQ_QUESTIONS"
                        ? "Previous Year Questions"
                        : item === "CURRENT_AFFAIRS_PDF"
                        ? "Current Affairs MCQ PDF"
                        : item === "MIND_MAPS"
                        ? "Mind Maps"
                        : item === "INFOGRAPHICS"
                        ? "Infographics"
                        : item === "OTHERS"
                        ? "Others"
                        : ""
                    }
                    avatar={
                      item === "NCERT_BOOK" ? (
                        <NcertIcon />
                      ) : item === "PYQ_QUESTIONS" ? (
                        <PyqIcon />
                      ) : item === "CURRENT_AFFAIRS_PDF" ? (
                        <CaIcon />
                      ) : item === "MIND_MAPS" ? (
                        <MindMapIcon />
                      ) : item === "INFOGRAPHICS" ? (
                        <Infograph />
                      ) : item === "OTHERS" ? (
                        <OtherIcon />
                      ) : (
                        ""
                      )
                    }
                  />
                </Link>
              </Col>
            )
          )}
        </Row>
      </PageContainer>
    </>
  );
};

export default DownloadListing;
