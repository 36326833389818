export default function () {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4625 3.92417L10.845 1.45208C9.855 0.517083 8.535 0 7.1325 0H3.75C1.68 0 0 1.58667 0 3.54167V13.4583C0 15.4133 1.68 17 3.75 17H11.25C13.32 17 15 15.4133 15 13.4583V7.43042C15 6.10583 14.4525 4.85917 13.4625 3.92417ZM12.405 4.92292C12.645 5.14958 12.8475 5.3975 13.0125 5.66667H9.7575C9.345 5.66667 9.0075 5.34792 9.0075 4.95833V1.88417C9.2925 2.04 9.555 2.23125 9.795 2.45792L12.4125 4.93L12.405 4.92292ZM13.5 13.4583C13.5 14.6271 12.4875 15.5833 11.25 15.5833H3.75C2.5125 15.5833 1.5 14.6271 1.5 13.4583V3.54167C1.5 2.37292 2.5125 1.41667 3.75 1.41667H7.1325C7.2525 1.41667 7.38 1.41667 7.5 1.43083V4.95833C7.5 6.12708 8.5125 7.08333 9.75 7.08333H13.485C13.5 7.19667 13.5 7.31 13.5 7.43042V13.4583ZM3.8175 9.20833H3C2.5875 9.20833 2.25 9.52708 2.25 9.91667V13.0617C2.25 13.3096 2.46 13.5008 2.715 13.5008C2.97 13.5008 3.18 13.3025 3.18 13.0617V12.1975H3.81C4.695 12.1975 5.415 11.5246 5.415 10.7029C5.415 9.88125 4.695 9.20833 3.81 9.20833H3.8175ZM3.8175 11.3121H3.195V10.0938H3.825C4.185 10.0938 4.4925 10.37 4.4925 10.7029C4.4925 11.0358 4.185 11.3121 3.825 11.3121H3.8175ZM12.765 9.65458C12.765 9.9025 12.555 10.0938 12.3 10.0938H11.0325V10.9012H11.9625C12.225 10.9012 12.4275 11.0996 12.4275 11.3404C12.4275 11.5813 12.2175 11.7796 11.9625 11.7796H11.0325V13.0546C11.0325 13.3025 10.8225 13.4937 10.5675 13.4937C10.3125 13.4937 10.1025 13.2954 10.1025 13.0546V9.6475C10.1025 9.39958 10.3125 9.20833 10.5675 9.20833H12.3C12.5625 9.20833 12.765 9.40667 12.765 9.6475V9.65458ZM7.5675 9.21542H6.75C6.3375 9.21542 6 9.53417 6 9.92375V13.0688C6 13.3167 6.21 13.4654 6.465 13.4654C6.72 13.4654 7.56 13.4654 7.56 13.4654C8.445 13.4654 9.165 12.7925 9.165 11.9708V10.71C9.165 9.88833 8.445 9.21542 7.56 9.21542H7.5675ZM8.235 11.9708C8.235 12.3037 7.9275 12.58 7.5675 12.58H6.945V10.1008H7.575C7.935 10.1008 8.2425 10.3771 8.2425 10.71V11.9708H8.235Z"
        fill="#353297"
      />
    </svg>
  );
}
