import { useState, useCallback, } from "react";
import { AxiosError } from "axios";
import { message } from "antd";
import api from "../config/api";
import { usePublicStore } from "../store/usePublicStore";

export interface ApiResponse<T> {
  result?: T | undefined;
  error?: Error | undefined;
  isFetching: boolean;
}

type TUseApiResponse<T> = {
  load: (...args: any[]) => Promise<void>;
  data: T | undefined;
  error: AxiosError | unknown;
  isLoading: boolean;
  updateData:React.Dispatch<React.SetStateAction<T | undefined>>
};

export default function useApi<T>(url?:string,initialValue?:T|undefined):TUseApiResponse<T>{
  const [data, setData] = useState<T|undefined>(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const {active} = usePublicStore()
  const [error, setError] = useState<
    AxiosError<unknown, any> | undefined | unknown
  >(undefined);
    const handleMount = useCallback(
          async (params:any) => {
            setError(undefined);
            setIsLoading(true);
            try {
              if(params?.uri){
                url=params.uri
                delete params.uri
              }
              
              const result = await api.get(url||params.uri,{params:{...params,batch_id:active.id}});
              setData(result.data);
            } catch (error:any) {
              setError(error)
              // message.error(error?.message)
            }
            setIsLoading(false);
          },
          [url,active.id]
        );

  return {load:handleMount,data,isLoading,error,updateData:setData}
}