import { Col } from "antd";
import React from "react";
import styled from "styled-components";

export default function AppSwitch({
  title,
  value,
  onValueChange,
  onLabel = "",
  offLabel = "",
}) {
  return (
    <div>
      <Title>{title}</Title>
      <div className="switchCourses">
        <input
          id="language-toggle"
          value={value}
          checked={value}
          className="check-toggle check-toggle-round-flat"
          type="checkbox"
          name="language"
          onChange={onValueChange}
        />
        <label for="language-toggle"></label>
        <span className="on">{onLabel}</span>
        <span className="off">{offLabel}</span>
      </div>
    </div>
  );
}

const Title = styled.p`
  color: #8f92a1;
  margin-bottom: 0px;
  font-size: 0.9em;
`;
