import React from "react";
import { Row, Col, Tooltip } from "antd";
import useQuizStore from "../../../store/quizStore";
import { Link } from "@tanstack/react-router";
import { useMediaQuery } from "react-responsive";

const Header = () => {
  const quiz = useQuizStore((state) => state.quiz);
  const title = quiz?.title;

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return isMobile ? (
    <div
      style={{
        height: "10vh",
        background: "white",
        boxShadow: "0px 4px 4px 0px #00000040",
        width: "100%",
        borderRadius: "0.5em 0.5em 0em 0em",
        display: "flex",
        alignItems: "center",
        padding: "0px 10px",
      }}
    >
      <Tooltip title="Go to Dashboard" arrow={false} placement="top">
        <Link to="/home">
          <svg
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="33" height="33" rx="5" fill="#F5F5F5" />
            <g opacity="0.8">
              <path
                d="M21.1887 22.5589L14.8912 16.5001L21.1887 10.4413L19.25 8.58008L11 16.5001L19.25 24.4201L21.1887 22.5589Z"
                fill="#202224"
              />
            </g>
          </svg>
        </Link>
      </Tooltip>

      <img src="/images/kgs.png" alt="logo" width="30px" />

      <h3>{title}</h3>
    </div>
  ) : (
    <Row
      align="middle"
      style={{
        height: "10vh",
        background: "white",
        boxShadow: "0px 4px 4px 0px #00000040",
        width: "100%",
        borderRadius: "0.5em 0.5em 0em 0em",
      }}
    >
      <Col span={1} offset={1}>
        <Tooltip title="Go to Dashboard" arrow={false} placement="top">
          <Link to="/home">
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="33" height="33" rx="5" fill="#F5F5F5" />
              <g opacity="0.8">
                <path
                  d="M21.1887 22.5589L14.8912 16.5001L21.1887 10.4413L19.25 8.58008L11 16.5001L19.25 24.4201L21.1887 22.5589Z"
                  fill="#202224"
                />
              </g>
            </svg>
          </Link>
        </Tooltip>
      </Col>
      <Col span={2}>
        <img src="/images/kgs.png" alt="logo" width="50px" />
      </Col>
      <Col span={19}>
        <h3>{title}</h3>
      </Col>
    </Row>
  );
};

export default Header;
