import React from "react";
import AppMetaHeader from "../component/AppMetaHeader";
import PageContainer from "../component/Layout/PageContainer";
import { Button, Card, Form, Input } from "antd";
import api from "../config/api";
import showNotification from "../utils/notify";
import styled from "styled-components";
const ChangePassword = () => {
  const [loading, setLoading] = React.useState(false);
  const onFinish = async (values: string) => {
    try {
      setLoading(true);
      const { data } = await api.post("change-password", values);
      if (data?.status === true) {
        showNotification("success", "Password is changed successfully ", 3);
        setTimeout(() => {
          window.location.href = "/home";
        }, 1000);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <>
      <AppMetaHeader currentPageTitle="Change Password" />
      <PageContainer currentPageTitle={"Change Password"} transparent={true}>
        <Card style={{ width: "77%", boxShadow: "0px 4px 12px 0px #00000033" }}>
          <Title>Change Password</Title>
          <SubTitle>Edit your Password Details</SubTitle>
          <div style={{ width: "85%" }}>
            <Form size="large" layout="vertical" onFinish={onFinish}>
              <Form.Item
                name="current-password"
                label="Old Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password placeholder="Please enter your old password" />
              </Form.Item>
              <Form.Item
                name="new-password"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    // pattern:
                    //   /^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                    // message:
                    //   "Password must be 8 characters including one uppercase,lowercase,special character and alphanumeric characters.",
                    pattern: /^.{8,}$/,
                    message: "Password must have 8 characters.",
                  },
                ]}
              >
                <Input.Password placeholder="Please enter your new password" />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Confirm New Password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("new-password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered does not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Please enter your new password again" />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  disabled={loading}
                  type="primary"
                  htmlType="submit"
                  size="large"
                >
                  Update Password
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Card>
      </PageContainer>
    </>
  );
};
const Title = styled.p`
  font-weight: 600;
  font-size: 1.5em;
  margin-bottom: 0;
`;
const SubTitle = styled.p`
  font-weight: 500;
  font-size: 0.75em;
`;
export default ChangePassword;
