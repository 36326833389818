import React, { useEffect, useState } from "react";
import AskedDoubts from "./AskedDoubts";
import { Button, Modal, Tabs } from "antd";
import PageContainer from "../../component/Layout/PageContainer";
import AppMetaHeader from "../../component/AppMetaHeader";
import DoubtCard from "./DoubtCard";
import CustomModal from "../../component/common/CustomModal";
import styled from "styled-components";

const AskDoubtListing = () => {
  const [activeKey, setActiveKey] = useState("1");
  const [isClicked, setIsClicked] = useState(false);
  const [content, setContent] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (content) => {
    setIsModalOpen(true);
    setContent(content);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    document.title = "Doubts | Khan Global Studies";
  }, []);

  const handleTabChange = (key) => {
    setActiveKey(key);
    setIsClicked(!isClicked);
  };

  const items = [
    {
      key: "1",
      label: (
        <span className={activeKey === "1" ? (isClicked ? "tabs_1" : "") : ""}>
          Asked Doubts
        </span>
      ),
      children: <AskedDoubts />,
    },
  ];

  const getCurrentPageTitle = () => {
    const activeItem = items.find((item) => item.key === activeKey);
    return `${activeItem ? activeItem.label.props.children : ""}`;
  };
  return (
    <>
      <AppMetaHeader currentPageTitle="My Doubts" />
      <PageContainer
        currentPageTitle={getCurrentPageTitle()}
        transparent={true}
      >
        <Tabs
          tabBarStyle={{
            width: "100%",
            position: "fixed",
            zIndex: "1",
            backgroundColor: "#EDF4FF",
            paddingBottom: "0.5%",
          }}
          items={items}
          defaultActiveKey="1"
          activeKey={activeKey}
          onChange={handleTabChange}
          size="small"
          tabBarExtraContent={
            activeKey === "1" && (
              <ModalButton
                type="primary"
                onClick={() => {
                  showModal(<DoubtCard url="/doubts" />);
                }}
              >
                Ask your Doubt
              </ModalButton>
            )
          }
        />
      </PageContainer>
      <Modal
        width="75%"
        height="45em"
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        style={{ overflow: "scroll" }}
        className="hideScrollbar"
      >
        {content}
      </Modal>
    </>
  );
};

const ModalButton = styled(Button)`
  background-color: #2e3192;
  color: white;
  box-shadow: none;
  right: 1.8em;
  position: fixed;
  margin-top: -1em;
`;

export default AskDoubtListing;
