import { Tag } from "antd";
import React from "react";
import styled from "styled-components";

interface ICourseTag {
  cat_id: number;
}

export default function CourseTag(props: ICourseTag) {
  const liveTag = () => {
    return (
      <LiveTag>
        {" "}
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3141_17773)">
            <path
              d="M4 3C3.80222 3 3.60888 3.05865 3.44443 3.16853C3.27998 3.27841 3.15181 3.43459 3.07612 3.61732C3.00044 3.80004 2.98063 4.00111 3.01922 4.19509C3.0578 4.38907 3.15304 4.56726 3.2929 4.70711C3.43275 4.84696 3.61093 4.9422 3.80491 4.98079C3.99889 5.01937 4.19996 4.99957 4.38269 4.92388C4.56541 4.84819 4.72159 4.72002 4.83147 4.55557C4.94135 4.39112 5 4.19778 5 4C5 3.73478 4.89465 3.48043 4.70711 3.29289C4.51957 3.10536 4.26522 3 4 3ZM4 4.33333C3.93408 4.33333 3.86963 4.31378 3.81481 4.27716C3.76 4.24053 3.71727 4.18847 3.69204 4.12756C3.66681 4.06665 3.66021 3.99963 3.67307 3.93497C3.68594 3.87031 3.71768 3.81092 3.7643 3.7643C3.81092 3.71768 3.87031 3.68593 3.93497 3.67307C3.99963 3.66021 4.06666 3.66681 4.12756 3.69204C4.18847 3.71727 4.24053 3.75999 4.27716 3.81481C4.31379 3.86963 4.33334 3.93407 4.33334 4C4.33334 4.08841 4.29822 4.17319 4.2357 4.2357C4.17319 4.29822 4.08841 4.33333 4 4.33333ZM4 0C3.20888 0 2.43552 0.234596 1.77772 0.674122C1.11992 1.11365 0.607234 1.73836 0.304484 2.46927C0.00173314 3.20017 -0.0774802 4.00444 0.0768607 4.78036C0.231202 5.55629 0.612165 6.26902 1.17157 6.82843C1.73098 7.38784 2.44372 7.7688 3.21964 7.92314C3.99557 8.07748 4.79983 7.99827 5.53074 7.69552C6.26164 7.39277 6.88636 6.88008 7.32588 6.22228C7.76541 5.56449 8 4.79113 8 4C7.99886 2.93949 7.57706 1.92274 6.82716 1.17284C6.07727 0.422943 5.06052 0.00114703 4 0ZM4 5.66667C3.67037 5.66667 3.34813 5.56892 3.07405 5.38578C2.79997 5.20265 2.58635 4.94235 2.4602 4.63781C2.33406 4.33326 2.30105 3.99815 2.36536 3.67485C2.42967 3.35155 2.5884 3.05458 2.82149 2.82149C3.05458 2.5884 3.35155 2.42967 3.67485 2.36536C3.99815 2.30105 4.33326 2.33406 4.63781 2.4602C4.94235 2.58635 5.20265 2.79997 5.38579 3.07405C5.56892 3.34813 5.66667 3.67037 5.66667 4C5.66614 4.44187 5.49038 4.86548 5.17793 5.17793C4.86548 5.49037 4.44187 5.66614 4 5.66667Z"
              fill="#CC0000"
            />
          </g>
          <defs>
            <clipPath id="clip0_3141_17773">
              <rect width="8" height="8" fill="white" />
            </clipPath>
          </defs>
        </svg>{" "}
        {"  "}
        Live
      </LiveTag>
    );
  };
  const liveRecordedTag = () => {
    return (
      <>
        {liveTag()}
        {recordedTag()}
      </>
    );
  };
  const recordedTag = () => {
    return (
      <RecordedTag>
        <svg
          width="9"
          height="9"
          viewBox="0 0 9 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3141_17818)">
            <path
              d="M4.875 3.75V5.25H3V3.75H4.875ZM9 4.5C9 6.98138 6.98138 9 4.5 9C2.01863 9 0 6.98138 0 4.5C0 2.01863 2.01863 0 4.5 0C6.98138 0 9 2.01863 9 4.5ZM6.75 3.1875L5.625 3.975V3H2.25V6H5.625V5.025L6.75 5.8125V3.1875Z"
              fill="#008E28"
            />
          </g>
          <defs>
            <clipPath id="clip0_3141_17818">
              <rect width="9" height="9" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {"  "}
        Recorded
      </RecordedTag>
    );
  };

  const offlineTag = () => {
    return <OfflineTag>Offline</OfflineTag>;
  };
  const offlineOnlineTag = () => {
    return (
      <>
        {offlineTag()}
        {<OnlineTag>Online</OnlineTag>}
      </>
    );
  };

  switch (props.cat_id) {
    case 1:
      return liveRecordedTag();
    case 2:
      return liveTag();
    case 3:
      return recordedTag();
    case 4:
      return offlineTag();
    case 5:
      return offlineOnlineTag();
  }
}

const LiveTag = styled(Tag)`
  background-color: #ffecec;
  color: #670000;
  border-radius: 1em;
  border: 0.1em solid #cc0000;
  font-weight: 500;
  margin-bottom: 3%;
`;
const RecordedTag = styled(Tag)`
  background-color: #d4ffe0;
  color: #005518;
  border-radius: 1em;
  border: 0.1em solid #23c550;
  font-weight: 500;
  margin-bottom: 3%;
`;

const OfflineTag = styled(Tag)`
  background-color: #edf4ff;
  color: #2e3192;
  border-radius: 1em;
  border: 0.1em solid #2e3192;
  font-weight: 500;
  margin-bottom: 3%;
`;
const OnlineTag = styled(Tag)`
  background-color: #e5e7ff;
  color: #2e3192;
  border-radius: 1em;
  border: 0.1em solid #2e3192;
  font-weight: 500;
  margin-bottom: 3%;
`;
