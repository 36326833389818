import { create } from "zustand";
import { persist } from 'zustand/middleware'
export const liveClassesStore = create(
    persist(
        (set,get)=>({
            liveStore:[],
            loading:true,
            setLoading:(loading)=>set(({loading})),
            storeLiveData: (liveStore) => set(({ liveStore }))
        }),
        {
            name:'liveClassesStore'
        }
    )
)