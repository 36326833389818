import React from "react";
import { Col, Row } from "antd";
import { Option } from "./option";
import parse from "html-react-parser";
import useQuizStore from "../../../store/quizStore";
import useMount from "../../../hooks/useMount";
import { onCopy } from "../../../helpers/help";
import { LANG } from "./LanguageConvert";

export default function QuizBody({ language, item }) {
  const { updateVisited, updateQuestion } = useQuizStore();

  useMount(() => {
    updateVisited(item.id);
  }, [item.id]);

  return (
    <Col span={24}>
      <span
        onCopy={onCopy}
        className="quiz-questions"
        style={{ fontSize: "1.2em" }}
      >
        
        {parse(language === LANG.Hi ? item?.hi.q : item?.q)}
      </span>
      <Row>
        <Col xl={10} lg={12} md={15} sm={20} xs={20}>
          {language === LANG.En ? (
            <>
              {(item?.options || []).map((elem, i) => (
                <Option
                  updateQuestion={updateQuestion}
                  elem={elem}
                  item={item}
                  i={i}
                />
              ))}
            </>
          ) : (
            <>
              {(item?.hi?.options || []).map((elem, i) => (
                <Option
                  updateQuestion={updateQuestion}
                  elem={elem}
                  item={item}
                  i={i}
                />
              ))}
            </>
          )}
        </Col>
      </Row>
    </Col>
  );
}
