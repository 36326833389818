import React from "react";
import parse from "html-react-parser";
import styled from "styled-components";
import Loader from "../../component/loader";
import { useApi, useMount } from "../../hooks";
import PdfViewer from "../../utils/pdfViewer";
const MyWriting = ({ id }) => {
  const { data, load, isLoading } = useApi<any>(`/user/submissions/${id}`);

  useMount(() => {
    load();
  }, [id]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        data?.map((item) => (
          <>
            <Title>Question:{parse(item?.question?.question)}</Title>
            <Title>Your Answer: {parse(item?.answer)}</Title>
            {item.attachment &&
              (item.attachment.endsWith(".pdf") ? (
                <PdfViewer pdfUrl={item.attachment} height={"100%"} />
              ) : (
                <img src={item.attachment} alt="file" />
              ))}
          </>
        ))
      )}
    </>
  );
};

const Title = styled.p`
  font-weight: 600;
  font-size: 1.2em;
`;
export default MyWriting;
